import React, { PureComponent } from "react";
import Layout from "../../components/layout";
import "react-multi-carousel/lib/styles.css";
import "../../components/section/cloudProcess/cloudprocess.css";
import "../../pages/cloudservices/cloudServices.css";
import "../../pages/digitaltranformation/digitaltransformation.css";
import {
  WebCarousel,
  WebNav,
  WebDigital,
  WebTransformService,
  WebInfo,
  WebExpertise,
  WebProcess,
  WebMasters,
  WebCapability,
} from "../../components/section";
import "./webdevelopment.css";

class WebDevelopment extends PureComponent {
  render() {
    return (
      <Layout>
        <WebCarousel />
        <WebNav />
        <WebDigital />
        <WebTransformService />
        <WebInfo />
        <WebCapability />
        <WebExpertise />
        <WebMasters />
        <WebProcess />
      </Layout>
    );
  }
}

export default WebDevelopment;
