import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import { ClientCarousel } from "../../carousel";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";

export const OurRelationship = (props) => {
  
  return (
    <Container
    fluid
      className="containerpadding"
      style={{ background: "rgb(250, 250, 250)",marginTop:"60px" }}
    >
      <Row>
        <Col >
          <ScrollAnimation
            animateIn="animate-text animate-text-home"
            duration={5}
            animateOnce={true}
            
          >
            <h2 className="h2heading1" >
              We are Proud of <span className="bold"> Our Relationships</span>
            </h2>
          </ScrollAnimation>
          <AnimatedLine className="animateline" width={"200px"} margin={"-5px auto 0 auto"}>
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
                
              >
                <div className="line"></div>
                </ScrollAnimation>
            </AnimatedLine>
          <br />
        </Col>
      </Row>

      <Row>
        <Col md={12} className="pr-0 pl-0">
          <ClientCarousel />
        </Col>
      </Row>
    </Container>
  );
};
