import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Image } from "react-bootstrap";

import arrowimg from "../../../images/arrow.svg";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import campaign1 from "../../../images/campaign/campaign1.png";
import campaign2 from "../../../images/campaign/campaign2.png";

export const CampaignInnovative = () => {
  const { width } = useViewport();
  const breakpoint = 1025;
  const [open, setOpen] = useState(false);
  return (
    <Container className="mcontainerpaddingnew learningmanage">
      <Row>
        <Col lg={7} md={12} sm={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h3 className="subheader">
              Campaigns to engage your customers better
             </h3>
             <h2 className="h2heading1">
              <span className="bold">Boost your brand’s reach</span>
            </h2>
          </ScrollAnimation>
          <AnimatedLine
            className="animateline"
            width={"150px"}
            margin={"-5px 0 10px 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
          <p className="mparagraphheadingtop">
            Using our many years of cumulative experience with campaigns, we can
            help you design an effective marketing strategy.
            <span
              className={`d-lg-inline d-xl-inline ${
                open ? "d-inline" : "d-none"
              }`}
            >
              &nbsp;Our team will help you structure a clear marketing roadmap for
              your project with campaigns, which will generate the most
              significant ROI impact on your business.
            </span>
          </p>
          <span
            className={`d-lg-inline d-xl-inline ${
              open ? "d-inline" : "d-none"
            }`}
          >
           
            <ul className="homelist">
              <li>
                <p>
                  Ensure your brand presence on all platforms and multiple
                  devices.
                </p>
              </li>
              <li>
                {" "}
                <p>
                  Design Responsive Campaign templates that fit your business
                  needs.
                </p>
              </li>

              <li>
                <p>
                  {" "}
                  Drive user engagement rate and capture unique users by our
                  campaign strategy.
                </p>
              </li>
              <li>
                <p>
                  Optimize your business insights by measuring the campaign
                  results.
                </p>
              </li>
              <li>
                <p>
                  Boost Customer loyalty and User retention with our effective
                  marketing strategies.
                </p>
              </li>
            </ul>
          </span>
          <button
            className="readmore_btn d-lg-none d-xl-none pl-0"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              ""
            ) : (
              <>
                <span className="expand readmore">
                  Read More <FontAwesomeIcon icon="angle-double-right" />
                </span>
              </>
            )}
          </button>
          <div className="d-lg-none d-xl-none d-md-none learning-mid-img mt-0">
            <img alt="" src={campaign1} className="mgridimg1" />
          </div>
          <p className="mparagraphheading">
            Whether you want to build your brand, find new customers, or
            increase engagement, we’ll develop the right advertising plan for
            you and implement it quickly and accurately.
            <span className={` d-xl-inline d-none `}>
              {" "}
              We focus extensively on conversion rate and driving revenue. To
              ensure successful email marketing, we thoroughly measure, track
              and optimize your campaigns. We offer well-documented processes,
              and our team of specialists can enable you to execute your
              campaigns faster while maintaining quality. We track metrics and
              subscriber demographics and review email automation, segmentation,
              list health (growth/shrinking), and target segments.
            </span>
          </p>
          <div className="d-lg-none d-xl-none d-md-none learning-mid-img mt-4">
            <img alt="" src={campaign2} className="mgridimg1" />
          </div>

          <br />
        </Col>
        <Col lg={5} md={12} sm={12} className="d-none d-md-block mt-2">
          <Row>
            <Col md={6} className="columntop">
              <div>
                <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/campaign-v2/campaign-engage -1.webp" className="mgridimg1" />
              </div>
              <div>
                <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/campaign-v2/campaign-engage-2.webp" className="mgridimg2" />
              </div>
            </Col>
            <Col md={6} className="mt-5">
              <div>
                <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/campaign-v2/campaign-engage-3.webp" className="mgridimg1" />
              </div>
              <br />
              <div>
                <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/campaign-v2/campaign-engage-4.webp" className="mgridimg1" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
