import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import image1 from "../../../images/workflowAutomation/image1.png";
import image2 from "../../../images/workflowAutomation/image2.png";
import image3 from "../../../images/workflowAutomation/image3.png";
import image4 from "../../../images/workflowAutomation/image4.png";

import arrowimg from "../../../images/arrow.svg";
import { useViewport } from "../../common/designParts/useViewport";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import imagemob1 from "../../../images/workflowAutomation/imagemob1.png";
import imagemob2 from "../../../images/workflowAutomation/imagemob2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const WorkflowInnovative = () => {
  const { width } = useViewport();
  const breakpoint = 800;
  const [open, setOpen] = useState(false);
  return (
    <Container className="mcontainerpaddingnew learningmanage">
      <Row>
        <Col lg={7} md={12} sm={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
              <h3 className="subheader">
            
              Optimize your Business Efficiency
              </h3>
              <h2 className="h2heading1">
              <span className="bold">Experience the power of Automation</span>
            </h2>
          </ScrollAnimation>
          <AnimatedLine
            className="animateline"
            width={"250px"}
            margin={"-5px 0 5px 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
          <p className="mparagraphheadingtop">
            By automating workflows and processes, we give you the right
            tool-set necessary to deploy a successful digital solution.
            <span
              className={`d-lg-inline d-xl-inline ${
                open ? "d-inline" : "d-none"
              }`}
            >
              &nbsp;We believe in implementing a comprehensive business process
              workflow that can considerably decrease the barriers between
              humans and systems, thus enhancing your business continuity.
            </span>
          </p>
          <span
            className={`d-lg-inline d-xl-inline ${
              open ? "d-inline" : "d-none"
            }`}
          >
           
            <ul className="homelist">
              <li>
                <p>
                  Streamline your business workflows for greater resilience and
                  flexibility.
                </p>
              </li>
              <li>
                <p>
                  Automate repetitive tasks to increase the productivity of your
                  team.
                </p>
              </li>
              <li>
                <p>
                  Leverage the power of latest technology to minimise manual
                  intervention.
                </p>
              </li>
              <li>
                <p>
                  Create workflows that are more predictive, adaptive, and
                  data-driven.
                </p>
              </li>
              <li>
                <p>
                  Expand your business across multiple digital touchpoints -
                  Web, Mobile, POS.
                </p>
              </li>
            </ul>
          </span>
          <button
            className="readmore_btn d-lg-none d-xl-none pl-0"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              ""
            ) : (
              <>
                <span className="expand readmore">
                  Read More <FontAwesomeIcon icon="angle-double-right" />
                </span>
              </>
            )}
          </button>
          <div className="d-lg-none d-xl-none d-md-none learning-mid-img mt-4">
            <img alt="" src={imagemob1} className="mgridimg1" />
          </div>
          <p className="mparagraphheading">
            Regardless of the industry, We help construct custom automated
            workflows for repetitive manual routines.
            <span className={`d-xl-inline d-none `}>
              {" "}
              To ensure a flexible, user-friendly workflow solution, we
              integrate agile project methodologies with top-notch automation
              technologies. In addition to offering software solutions that
              allow employees and systems to work across boundaries seamlessly,
              our team implements streamlined processes, repeatable action
              management, reliable delivery, and verifiable automation
              eco-systems to help your business succeed.
            </span>
          </p>
          <div className="d-lg-none d-xl-none d-md-none learning-mid-img mt-4">
            <img alt="" src={imagemob2} className="mgridimg1" />
          </div>

          <br />
        </Col>
        <Col lg={5} md={12} sm={12} className="d-none d-md-block mt-2">
          <Row>
            <Col md={6} className="columntop">
              <div>
                <img alt="" src={image1} className="mgridimg1" />
              </div>
              <div>
                <img alt="" src={image2} className="mgridimg2" />
              </div>
            </Col>
            <Col md={6} className="mt-5">
              <div>
                <img alt="" src={image3} className="mgridimg1" />
              </div>
              <br />
              <div>
                <img alt="" src={image4} className="mgridimg1" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
