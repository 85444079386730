import { Container } from "react-bootstrap";

export const CampaignCarousel = () => {
  return (
    <div className="hero-styles campaignhero">
      <Container>
        <p className="mwhiteparagraph" style={{ overflowX: "hidden" }}>
          <h1 className="cloudcaptionheading">Campaign Management</h1>

          <h4>
          Transform every campaign into a personal and unique experience for your customers.


          </h4>
        </p>
      </Container>
    </div>
  );
};
