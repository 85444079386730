import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import mob1 from "../../../images/emasters/Ellipse 82.svg";
import mob2 from "../../../images/emasters/Ellipse 83.svg";
import mob3 from "../../../images/emasters/Ellipse 84.svg";
import mob4 from "../../../images/emasters/Ellipse 85.svg";
import text from "../../../images/emasters/text.svg";
import grid1 from "../../../images/emasters/images.png";
import grid2 from "../../../images/emasters/nike-air-max-90-cw5458-101-white-grey-orange-black-release-date-info-1200x750.png";
import grid3 from "../../../images/emasters/a08fa92e7226403dca92f9a95e654f26.png";
import grid4 from "../../../images/emasters/wallpaper.png";
import grid5 from "../../../images/emasters/GettyImages-1146315260-734ae3c6382448f7adfee76617db0d68.png";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import grid from "../../../images/emasters/master-grid.png";

export const EmasterBranding = () => {
    const { width } = useViewport();
  const breakpoint = 768;
  const [open, setOpen] = useState(false);
  return (
   
    <Container className="emastercontainer pt-3">
      <Row>
        <Col md={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
              Moodboard & <span className="bold">Branding</span>
            </h2>
          </ScrollAnimation>

          <AnimatedLine
              className="animateline"
              width={"150px"}
              margin={"-10px 0 10px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
        </Col>
      </Row>
      <Row>
        <Col lg={6} xs={12}>
          <Row className="mt-3">
            <Col className="color-plates " xs={3}>
              <img src={mob1} alt="" />
              <p>#FA5511</p>
            </Col>
            <Col className="color-plates" xs={3}>
              <img src={mob2} alt="" />
              <p>#FC833F</p>
            </Col>
            <Col className="color-plates" xs={3}>
              <img src={mob3} alt="" />
              <p>#222E3A</p>
            </Col>
            <Col className="color-plates" xs={3}>
              <img src={mob4} alt="" />
              <p>#C4C4C4</p>
            </Col>
          </Row>
          <Row className="brand-text">
            <h4>Typography</h4>
            <h4>Helvetica Neue</h4>
            <img src={text} alt="text" />
          </Row>
          <Row>
            <Col xs={5} sm={3}>
              <span className="txt">Aa</span>
            </Col>
            <Col className="brand-p">
              <p>
                <span className="bold">Header</span> : 19 px
              </p>
              <p>
                <span className="bold">Body</span> : 14 px
              </p>
            </Col>
          </Row>
        </Col>
        <Collapse
              in={width < breakpoint ? open : true}
              className="collapse-div"
            >
              <div id="collapse-services-learning">
        <Col lg={6} xs={12}>
          {/* <section id="photos">
            <img src={grid1} alt="" />
            <img src={grid4} alt="" />
            <img src={grid2} alt="" />

            <img src={grid5} alt="" />
            <img src={grid3} alt="" />
          </section> */}
          <section className="emaster-grid">
              <img src={grid} alt="" width="100%"/>
          </section>
        </Col>
        </div>
        </Collapse>
        <button
              onClick={() => setOpen(!open)}
              aria-controls="collapse-services-learning"
              aria-expanded={open}
              className="d-xl-none d-lg-none d-md-none collapse-btn pl-0 pt-2"
            >
               {open ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Read More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
            </button>
      </Row>
    </Container>
    
  );
};
