import React from "react";
import { Carousel } from "react-bootstrap";
import { useHistory } from "react-router-dom";


export const HomeCarousel = () => {
  const history = useHistory();
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/bg1-_3_.webp"
          alt="First slide"
        />
        <Carousel.Caption className="caption1">
          <h1 className="captionheading">Providing only the best</h1>
          <h1 className="captionheadings">
            <span className="bold">Solution</span>
          </h1>
          <h1 className="captionheadings">for your demands</h1>
          <button
            type="button"
            class="btn btn-default captionbtn"
            onClick={() => history.push("./contact-us")}
          >
            START A PROJECT WITH US
          </button>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/bg2-_1_.webp"
          alt="First slide"
        />
        <Carousel.Caption className="caption2">
          <h1 className="captionheading">Maximise your</h1>
          <h1 className="captionheadings">
            <span className="bold">Business Potential</span>
          </h1>
          <button
            type="button"
            class="btn btn-default readmorebtn"
            onClick={() => history.push("./contact-us")}
          >
            READ MORE
          </button>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/bg3-_1_%20(1).webp"
          alt="First slide"
        />
        <Carousel.Caption className="caption3">
          <h1 className="captionheading">Digital Strategies to help your</h1>
          <h1 className="captionheadings">
            <span className="bold">Enterprise Grow</span>
          </h1>
          <button
            type="button"
            class="btn btn-default captionbtn"
            onClick={() => history.push("./portfolio")}
          >
            VIEW OUR PORTFOLIO
          </button>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};
