import React, { PureComponent } from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Home from "../pages/index";
import MobileDevelopment from "../pages/mobiledevelopment/mobileDevelopment";
import CloudServices from "../pages/cloudservices/cloudServices";
import PortFolio from "../pages/portfolio/portfolio";
import CampaignManagement from "../pages/campaignManagement/campaignService";

import "../styles/index.scss";
import DigitalTransformation from "./digitaltranformation/digitaltransformation";
import UiUxServices from "./UiUxServices";
import WebDevelopment from "./webDevelopment/webDevelopment";
import PortalCollaboration from "./portalNcollaboration/portalNcollaboration";
import ContactUs from "./contactUs";
import Emasters from "./emasters/emasters";
import LearningManagement from "./learningManagement/learningManagement";
import WorkflowAutomation from "./workflowAutomation/workflowAutomation";
import AdobePro from "./adobe/adobeProject";
import Xtime from "./xtime/xtime";
import AboutUs from "./aboutUs/about";
import QaTesting from "./qaTesting/qaTesting";

class App extends PureComponent {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route path="/home" exact component={Home} />
          <Route
            path="/mobile-development"
            exact
            component={MobileDevelopment}
          />
          <Route path="/cloud-services" exact component={CloudServices} />
          <Route path="/portfolio" exact component={PortFolio} />
          <Route
            path="/digital-transformation"
            exact
            component={DigitalTransformation}
          />
          <Route
            path="/campaign-management"
            exact
            component={CampaignManagement}
          />
          <Route path="/ui-ux-services" exact component={UiUxServices} />
          <Route path="/web-development" exact component={WebDevelopment} />
          <Route
            path="/portal-collaboration"
            exact
            component={PortalCollaboration}
          />
          <Route path="/contact-us" exact component={ContactUs} />
          <Route path="/emasters" exact component={Emasters} />
          <Route
            path="/learning-management-system"
            exact
            component={LearningManagement}
          />
          <Route
            path="/workflow-automation"
            exact
            component={WorkflowAutomation}
          />
          <Route
          path="/adobe"
          exact
          component={AdobePro}
          />
          <Route
          path="/xtime"
          exact
          component={Xtime}
          />
          <Route
          path="/about-us"
          exact
          component={AboutUs}
          />
          <Route
          path="/qatesting"
          exact
          component={QaTesting}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
