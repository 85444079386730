import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import image1 from "../../../images/portal/1.png";
import image2 from "../../../images/portal/2.png";

import image3 from "../../../images/portal/3.png";

import image4 from "../../../images/portal/4.png";
import arrowimg from "../../../images/arrow.svg";
import { useViewport } from "../../common/designParts/useViewport";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import imagemob1 from "../../../images/portal/portal1.png";
import imagemob2 from "../../../images/portal/portal2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PortalInnovative = () => {
  const { width } = useViewport();
  const breakpoint = 800;
  const [open, setOpen] = useState(false);
  return (
    <Container className="mcontainerpaddingnew learningmanage">
      <Row>
        <Col lg={7} md={12} sm={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h3 className="subheader">
              Delivering Right Information to Right People
            </h3>
            <h2 className="h2heading1">
              <span className="bold">Build advanced Digital Workplaces</span>
            </h2>
          </ScrollAnimation>
          <AnimatedLine
            className="animateline"
            width={"250px"}
            margin={"-5px 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
          <p className="mparagraphheadingtop">
            As part of our commitment to understanding your business objectives
            and target audiences, we deliver engaging, collaborative online
            environments that meet your business and customers’ needs.
            <span
              className={`d-lg-inline d-xl-inline ${
                open ? "d-inline" : "d-none"
              }`}
            >
              &nbsp;Our Portal & Collaboration solutions help you build a network of
              employees who can work in teams quickly and efficiently.
            </span>
          </p>
          <span
            className={`d-lg-inline d-xl-inline ${
              open ? "d-inline" : "d-none"
            }`}
          >
           
            <ul className="homelist">
              <li>
                <p>
                  Maximize your Business performance by building Agile,
                  Intelligent portals.
                </p>
              </li>
              <li>
                <p>
                  Assist Corporate branding, Search-retrieval and, Remote
                  collaborations.
                </p>
              </li>
              <li>
                <p>
                  Achieve Additional Revenues, Fewer errors and, Reduction in
                  costs.
                </p>
              </li>
              <li>
                <p>
                  Manage Information Convergence and distribution across various
                  platforms.
                </p>
              </li>
              <li>
                <p>
                  Create a thriving and simplified Ecosystem for your valuable
                  enterprise.
                </p>
              </li>
            </ul>
          </span>
          <button
            className="readmore_btn d-lg-none d-xl-none pl-0"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              ""
            ) : (
              <>
                <span className="expand readmore">
                  Read More <FontAwesomeIcon icon="angle-double-right" />
                </span>
              </>
            )}
          </button>
          <div className="d-lg-none d-xl-none d-md-none learning-mid-img mt-4">
            <img alt="" src={imagemob1} className="mgridimg1" />
          </div>
          <p className="mparagraphheading">
            We are a proven leader in portal projects and engagements. Our goal
            is to ensure that companies realize the tangible benefits of portals
            across the entire value chain, including improving customer service,
            supply chain response, employee collaboration, and knowledge
            sharing.
            <span className={`d-xl-inline d-none `}>
              {" "}
              Using unifying platforms and technologies, our company provides
              vendor-independent portal solutions for diverse industry
              verticals. With our extensive library of reusable assets, we help
              you build high-quality portal solutions based on industry-standard
              portlets, components, algorithms, and best practices.
            </span>
          </p>
          <div className="d-lg-none d-xl-none d-md-none learning-mid-img mt-4">
            <img alt="" src={imagemob2} className="mgridimg1" />
          </div>

          <br />
        </Col>
        <Col lg={5} md={12} sm={12} className="d-none d-md-block mt-2">
          <Row>
            <Col md={6} className="columntop">
              <div>
                <img alt="" src={image1} className="mgridimg1" />
              </div>
              <div>
                <img alt="" src={image2} className="mgridimg2" />
              </div>
            </Col>
            <Col md={6} className="mt-5">
              <div>
                <img alt="" src={image3} className="mgridimg1" />
              </div>
              <br />
              <div>
                <img alt="" src={image4} className="mgridimg1" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
