import { Container } from "react-bootstrap";


export const WorkflowCarousel = () => {
    return (
      <div className="hero-styles workflowhero">
      <Container>
       <p className="mwhiteparagraph" style={{ overflowX: "hidden" }}>
         <h1 className="cloudcaptionheading">Workflow Automation</h1>

         <h4>
         Automate Any Business Process that can benefit from optimization 
         </h4>
       </p>
     </Container>
   </div>
    );
  };
  