import ScrollAnimation from "react-animate-on-scroll";
import { Col, Collapse, Container, Row } from "react-bootstrap";
import icon4 from "../../../images/qatesting/exp.png";
import icon1 from "../../../images/qatesting/testing.png";
import icon3 from "../../../images/qatesting/mobile.png";
import icon2 from "../../../images/qatesting/automation.png";
import plat1 from "../../../images/icons/soapui.png";
import plat2 from "../../../images/icons/rest.png";
import plat3 from "../../../images/icons/protractor.png";
import plat4 from "../../../images/icons/selenium.png";
import plat5 from "../../../images/icons/appium.png";
import plat6 from "../../../images/icons/katolan.png";
import plat7 from "../../../images/icons/jmeter.png";
import plat8 from "../../../images/icons/loader.io.png";

import "react-multi-carousel/lib/styles.css";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const QaExpertise = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [openExp, setOpenExp] = useState(false);
  const [openWeb, setOpenWeb] = useState(false);
  return (
    <Container className="mcontainerpadding ">
      <div id="expertise"></div>
      <Row>
        <Col md={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
              Our Development{" "}
              <span className="bold">
                <span className="bold">Platforms & Frameworks Expertise</span>
              </span>
            </h2>
          </ScrollAnimation>
          <AnimatedLine
            className="animateline"
            width={"200px"}
            margin={"-5px 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
        </Col>
      </Row>
      <br />

      <Row>
        <Col md={6} sm={6} xs={12} lg={6} xl={3}>
          <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
            <div class="numberCircle textcenter">
              <img alt="" src={icon1} className="platimage" />
            </div>
            <div
              className="card platcard digi-platforms"
              style={{ height: "auto" }}
            >
              <p className="devheading">
               <span className="bold">Web Services</span> 
                <AnimatedLine
                  className="animateline"
                  width={"200px"}
                  margin={"5px auto"}
                >
                  <ScrollAnimation
                    animateIn="fadeInLeft"
                    duration={2}
                    animateOnce={true}
                    className="no-animation"
                  >
                    <div className="line"></div>
                  </ScrollAnimation>
                </AnimatedLine>
              </p>

              <div class="mflex-container digi-ex-cont">
                <div>
                  <img alt="" src={plat1} className="platimgnew " />

                  <p className="platparagraph">SOAPUI</p>
                </div>
                <div>
                  <img alt="" src={plat2} className="platimgnew " />
                  <p className="platparagraph">REST</p>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </Col>

        {/* section 2 */}
        <Col md={6} sm={6} xs={12} lg={6} xl={3}>
          <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
            <div class="numberCircle textcenter">
              <img alt="" src={icon2} className="platimage" />
            </div>
            <div
              className="card platcard digi-platforms"
              style={{ height: "auto" }}
            >
              <p className="devheading">
              <span className="bold"> Automation Testing</span>
                <AnimatedLine
                  className="animateline"
                  width={"200px"}
                  margin={"5px auto"}
                >
                  <ScrollAnimation
                    animateIn="fadeInLeft"
                    duration={2}
                    animateOnce={true}
                    className="no-animation"
                  >
                    <div className="line"></div>
                  </ScrollAnimation>
                </AnimatedLine>
              </p>

              <div class="mflex-container digi-ex-cont">
                <div>
                  <img alt="" src={plat3} className="platimgnew " />

                  <p className="platparagraph">Protractor</p>
                </div>
                <div>
                  <img alt="" src={plat4} className="platimgnew " />
                  <p className="platparagraph">Selenium</p>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </Col>

        {/* section 3 */}
        <Collapse
          in={width < breakpoint ? openWeb : true}
          className="collapse-div"
        >
          <div id="collapse-services-learning">
            <Col md={6} sm={6} xs={12} lg={6} xl={3}>
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div class="numberCircle textcenter">
                  <img alt="" src={icon3} className="platimage" />
                </div>
                <div
                  className="card platcard digi-platforms"
                  style={{ height: "auto" }}
                >
                  <p className="devheading">
                  <span className="bold"> Mobile App Testing</span>
                    <AnimatedLine
                      className="animateline"
                      width={"200px"}
                      margin={"5px auto"}
                    >
                      <ScrollAnimation
                        animateIn="fadeInLeft"
                        duration={2}
                        animateOnce={true}
                        className="no-animation"
                      >
                        <div className="line"></div>
                      </ScrollAnimation>
                    </AnimatedLine>
                  </p>

                  <div class="mflex-container digi-ex-cont">
                    <div>
                      <img alt="" src={plat5} className="platimgnew " />

                      <p className="platparagraph">Appium</p>
                    </div>
                    <div>
                      <img alt="" src={plat6} className="platimgnew " />
                      <p className="platparagraph">Katalon</p>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </Col>

            {/* section 4 */}
            <Col md={6} sm={6} xs={12} lg={6} xl={3}>
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div class="numberCircle textcenter">
                  <img alt="" src={icon4} className="platimage" />
                </div>
                <div
                  className="card platcard digi-platforms"
                  style={{ height: "auto" }}
                >
                  <p className="devheading">
                  <span className="bold">     Performance Testing</span>
                    <AnimatedLine
                      className="animateline"
                      width={"200px"}
                      margin={"5px auto"}
                    >
                      <ScrollAnimation
                        animateIn="fadeInLeft"
                        duration={2}
                        animateOnce={true}
                        className="no-animation"
                      >
                        <div className="line"></div>
                      </ScrollAnimation>
                    </AnimatedLine>
                  </p>

                  <div class="mflex-container digi-ex-cont">
                    <div>
                      <img alt="" src={plat7} className="platimgnew " />

                      <p className="platparagraph">JMeter</p>
                    </div>
                    <div>
                      <img alt="" src={plat8} className="platimgnew " />
                      <p className="platparagraph">Loader.io</p>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
          </div>
        </Collapse>
        <button
          onClick={() => setOpenWeb(!openWeb)}
          aria-controls="collapse-services-learning"
          aria-expanded={openWeb}
          className="d-xl-none d-lg-none d-md-none collapse-btn text-center mr-auto ml-auto pt-2"
        >
          {openWeb ? (
            ""
          ) : (
            <>
              <span className="expand readmore">
                Expand More <FontAwesomeIcon icon="angle-double-right" />
              </span>
            </>
          )}
        </button>
      </Row>
    </Container>

    //

    // circle menu
  );
};
