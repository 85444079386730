import React from "react";
import { Container } from "react-bootstrap";
export const PortFolioCarousel = () => {
  
  return (
    <div class="phero hero-styles" id="phero">
     <Container>
        <p className="mwhiteparagraph" style={{ overflowX: "hidden" }}>
          <h1 className="cloudcaptionheading">Portfolio</h1>
          <h4>
            Showcases our expertise in new technology and trends
          </h4>
        </p>
     </Container>
    </div>
  );
};
