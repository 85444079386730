import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export const QaInfo = () => {
  return (
    <div>
      <div className="learningbgInfo qabg">
        <ScrollAnimation
          animateIn="fadeInRight"
          duration={2}
          animateOnce={true}
        >
          <p className="">
            Our team makes sure that your software application’s functions
            operate according to its behavioral requirements, ensuring quality
            and behavioral adherence.
          </p>
        </ScrollAnimation>
      </div>
    </div>
  );
};
