import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import xtimeImage from "../../../images/xtime/Artboard.png";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const XtimeCloud = () => {
  const { width } = useViewport();
  const breakpoint = 992;
  const [openlearning, setOpenlearning] = useState(false);
  return (
    <>
      <div id="features"></div>
      <div style={{ backgroundColor: "#F8F8F8BA" }}>
        <Container
          className="learningmanage pb-5"
          style={{ backgroundColor: "#F8F8F8BA" }}
        >
          <Row>
            <Col lg={6} className="d-none d-lg-block"></Col>
            <Col lg={6} sm={12}>
              <ScrollAnimation
                animateIn="animate-text"
                duration={5}
                animateOnce={true}
              >
                <h2 className="h2heading1 pt-3">
                  Success of our <span className="bold">Cloud based </span>application service
                </h2>
              </ScrollAnimation>
              <AnimatedLine
                className="animateline"
                width={"200px"}
                margin={"-5px 0 5px 0"}
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                  className="no-animation"
                >
                  <div className="line"></div>
                </ScrollAnimation>
              </AnimatedLine>
            </Col>
          </Row>
          <Row className="portal-cap-p mb-0">
            <Col lg={6}>
              <ScrollAnimation
                animateIn="fade-image-right"
                duration={2}
                animateOnce={true}
              >
                <img
                  src={xtimeImage}
                  alt=""
                  className="learning-cap-img pt-5 mb-0 xtime-img"
                />
              </ScrollAnimation>
            </Col>
            <Col lg={6}>
              <ul className="adobe-list xtimelist">
                <li>
                  <p>
                    Re-Designed the existing Customer / Dealer/ Inspection
                    interface which increased User Retention by 14% and improved
                    customer satisfaction-{" "}
                    <span>Superior Customer Experience Management</span>
                  </p>
                </li>
                <li>
                  <p>
                    The process of appointment scheduling required managing
                    complex data efficiently to display the relevant results -
                    <span>Scalable Data Management</span>
                  </p>
                </li>
                <li>
                  <p>
                    Integrate different API’s to the workflow and connected
                    vehicles to manage interactions and generate reports -
                    <span>Robust API’s and Integration</span>
                  </p>
                </li>
                <Collapse in={width < breakpoint ? openlearning : true}>
                  <div id="collapse-learning">
                    <li>
                      <p>
                        Managed customer experiences better by having instant
                        access to service history, inspections, recalls, and
                        tires -<span>Smarter Cloud Management</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        Developed different workflows for Mobile/Web based
                        appointment scheduling, Tablet based check-in and
                        inspection interfaces -
                        <span>
                          Specialized Multi-Channel Workflow Automation{" "}
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        Harnessed the right product strategy, roadmap, workflow
                        model and Cloud ecosystem collaborations -
                        <span>Digital Transformation and Cloud Migration</span>
                      </p>
                    </li>
                  </div>
                </Collapse>
                <button
                  onClick={() => setOpenlearning(!openlearning)}
                  aria-controls="collapse-learning"
                  aria-expanded={openlearning}
                  className="d-xl-none d-lg-none collapse-btn text-center ml-0 pl-0"
                >
                  {openlearning ? (
                    ""
                  ) : (
                    <>
                      <span className="expand readmore">
                        Read More <FontAwesomeIcon icon="angle-double-right" />
                      </span>
                    </>
                  )}
                </button>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
