
import styled from "styled-components";

export const AnimatedLine = styled.div`
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  padding-bottom: 20px;
  max-width: ${(props) => props.width};
  overflow: hidden;
  position: relative;
  margin: ${(props) => props.margin};
`;
