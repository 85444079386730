import React from "react";
import { Container, Row, Col, Image, Collapse } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ServiceCard from "../../common/designParts/serviceCardComponent";

export const CampaignServices = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [open, setOpen] = useState(false);
  const [openservice, setOpenservice] = useState(false);
  return (
    <div>
      <div id="campaignservice"></div>
      <div className="mbg-img">
        <Container
          className="mcontainerpadding "
          style={{ background: "none" }}
        >
          <Row>
            <Col md={12}>
              <ScrollAnimation
                animateIn="animate-text"
                duration={5}
                animateOnce={true}
              >
                <h2 className="h2heading1">Our <span className="bold">Campaign Services</span></h2>
              </ScrollAnimation>

              <AnimatedLine
                className="animateline"
                width={"150px"}
                margin={"-5px 0"}
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                  className="no-animation"
                >
                  <div className="line"></div>
                </ScrollAnimation>
              </AnimatedLine>
            </Col>
          </Row>
          <br />

          <Row>
            <Col md={6} lg={4}>
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div className="card digicard">
                  <div className="portal-service-img">
                    <Image src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/campaign-v2/icons8-email-open-50.png" className="serveimg" />
                  </div>
                  <p className="devheading"><span className="bold">Email Strategy and Audit</span></p>
                  <p className="devparagraph">
                    Our comprehensive email marketing audit allows to uncover
                    the gaps and opportunities in your email marketing program.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={6} lg={4}>
              <ServiceCard
                id="collapse-services-dtl"
                heading="Email Campaign Management"
                image="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/campaign-v2/icons8-mail-advertising-80.png"
                content="We help you understand Campaign Goals and Targeting, Coordinate and collect assets such as content, offers, images, and brand guidelines.
                  "
              />
            </Col>
            <Col md={6} lg={4}>
              <ServiceCard
                id="collapse-service-dtl2"
                heading="Email Marketing Automation"
                image="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/campaign-v2/icons8-automation-50.png"
                content="Drip email campaign to engage your customers or a multi-step email sequence to increase revenue, we’ve got you covered."
              />
            </Col>

            <Collapse
              in={width < breakpoint ? open : true}
              className="collapse-div"
            >
              <div id="collapse-services-learning">
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl3"
                    heading="Email Personalization and Template Generation"
                    image="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/campaign-v2/template.png"
                    content="We can help design, code, and deploy email templates that create a consistent experience for all users."
                  />
                </Col>
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl4"
                    heading="ESP Vendor Evaluation and Migration"
                    image="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/campaign-v2/icons8-group-50.png"
                    content="We do a preliminary audit to understand the prevailing challenges and things your current ESP is unable to deliver and work to enhance it."
                  />
                </Col>
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl5"
                    heading="Analytics and Deliverability"
                    image="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/campaign-v2/analytics.png"
                    content="We can help you optimize your email deliverability and proactively protect your mailing reputation."
                  />
                </Col>
              </div>
            </Collapse>
            <button
              onClick={() => setOpen(!open)}
              aria-controls="collapse-services-learning"
              aria-expanded={open}
              className="d-xl-none d-lg-none d-md-none collapse-btn text-center mr-auto ml-auto pt-2"
            >
              {open ? (
                ""
              ) : (
                <>
                  <span className="expand readmore">
                    Expand More <FontAwesomeIcon icon="angle-double-right" />
                  </span>
                </>
              )}
            </button>
          </Row>
        </Container>
      </div>
    </div>
  );
};
