import ScrollAnimation from "react-animate-on-scroll";
import { Col, Collapse, Container, Row } from "react-bootstrap";

import "react-multi-carousel/lib/styles.css";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import creativeCloud from "../../../images/icons/Creative_Cloud.svg";
import document from "../../../images/icons/acrobat.png";
import aec from "../../../images/icons/Aec-logo.png";
import xd from "../../../images/icons/xd.svg";
import dream from "../../../images/icons/dreamviewer.png";
import campaign from "../../../images/icons/icon3.png";
import html from "../../../images/icons/html.png";
import css from "../../../images/icons/css.png";

export const CampaignExpertise = () => {
    const { width } = useViewport();
    const breakpoint = 768;
    const [openExp, setOpenExp] = useState(false);
    const [openWeb, setOpenWeb] = useState(false);
  return (
    <>
      <div id="campaignexpertise"></div>
      <Container className="mcontainerpadding ">
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                Our Development{" "}
                <span className="bold">Platforms & Frameworks Expertise</span>
              </h2>
            </ScrollAnimation>
            <AnimatedLine
              className="animateline"
              width={"200px"}
              margin={"0 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        <br />
     
        <Row>
          <Col md={8}>
            <div className="campaignExpertise-first">
              <Row>
                <h4>Design</h4>
              </Row>
              <Row className="pt-5">
                <Col xs={6} lg={4} className="mb-4">
                  <div className="exp-img">
                    <img src={creativeCloud} alt="icon" />
                    <p>Adobe Creative Cloud</p>
                  </div>
                </Col>
                <Col xs={6} lg={4} className="mb-4">
                  <div className="exp-img">
                    <img src={aec} alt="icon" />
                    <p>Adobe Experience Cloud</p>
                  </div>
                </Col>
                <Col xs={6} lg={4} className="mb-4">
                  <div className="exp-img">
                    <img src={document} alt="icon" />
                    <p>Adobe Document Cloud</p>
                  </div>
                </Col>
              
              
                <Col xs={6} lg={4} className="mb-4">
                  <div className="exp-img">
                    <img src={xd} alt="icon" />
                    <p>Adobe Experience Design</p>
                  </div>
                </Col>
                <Collapse in={width < breakpoint ? openExp === "first" : true} >
                <div id="first" style={{display:"contents"}}>
                <Col xs={6} lg={4} className="mb-4"> 
                  <div className="exp-img">
                    <img src={dream} alt="icon" />
                    <p>Adobe Dreamweaver</p>
                  </div>
                </Col>
                <Col xs={6} lg={4} className="mb-4">
                  <div className="exp-img">
                    <img src={campaign} alt="icon" />
                    <p>Adobe Campaign</p>
                  </div>
                </Col>
                </div>
                </Collapse>
                {/* <button className="d-xl-none d-lg-none d-md-none collapse-btn-services text-center mr-auto ml-auto pt-2">
                {openExp === "first" ? (
                  <FontAwesomeIcon
                    icon="chevron-up"
                    onClick={() => setOpenExp(false)}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon="chevron-down"
                    onClick={() => setOpenExp("first")}
                    aria-controls="first"
                    aria-expanded="first"
                  />
                )}
              </button> */}
                </Row>
            </div>
          </Col>
          <Col md={4}>
            <div className="campaignExpertise">
              <Row>
                <h4>Implementation</h4>
              </Row>

              <Row className="pt-5">
                <Col col={6}>
                  <div className="exp-img">
                    <img src={html} alt="icon" />
                    <p>HTML5</p>
                  </div>
                </Col>
                <Col col={6}>
                  <div className="exp-img">
                    <img src={css} alt="icon" />
                    <p>CSS3</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
