import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useViewport } from "../../common/designParts/useViewport";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import imagemob1 from "../../../images/cloud/1.png";
import imagemob2 from "../../../images/cloud/3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cg1 from "../../../images/cloud/cloud/cg1.webp";
import cg2 from "../../../images/cloud/cloud/cg2.webp";
import cg3 from "../../../images/cloud/cloud/cg3.webp";
import cg4 from "../../../images/cloud/cloud/cg4.webp";

export const InnovationCloud = () => {
  const { width } = useViewport();
  const breakpoint = 1025;
  const [open, setOpen] = useState(false);
  return (
    <div style={{ background: "#ffffff" }}>
      <Container className="mcontainerpaddingnew learningmanage">
        <Row>
          <Col lg={7} md={12} sm={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h3 className="subheader">
                Build secure and scalable digital solutions
                </h3>
                <h2 className="h2heading1">
                <span className="bold">Harness the power of the cloud</span>
              </h2>
            </ScrollAnimation>
            <AnimatedLine
              className="animateline"
              width={"150px"}
              margin={"-5px 0 10px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
            <p className="mparagraphheadingtop">
              Utilize cloud to its full potential to optimize your customer
              experience with fast, secure, scalable solutions.
              <span
                className={`d-lg-inline d-xl-inline ${
                  open ? "d-inline" : "d-none"
                }`}
              >
                &nbsp;We provide cutting-edge cloud-based application development
                services that assist our clients in accomplishing their business
                objectives with the highest efficiency and scalability.
              </span>
            </p>
            <span
              className={`d-lg-inline d-xl-inline ${
                open ? "d-inline" : "d-none"
              }`}
            >
              
              <ul className="homelist">
                <li>
                  <p>
                    
                      Integrate your software with a wide array of cloud-hosted
                      microservices.
                   
                  </p>
                </li>
                <li>
                  <p>
                    Take better control of your infrastructure and applications
                    with CI/CD tracking.
                  </p>
                </li>
                <li>
                  <p>
                    Experience better scalability, flexibility, and reliability
                    during development.
                  </p>
                </li>
                <li>
                  <p>
                    Enable DevOps to manage complex deployments in shorter
                    timeframe.
                  </p>
                </li>
                <li>
                  <p>
                    Ensure security on your device, application, network and
                    infrastructure.
                  </p>
                </li>
                <li>
                  <p>
                    Minimize downtimes using our resilient and agile Cloud
                    services.
                  </p>
                </li>
              </ul>
            </span>
            <button
              className="readmore_btn d-lg-none d-xl-none pl-0"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                ""
              ) : (
                <>
                  <span className="expand readmore pl-0">
                    Read More <FontAwesomeIcon icon="angle-double-right" />
                  </span>
                </>
              )}
            </button>
            <div className="d-lg-none d-xl-none d-md-none learning-mid-img ">
              <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/1.png" className="mgridimg1" />
            </div>
            <p className="mparagraphheading">
              Combined with server-based programming on a mobile device and rich
              database functionality, we deliver enterprise cloud applications
              that are high-performing and reliable. As a cloud application
              development company, we create ERPs, CRMs, and other cloud-native
              applications.
              <span className={` d-xl-inline d-none `}>
                {" "}
                In addition, our integration services enable us to deploy cloud
                applications in a public, private, or hybrid environment. Our
                solutions are tailored for a world of dynamically changing
                business landscape. We enable our clients to channel their
                energies into growth by enabling seamless and reliable cloud
                migration.
              </span>
            </p>
            <div className="d-lg-none d-xl-none d-md-none learning-mid-img mt-4">
              <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/3.png" className="mgridimg1" />
            </div>

            <br />
          </Col>
          <Col lg={5} md={12} sm={12} className="d-none d-md-block mt-2">
            <Row>
              <Col md={6} className="columntop">
                <div>
                  <img alt="" src={cg1} className="mgridimg1" />
                </div>
                <div>
                  <img alt="" src={cg2} className="mgridimg2" />
                </div>
              </Col>
              <Col md={6} className="mt-5">
                <div>
                  <img alt="" src={cg3} className="mgridimg1" />
                </div>
                <br />
                <div>
                  <img alt="" src={cg4} className="mgridimg1" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
