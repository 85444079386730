import { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Collapse, Container, Image, Row } from "react-bootstrap";
import arrowimg from "../../../images/home/arrow.svg";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PartnerSection = () => {
  const { width } = useViewport();
  const breakpoint = 1025;
  const [open, setOpen] = useState(false);
  return (
    <Container className="containerpadding mt-0">
      <Row>
        <Col md={12} lg={12} xl={6} sm={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h3 className="subheader">
              Connecting your ideas with right Technology
            </h3>
            <h2 className="h2heading1">
              <span className="bold">Excellence is a Habit</span>
            </h2>
          </ScrollAnimation>

          <p className="paragraphheading">
            With a robust specialisation in technology development, backed by
            nearly a decade of software development experience, JMT Worldwide
            LLC is aimed to assist you tackle any problem with cutting-edge
            solutions.
          </p>
          <Collapse in={width < breakpoint ? open : true}>
            <div id="example-collapse-text">
             
              <ul className="homelist mt-4">
                <li>
                  <p>
                    We are a cross-functional team committed to providing
                    quality IT Services.
                  </p>
                </li>

                <li>
                  <p>
                    We develop custom and intuitive solutions to meet your
                    demands.
                  </p>
                </li>

                <li>
                  <p>
                    We cater to your true needs and surpass your expectations.
                  </p>
                </li>
                <li>
                  <p>Our Work Processes are aimed to achieve excellence.</p>
                </li>
              </ul>
            </div>
          </Collapse>

          <button
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
            className="d-xl-none collapse-btn mb-2 mt-0"
          >
            {open ? (
              ""
            ) : (
              <>
                <span className="expand readmore">
                  Read More <FontAwesomeIcon icon="angle-double-right" />
                </span>
              </>
            )}
          </button>
          <div className="image-box d-block d-md-none mb-4">
            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/image3.webp" className="gridimg2" />
          </div>
          <p className="paragraphheading">
            we are geared to help companies redesign, re-innovate and
            re-engineer to springboard into future opportunities.
          </p>
        </Col>

        <Col md={12} lg={12} xl={6} sm={12}>
          <Row>
            <Col md={6}>
              <div className="image-box d-none d-md-block">
                <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/image1.webp" className="gridimg1" />
                <div className="btn-floating">
                  <h3 className="expheading">08</h3>
                  <p className="expsubheading">YEARS OF EXPERIENCE</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="image-box d-none d-md-block">
                <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/image2.webp" className="gridimg2" />
              </div>

              <div class="image-box d-none d-md-block">
                <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/image3.webp" className="gridimg3" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
