import { Container } from "react-bootstrap";

export const AdobeProCarousel = () => {
  return (
    <div className="hero-styles adobehero">
      <Container>
        <p className="mwhiteparagraph" style={{ overflowX: "hidden" }}>
          <h1 className="cloudcaptionheading">Adobe</h1>

          <h4>
          Program Management for Technology Partner Program, Campaign Management and Enterprise Marketing
          </h4>
        </p>
      </Container>
    </div>
  );
};
