import React, { PureComponent } from "react";
import Layout from "../../components/layout";
import "react-multi-carousel/lib/styles.css";
import "../../pages/mobiledevelopment/mobiledevelopment.css";
import "../../pages/mobiledevelopment/mobiledevelopment.scss";
import {
  XtimeCarousel,
  XtimeAbout,
  XtimeCloud,
  XtimeAnalysis,
  XtimeProducts,
  XtimeProcess,
  XtimeWorkflow,
  XtimeTechnology,
  XtimeMigration,
  XtimeFinaldesign,
  XtimeNav,
} from "../../components/section";
import "../emasters/emasters.css";
import "./xtime.css";

class Xtime extends PureComponent {
  render() {
    return (
      <Layout>
        <XtimeCarousel />
        <XtimeNav />
        <XtimeAbout />
        <XtimeCloud />
        <XtimeAnalysis />
        <XtimeProducts />
        <XtimeProcess />
        <XtimeWorkflow />
        <XtimeTechnology />
        <XtimeMigration />
        <XtimeFinaldesign />
      </Layout>
    );
  }
}

export default Xtime;
