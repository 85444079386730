import { Container } from "react-bootstrap";

export const WebCarousel = () => {
  return (
    <div className="hero-styles webhero">
       <Container>
        <p className="mwhiteparagraph" style={{ overflowX: "hidden" }}>
          <h1 className="cloudcaptionheading">Web Development</h1>

          <h4>
          Increase the eﬃciency of your business via relevant web development solutions
          </h4>
        </p>
      </Container>
    </div>
  );
};
