import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import image1 from "../../../images/webdevelopment/developing-programmer-team-development-website-design-and-coding-technologies-working-in-software-company-office-140335451.png";
import image2 from "../../../images/webdevelopment/web-designer-drawing-website-development-wireframe-68755251.png";
import image3 from "../../../images/webdevelopment/digital-marketing-businessman-holding-global-interface-128341166.png";
import image4 from "../../../images/webdevelopment/young-female-entrepreneur-working-in-a-home-office-58215150.png";
import arrowimg from "../../../images/arrow.svg";
import { useViewport } from "../../common/designParts/useViewport";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import imagemob1 from "../../../images/webdevelopment/image1.png";
import imagemob2 from "../../../images/webdevelopment/image2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const WebDigital = () => {
  const { width } = useViewport();
  const breakpoint = 1025;
  const [open, setOpen] = useState(false);
  return (
    <Container className="mcontainerpaddingnew learningmanage">
      <Row>
        <Col lg={7} md={12} sm={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
              <h3 className="subheader">
            
              Delivering tangible business results through web solutions
              </h3>
              <h2 className="h2heading1">
              <span className="bold">Transform your business with web</span>
            </h2>
          </ScrollAnimation>
          <AnimatedLine
            className="animateline"
            width={"300px"}
            margin={"-5px 0 10px 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
          <p className="mparagraphheadingtop">
            With our unparalleled web development services, we will create a
            website that meets your organization’s specific requirements.
            <span
              className={`d-lg-inline d-xl-inline ${
                open ? "d-inline" : "d-none"
              }`}
            >
              &nbsp;Our mission is to build innovative, business-focused websites that
              stimulate creativity and increase your profitability.
            </span>
          </p>
          <span
            className={`d-lg-inline d-xl-inline ${
              open ? "d-inline" : "d-none"
            }`}
          >
           
            <ul className="homelist">
              <li>
                <p>
                  Establish your digital presence on cross platforms and
                  multiple devices.
                </p>
              </li>
              <li>
                <p>
                  Build unique responsive web solutions which converts visitors
                  to valued customers.
                </p>
              </li>
              <li>
                <p>
                  Create User-engaging Web designs to boost User Engagement.
                </p>
              </li>
              <li>
                <p>
                  Stay at the top of the search game by utilizing our SEO
                  strategies to reach users.
                </p>
              </li>
              <li>
                <p>
                  Customise your existing web application to tune according to
                  your business goals.
                </p>
              </li>
              <li>
                <p>
                  Avoid security breaches with our ongoing maintenance and
                  support services.
                </p>
              </li>
            </ul>
          </span>
          <button
            className="readmore_btn d-lg-none d-xl-none pl-0"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              ""
            ) : (
              <>
                <span className="expand readmore">
                  Read More <FontAwesomeIcon icon="angle-double-right" />
                </span>
              </>
            )}
          </button>
          <div className="d-lg-none d-xl-none d-md-none learning-mid-img mb-3">
            <img alt="" src={imagemob1} className="mgridimg1" />
          </div>
          <p className="mparagraphheading">
            Through nearly a decade of extensive experience, we have developed
            expertise in many Web Development platforms. Our experts specialize
            in creating brand-oriented, interactive, and business-ready custom
            web solutions.
            <span className={`d-xl-inline d-none `}>
              {" "}
              We offer comprehensive web development services, including bespoke
              web development, migration, update, and ongoing maintenance. If
              you are seeking a comprehensive web development solution to ensure
              growth-oriented digital transformation, our full-stack web
              development services are the best. We offer all major robust web
              solutions, including e-commerce portals, CMS, ERP, chatbots, and
              custom apps.
            </span>
          </p>
          <div className="d-lg-none d-xl-none d-md-none learning-mid-img mt-4">
            <img alt="" src={imagemob2} className="mgridimg1" />
          </div>

          <br />
        </Col>
        <Col lg={5} md={12} sm={12} className="d-none d-md-block mt-2">
          <Row>
            <Col md={6} className="columntop">
              <div>
                <img alt="" src={image1} className="mgridimg1" />
              </div>
              <div>
                <img alt="" src={image2} className="mgridimg2" />
              </div>
            </Col>
            <Col md={6} className="mt-5">
              <div>
                <img alt="" src={image3} className="mgridimg1" />
              </div>
              <br />
              <div>
                <img alt="" src={image4} className="mgridimg1" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
