import ScrollAnimation from "react-animate-on-scroll";
import { Col, Collapse, Container, Row } from "react-bootstrap";

import plat1 from "../../../images/icons/oracle.png";
import plat2 from "../../../images/portal/adobe-1.svg";

import plat3 from "../../../images/icons/ibm.png";

import plat4 from "../../../images/icons/servicenow.png";

import plat5 from "../../../images/icons/microsoft.png";

import plat6 from "../../../images/icons/emc.png";
import plat7 from "../../../images/icons/opencms.png";
import plat8 from "../../../images/icons/shopify.png";
import plat9 from "../../../images/icons/wordpress.png";
import plat10 from "../../../images/icons/joomla.png";
import plat11 from "../../../images/icons/drupal.png";
import icon1 from "../../../images/portal/icons8-stackshare-50.png";
import icon2 from "../../../images/portal/content.png";

import "react-multi-carousel/lib/styles.css";
import { useViewport } from "../../common/designParts/useViewport";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";

export const PortalExpertise = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [openExp, setOpenExp] = useState(false);
  const [openWeb, setOpenWeb] = useState(false);
  return (
    <Container className="mcontainerpadding ">
      <div id="expertise"></div>
      <Row>
        <Col md={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
              Our Development{" "}
              <span className="bold">
                Platforms & Frameworks Expertise
              </span>
            </h2>
          </ScrollAnimation>

          <AnimatedLine className="animateline" width={"250px"} margin={"-10px 0 10px 0"}>
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
        </Col>
      </Row>
      <br />

      <Row>
        <Col md={6} style={{ padding: "0 2%" }}>
          <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
            <div className="portal-exp-icon">
              <img src={icon1} alt="#" />
            </div>

            <div className="campaignExpertise">
              <Row>
                <h4>Certified Platforms</h4>
              </Row>

              <Row className="pt-5">
                <Col col={4}>
                  <div className="exp-img ">
                    <div>
                      <img src={plat1} alt="icon" />
                    </div>
                    <p>Oracle</p>
                  </div>
                </Col>
                <Col col={4}>
                  <div className="exp-img">
                    <div>
                      <img src={plat2} alt="icon" />
                    </div>
                    <p>Adobe</p>
                  </div>
                </Col>
                <Col col={4}>
                  <div className="exp-img">
                    <div>
                      <img src={plat3} alt="icon" />
                    </div>
                    <p>IBM</p>
                  </div>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col col={4}>
                  <div className="exp-img">
                    <div>
                      <img src={plat4} alt="icon" />
                    </div>
                    <p>ServiceNow</p>
                  </div>
                </Col>
                <Col col={4}>
                  <div className="exp-img">
                    <div>
                      <img src={plat5} alt="icon" />
                    </div>
                    <p>Microsoft</p>
                  </div>
                </Col>

                <Col col={4}>
                  <div className="exp-img">
                    <div>
                      <img src={plat6} alt="icon" />
                    </div>
                    <p>EMC</p>
                  </div>
                </Col>
              </Row>
            </div>
          </ScrollAnimation>
        </Col>

        <Collapse
          in={width < breakpoint ? openWeb : true}
          className="collapse-div"
        >
          <div id="collapse-services-learning">
            <Col md={6} style={{ padding: "0 2%" }}>
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div className="portal-exp-icon">
                  <img src={icon2} alt="#" />
                </div>
                <div className="campaignExpertise">
                  <Row>
                    <h4>Content Management Systems</h4>
                  </Row>

                  <Row className="pt-5">
                    <Col xs={4}>
                      <div className="exp-img">
                        <div>
                          <img src={plat7} alt="icon" />
                        </div>
                        <p>OpenCMS</p>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="exp-img">
                        <div>
                          <img src={plat8} alt="icon" />
                        </div>
                        <p>Shopify</p>
                      </div>
                    </Col>

                    <Col xs={4}>
                      <div className="exp-img">
                        <div>
                          <img src={plat9} alt="icon" />
                        </div>
                        <p>Wordpress</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-3">
                    <Col xs={6}>
                      <div className="exp-img">
                        <div>
                          <img src={plat10} alt="icon" />
                        </div>
                        <p>Joomla</p>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="exp-img">
                        <div>
                          <img src={plat11} alt="icon" />
                        </div>
                        <p>Drupal</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </ScrollAnimation>
            </Col>
          </div>
        </Collapse>
        <button
          onClick={() => setOpenWeb(!openWeb)}
          aria-controls="collapse-services-learning"
          aria-expanded={openWeb}
          className="d-xl-none d-lg-none d-md-none collapse-btn text-center mr-auto ml-auto pt-2"
        >
           {openWeb ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Expand More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
        </button>
      </Row>
    </Container>
  );
};
