import { Col, Container, Row } from "react-bootstrap"
import "react-multi-carousel/lib/styles.css";


export const DigitalTransformNav = () => {
    return (
        <Container fluid  className="d-none d-md-block">
            <Row>
                <Col md={12} className="pl-0 pr-0">
                    <div class="mobileflex-container">
                        <div><a href="/digital-transformation#digitalservice" className="blacknav">Our Services</a></div>
                        <div><a href="/digital-transformation#process" className="blacknav">Working Process</a></div>
                        <div><a href="/digital-transformation#story" className="blacknav">Case Studies</a></div>
                        <div><a href="/digital-transformation#expertise" className="blacknav">Our Expertise</a></div>
                       
                    </div>
                </Col>
            </Row>
        </Container>

    )
}