import { Container } from "react-bootstrap";
import contactJmt from "../../../images/contact-banner.png"

export const ContactCarousel = () => {
  return (
    <div className="contacthero">
      <img src={contactJmt} alt="about" />
    </div>
  );
};
