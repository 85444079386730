import React from "react";
import { Container, Row, Col, Image, Collapse } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ServiceCard from "../../common/designParts/serviceCardComponent";

export const CloudDevelopmentService = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [open, setOpen] = useState(false);
  const [openservice, setOpenservice] = useState(false);
  return (
    <div>
      <div id="cloudservice"></div>
      <div className="mbg-img">
        <Container
          className="mcontainerpadding "
          style={{ background: "none" }}
        >
          <Row>
            <Col md={12}>
              <ScrollAnimation
                animateIn="animate-text"
                duration={5}
                animateOnce={true}
              >
                <h2 className="h2heading1">
                  Our Cloud Application{" "}
                  <span className="bold">Development Services</span>
                </h2>
              </ScrollAnimation>

              <AnimatedLine
                className="animateline"
                width={"250px"}
                margin={"-5px 0"}
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                  className="no-animation"
                >
                  <div className="line"></div>
                </ScrollAnimation>
              </AnimatedLine>
            </Col>
          </Row>
          <br />

          <Row>
            <Col md={6} lg={4}>
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div className="card digicard">
                  <div className="portal-service-img">
                    <Image src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/cd1.png" className="serveimg" />
                  </div>
                  <p className="devheading"><span className="bold">Cloud-based SaaS Application</span></p>
                  <p className="devparagraph">
                    Our cloud team builds solutions that are extremely scalable,
                    highly secure, and efficiently designed to optimize the user
                    experience and maximize your ROI.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={6} lg={4}>
              <ServiceCard
                id="collapse-services-dtl"
                heading="Re-engineering Existing Application"
                image="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/cd2.png"
                content="Assist businesses for strategic cloud consultation related to planning, architecture, infrastructure, deployment, integration, optimization, delivery and support."
              />
            </Col>
            <Col md={6} lg={4}>
              <ServiceCard
                id="collapse-service-dtl2"
                heading="Cloud Migration"
                image="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/cd3.png"
                content="To support transformative journey for migrating legacy applications to the cloud, moving cloud application among cloud vendors or configuring a multi-provider infrastructure."
              />
            </Col>

            <Collapse
              in={width < breakpoint ? open : true}
              className="collapse-div"
            >
              <div id="collapse-services-learning">
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl3"
                    heading="Cloud Integration"
                    image="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/cd4.png"
                    content="Accelerate your business productivity by Integrating processes, databases, and network resources to enable data flow and transactions across different systems while going cloud."
                  />
                </Col>
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl4"
                    heading="Application Testing"
                    image="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/cd5.png"
                    content="Maximize your productivity with our Cloud-Native services that include, Design, Develop and deploy cloud-native applications to get a competitive advantage and drive your business growth."
                  />
                </Col>
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl5"
                    heading="DevOps & Support"
                    image="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/cd6.png"
                    content="Effective cloud performance monitoring and optimization services. Our support gives you complete visibility into the health and availability of the entire cloud infrastructure."
                  />
                </Col>
              </div>
            </Collapse>
            <button
              onClick={() => setOpen(!open)}
              aria-controls="collapse-services-learning"
              aria-expanded={open}
              className="d-xl-none d-lg-none d-md-none collapse-btn text-center mr-auto ml-auto pt-2"
            >
              {open ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Expand More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
            </button>
          </Row>
        </Container>
      </div>
    </div>
  );
};
