import ScrollAnimation from "react-animate-on-scroll";
import { Container } from "react-bootstrap";

export const UiCarousel = () => {
  return (
    <div className="hero-styles uihero">
      <Container>
        <p className="mwhiteparagraph" style={{ overflowX: "hidden" }}>
          <h1 className="cloudcaptionheading">UI/UX Services</h1>

          <h4>
          With a consistent building process, we meet deadlines and deliver a product that is right on target
          </h4>
        </p>
      </Container>
    </div>
  );
};
