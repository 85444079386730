import App from "./pages/App";
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare, faMugHot, faChevronRight, faHamburger, faTimes, faChevronDown, faChevronUp, faAngleDoubleRight , faChevronLeft, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
library.add( faCheckSquare, faMugHot,faChevronRight, faHamburger, faTimes, faChevronDown, faChevronUp, faAngleDoubleRight, faChevronLeft, faTimesCircle);
render(
  <Router>
      
    <App />
    
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
