import { Col, Container, Row, Image } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
// import AliceCarousel from "react-alice-carousel";
// import "react-alice-carousel/lib/alice-carousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const IndustriesWeServe = () => {
  const { width } = useViewport();
  const breakpoint = 800;

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    accessibility: true,
  };
  return (
    <Container className="commoncontainer">
      <Row>
        <Col md={12}>
          <ScrollAnimation
            animateIn="animate-text animate-text-home"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
              Industries <span className="bold">We Serve</span>
            </h2>
          </ScrollAnimation>
          <AnimatedLine
            className="animateline"
            width={"150px"}
            margin={"-5px auto 10px auto"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
        </Col>
      </Row>

      {width < breakpoint ? (
        // <Row className="mb-3 industries-carousel">
          <Slider {...settings} className="industries-carousel mb-3">
            <div>
              <div className="card servecard">
                <Image
                  src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/education.png"
                  className="serveimg"
                />
                <p className="serveheading">Education</p>
              </div>
            </div>
            <div>
              <div className="card servecard">
                <Image
                  src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/banking.png"
                  className="serveimg"
                />
                <p className="serveheading">Banking</p>
              </div>
            </div>
            <div>
              <div className="card servecard">
                <Image
                  src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/retail.png"
                  className="serveimg"
                />
                <p className="serveheading">Retail</p>
              </div>
            </div>
            <div>
            <div className="card servecard">
              <Image
                src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/finance.png"
                className="serveimg"
              />
              <p className="serveheading">Finance</p>
            </div>
            </div>
            <div>
            <div className="card servecard">
              <Image
                src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/healthcare.png"
                className="serveimg"
              />
              <p className="serveheading">Healthcare</p>
            </div>
            </div>
            <div>
            <div className="card servecard">
              <Image
                src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/automobile.png"
                className="serveimg"
              />
              <p className="serveheading">Automobile</p>
            </div>
            </div>
            <div>
            <div className="card servecard">
              <Image
                src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/insurance.png"
                className="serveimg"
              />
              <p className="serveheading">Insurance</p>
            </div>
            </div>
            <div>
            <div className="card servecard">
              <Image
                src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/travel.png"
                className="serveimg"
              />
              <p className="serveheading">Travel</p>
            </div>
            </div>
          </Slider>
        // </Row>
      ) : (
        <>
          <Row>
            <Col xs={12} sm={12} md={6} lg={3} xl={3} className="fade-in">
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div className="card servecard">
                  <Image
                    src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/education.png"
                    className="serveimg"
                  />
                  <p className="serveheading">Education</p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} xl={3} className="fade-in">
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div className="card servecard">
                  <Image
                    src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/banking.png"
                    className="serveimg"
                  />
                  <p className="serveheading">Banking</p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} xl={3} className="fade-in">
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div className="card servecard">
                  <Image
                    src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/retail.png"
                    className="serveimg"
                  />
                  <p className="serveheading">Retail</p>
                </div>
              </ScrollAnimation>
            </Col>

            <Col xs={12} sm={12} md={6} lg={3} xl={3} className="fade-in">
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div className="card servecard">
                  <Image
                    src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/finance.png"
                    className="serveimg"
                  />
                  <p className="serveheading">Finance</p>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
          <Row className="rowpadding">
            <Col xs={12} sm={12} md={6} lg={3} xl={3} className="fade-in">
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div className="card servecard">
                  <Image
                    src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/healthcare.png"
                    className="serveimg"
                  />
                  <p className="serveheading">Healthcare</p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={3}
              xl={3}
              className="fade-in"
              animateIn="fadeIn"
              duration={2}
              animateOnce={true}
            >
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div className="card servecard">
                  <Image
                    src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/automobile.png"
                    className="serveimg"
                  />
                  <p className="serveheading">Automobile</p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={3}
              xl={3}
              className="fade-in"
              animateIn="fadeIn"
              duration={2}
              animateOnce={true}
            >
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div className="card servecard">
                  <Image
                    src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/insurance.png"
                    className="serveimg"
                  />
                  <p className="serveheading">Insurance</p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={3}
              xl={3}
              className="fade-in"
              animateIn="fadeIn"
              duration={2}
              animateOnce={true}
            >
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div className="card servecard">
                  <Image
                    src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/travel.png"
                    className="serveimg"
                  />
                  <p className="serveheading">Travel</p>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};
