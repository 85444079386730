import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export const UiUxInfo = () => {
  return (
    <div>
      <div
        className="uibg learningbgInfo
"
      >
        <ScrollAnimation
          animateIn="fadeInRight"
          duration={2}
          animateOnce={true}
        >
          <p className="">
            You can count on us to guide you through the complex process of
            improving your user experience. We offer a complete line of user
            experience services to help you design the perfect UX strategy.
          </p>
        </ScrollAnimation>
      </div>
    </div>
  );
};
