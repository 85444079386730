import React, { PureComponent } from "react";
import Layout from "../../components/layout";
import "react-multi-carousel/lib/styles.css";
import "../../components/section/cloudProcess/cloudprocess.css";
import "../../pages/cloudservices/cloudServices.css";
import "./digitaltransformation.css";
import {
  DigitalTCarousel,
  DigitalTransformNav,
  InnovativeDigital,
  DigitalTransformService,
  DigiInfo,
  DigitalCapability,
  DigitalExpertise, DigitalStory, DigitalProcess
} from "../../components/section";

class DigitalTransformation extends PureComponent {
  render() {
    return (
      <Layout>
        <DigitalTCarousel />
        <DigitalTransformNav />
        <InnovativeDigital />
        <DigitalTransformService />
        <DigiInfo />
        <DigitalCapability />
        <DigitalExpertise />
        <DigitalStory />
        <DigitalProcess/>
      </Layout>
    );
  }
}

export default DigitalTransformation;
