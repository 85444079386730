
import { Container } from "react-bootstrap";

export const LearningCarousel = () => {
  return (
    <div className="hero-styles learningHero">
       <Container>
        <p className="mwhiteparagraph" style={{ overflowX: "hidden" }}>
          <h1 className="cloudcaptionheading">Learning Management Services</h1>

          <h4>
          Our team of trained professionals delivers a range of LMS solutions to ensure you will get what you are looking for
          </h4>
        </p>
      </Container>
    </div>
  );
};
