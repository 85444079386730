import { Container } from "react-bootstrap";

export const MobileAppCarousel = () => {
  return (
    <div className="hero-styles mhero">
      <Container>
        <p className="mwhiteparagraph" style={{ overflowX: "hidden" }}>
          <h1 className="cloudcaptionheading">
            Mobile Application Development
          </h1>

          <h4>Offering end-to-end mobile application development services for consumers as well as businesses.</h4>
        </p>
      </Container>
    </div>
  );
};
