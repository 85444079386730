import React from "react";
import { Container, Row, Col, Card, Badge } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import cloudstory1 from "../../../images/cloudstory1.webp";
import xtime from "../../../images/xtimewhite.png";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";

export const CloudSuccessStories = () => {
  return (
    <div className="emaster-section pt-5 pb-5" style={{ background: "#fff" }}>
      <div id="xtimestory"></div>

      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                Our <span className="bold">Success Stories</span>
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"100px"}
              margin={"0px 0 15px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        <div className="detail-section">
          <Row>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <div className="detail">
                <img
                  alt=""
                  src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home/xtimewhite.png"
                  fluid
                  style={{
                    maxWidth: "60%",
                    display: "block",
                    margin: "10px 0",
                  }}
                />
                <h3>
                  Implementation of SaaS Based App for Scheduling Appointments.
                </h3>
                <div className="badge-section d-none d-lg-block">
                  <Badge variant="danger">iOS</Badge>
                  <Badge variant="danger">ANDROID</Badge>
                  <Badge variant="danger">WEB DEVELOPMENT</Badge>
                  <Badge variant="danger">CLOUD SERVICES</Badge>
                  <Badge variant="danger">UI/UX SERVICES</Badge>
                  <Badge variant="danger">MOBILE APP DEVELOPMENT</Badge>
                </div>

                <p className="d-none d-lg-block">
                  Xtime helps dealers adapt to changing customer expectations by
                  leveraging the power of the cloud. With a connected service
                  solution providing exceptional support, dealers are enabled to
                  deliver an exceptional auto-repair experience.
                </p>
              </div>
            </Col>

            <Col xs={4} sm={4} md={4} lg={4} xl={4} className="p-0">
              <div className="image-detail webmaster-img">
                <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/cloudstory1.webp" fluid />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
