import React from "react";
import { Container } from "react-bootstrap";

export const XtimeCarousel = () => {
  return (
    <div className="xtime-hero hero-styles">
      <Container>
        <p className="mwhiteparagraph" style={{ overflowX: "hidden" }}>
          <h1 className="cloudcaptionheading pt-5">Xtime</h1>

          <h4>
            Technical Management and Implementation of SaaS Based Solutions for
            Automotive industry OEs across North America and Australia.
          </h4>
        </p>
      </Container>
    </div>
  );
};
