import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import rightcircle from "../../../images/rightcircle.png";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import web_process from "../../../images/qatesting/process.png";
import { useViewport } from "../../common/designParts/useViewport";
import styled from "styled-components";

export const QaProcess = () => {
  const { width } = useViewport();
  const breakpoint = 992;
  const [webProcess, setWebprocess] = useState("requirement");

  const QaBox = styled.div`
    height: 100px;
    width: 100px;
    background: #7b7d7e;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    padding: 27px 5px 0 5px;
    position: absolute;
    cursor: pointer
    top: ${(props) => props.top};
    left: ${(props) => props.left};
  `;

  return (
    <div>
      {" "}
      <div id="process"></div>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                Our QA Testing
                <span className="bold"> Process Steps</span>
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"200px"}
              margin={"-5px 0 0px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        {width < breakpoint ? (
          <Row>
            <Col md={12} className="mb-3 mt-0">
              <img src={web_process} alt="" style={{ width: "100%" }} />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md={9} className="qa-process-web">
              <QaBox
                left="20%"
                top="12%"
                className={`qa-mobile-process ${
                  webProcess === "requirement" && "active"
                } `}
                onClick={() => setWebprocess("requirement")}
              >
                <p>Requirement Analysis</p>
              </QaBox>
              <QaBox
                left="75%"
                top="12%"
                className={`qa-mobile-process ${
                  webProcess === "planning" && "active"
                } `}
                onClick={() => setWebprocess("planning")}
              >
                <p>
                  Test <br /> Planning
                </p>
              </QaBox>
              <QaBox
                left="75%"
                top="45%"
                className={`qa-mobile-process ${
                  webProcess === "design" && "active"
                } `}
                onClick={() => setWebprocess("design")}
              >
                <p>
                  Test <br /> Design
                </p>
              </QaBox>
              <QaBox
                left="30%"
                top="45%"
                className={`qa-mobile-process ${
                  webProcess === "report" && "active"
                } `}
                onClick={() => setWebprocess("report")}
              >
                <p>Test Execution & Defect Reporting</p>
              </QaBox>
              <QaBox
                left="-10%"
                top="65%"
                className={`qa-mobile-process ${
                  webProcess === "regression" && "active"
                } `}
                onClick={() => setWebprocess("regression")}
              >
                <p>Regression Testing</p>
              </QaBox>
              <QaBox
                left="45%"
                top="85%"
                className={`qa-mobile-process ${
                  webProcess === "release" && "active"
                } `}
                onClick={() => setWebprocess("release")}
              >
                <p>Release Testing</p>
              </QaBox>
            </Col>

            <Col md={3} className="initiancolumn mobile-pro-card pt-5">
              <div
                className="card initiancard"
                style={
                  webProcess === "requirement"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <div className="d-flex">
                      <span className="inih">
                        <span className="bold">01</span>
                      </span>
                      <span className="inip" style={{ fontSize: "16px" }}>
                        Requirement Analysis
                      </span>
                    </div>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Analysis of Functional and Non- Functional Requirements.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Analysis of Testable requirements.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Prevention of software defects.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Facilitate upcoming test design activities.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* second */}
              <div
                className="card initiancard"
                style={
                  webProcess === "planning"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <div className="d-flex align-items-middle">
                      <span className="inih">
                        <span className="bold">02</span>
                      </span>
                      <span className="inip" style={{ fontSize: "16px" }}>
                        Test Planning
                      </span>
                    </div>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Build a Test Plan that cover the Testing Scope and contain
                      the Testing Strategy.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Estimate Project budget and deadlines.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Fix Bug-Tracking and reporting Deadlines.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* third */}
              <div
                className="card initiancard"
                style={
                  webProcess === "design"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <div className="d-flex align-items-middle">
                      <span className="inih">
                        <span className="bold">03</span>
                      </span>
                      <span className="inip" style={{ fontSize: "16px" }}>
                        Test Design
                      </span>
                    </div>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Prepare design test-cases or checklists covering software
                      requirements.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Create Automation test scenarios in this stage.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Prepare test environment for test case execution.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* fourth */}
              <div
                className="card initiancard"
                style={
                  webProcess === "report"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <div className="d-flex align-items-middle">
                      <span className="inih">
                        <span className="bold">04</span>
                      </span>
                      <span className="inip" style={{ fontSize: "16px" }}>
                        Test Execution & Defect Reporting
                      </span>
                    </div>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Execute the planned design test cases.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Submit the defects in the tracking system.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Generate test reports and test scripts.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* fifth */}
              <div
                className="card initiancard"
                style={
                  webProcess === "regression"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <div className="d-flex align-items-middle">
                      <span className="inih">
                        <span className="bold">05</span>
                      </span>
                      <span className="inip" style={{ fontSize: "16px" }}>
                        Regression Testing
                      </span>
                    </div>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Regression testing done to make sure the fix are not
                      broke.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Re-testing different functionalities.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Testing to ensure that the functionality is in compliance
                      with the requirements.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* sixth */}
              <div
                className="card initiancard"
                style={
                  webProcess === "release"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <div className="d-flex align-items-middle">
                      <span className="inih">
                        <span className="bold">06</span>
                      </span>
                      <span className="inip" style={{ fontSize: "16px" }}>
                        Release Testing
                      </span>
                    </div>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Monitor the effectiveness of the changes to the software.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Continuously assess the QA process, audit and plans to
                      check conformity.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Perform smoke testing to check the stability of the build.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};
