import ScrollAnimation from "react-animate-on-scroll";
import { Col, Collapse, Container, Image, Row } from "react-bootstrap";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useState } from "react";



export const OurFocusAreaSection = () => {
  const { width } = useViewport();
  const breakpoint = 1025;
  const [open, setOpen] = useState(false);

  return (
    <div className="bg-img" style={{ paddingBottom: "70px" }}>
      <Container fluid className="containerpadding">
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text animate-text-home"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                <span className="bold">Our Focus Areas </span>& Solutions
              </h2>
            </ScrollAnimation>
            <AnimatedLine
              className="animateline"
              width={"200px"}
              margin={"0px auto 15px auto"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
      </Container>

      {width < breakpoint ? (
        <>
          <div className="focusAreaView">
            <div onClick={() => (window.location = "/ui-ux-services")}>
              <img
                alt=""
                src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/ui-min.webp"
              />

              <button
                type="button"
                className="btn btn-default uibtn btn-image-hover"
              >
                UI/UX Services
              </button>
            </div>
            <div onClick={() => (window.location = "/digital-transformation")}>
              <img
                alt=""
                src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/digital_trans.webp"
              />

              <button
                type="button"
                className="btn btn-default uibtn btn-image-hover"
              >
                Digital Transformation
              </button>
            </div>
            <div onClick={() => (window.location = "/mobile-development")}>
              <img
                alt=""
                src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/mobile_development.webp"
              />

              <button
                type="button"
                className="btn btn-default uibtn btn-image-hover"
              >
                Mobile Development
              </button>
            </div>
            <Collapse in={width < breakpoint ? open : true}>
              <div id="collapse-images">
                <div onClick={() => (window.location = "/workflow-automation")}>
                  <img
                    alt=""
                    src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/workflow-min.webp"
                  />

                  <button
                    type="button"
                    className="btn btn-default uibtn btn-image-hover"
                  >
                    Workflow Automation
                  </button>
                </div>
                <div onClick={() => (window.location = "/cloud-services")}>
                  <img
                    alt=""
                    src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/cloud_services-min.webp"
                  />

                  <button
                    type="button"
                    className="btn btn-default uibtn btn-image-hover"
                  >
                    Cloud Services
                  </button>
                </div>
                <div onClick={() => (window.location = "/web-development")}>
                  <img
                    alt=""
                    src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/web-min.webp"
                  />

                  <button
                    type="button"
                    className="btn btn-default uibtn btn-image-hover"
                  >
                    Web Development
                  </button>
                </div>
                <div
                  onClick={() => (window.location = "/portal-collaboration")}
                >
                  <img
                    alt=""
                    src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/portal-min.webp"
                  />

                  <button
                    type="button"
                    className="btn btn-default uibtn btn-image-hover"
                  >
                    Portal and Collaboration
                  </button>
                </div>
                <div onClick={() => (window.location = "/qatesting")}>
                  <img
                    alt=""
                    src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/qa_testing.webp"
                  />

                  <button
                    type="button"
                    className="btn btn-default uibtn btn-image-hover"
                  >
                    QA Testing
                  </button>
                </div>
                <div onClick={() => (window.location = "/campaign-management")}>
                  <img
                    alt=""
                    src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/campaign-min.webp"
                  />

                  <button
                    type="button"
                    className="btn btn-default uibtn btn-image-hover"
                  >
                    Campaign Management
                  </button>
                </div>
                <div
                  onClick={() =>
                    (window.location = "/learning-management-system")
                  }
                >
                  <img
                    alt=""
                    src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/lms.webp"
                  />

                  <button
                    type="button"
                    className="btn btn-default uibtn btn-image-hover"
                  >
                    Learning Management System
                  </button>
                </div>
              </div>
            </Collapse>
          </div>
          <button
            onClick={() => setOpen(!open)}
            aria-controls="collapse-images"
            aria-expanded={open}
            className="d-xl-none collapse-btn text-center mr-auto ml-auto pt-2"
          >
             {open ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Expand More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
          </button>
        </>
      ) : (
        <ScrollAnimation
          animateIn="scroll-width"
          duration={5}
          animateOnce={true}
        >
          <div className="gridview containerpadding">
            <div
              className="image-hover-text-container box a"
              onClick={() => (window.location = "/ui-ux-services")}
            >
              <div className="image-hover-image">
                <img
                  alt=""
                  src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/ui-min.webp"
                />
                <button
                  type="button"
                  className="btn btn-default uibtn btn-image-hover"
                >
                  UI/UX Services
                </button>
              </div>
            </div>

            <div
              className="image-hover-text-container1 box b"
              onClick={() => (window.location = "/digital-transformation")}
            >
              <div className="image-hover-image1">
                <img
                  alt=""
                  src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/digital_trans.webp"
                />
                <button
                  type="button"
                  className="btn btn-default uibtn btn-image-hover ui-btn-long"
                >
                  Digital Transformation
                </button>
              </div>
            </div>

            <div
              className="image-hover-text-container2 box c"
              onClick={() => (window.location = "/mobile-development")}
            >
              <div className="image-hover-image2">
                <img
                  alt=""
                  src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/mobile_development.webp"
                />
                <button
                  type="button"
                  className="btn btn-default uibtn btn-image-hover ui-btn-long"
                >
                  Mobile Development
                </button>
              </div>
            </div>

            <div
              className="image-hover-text-container2 box d"
              onClick={() => (window.location = "/workflow-automation")}
            >
              <div className="image-hover-image2">
                <img
                  alt=""
                  src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/workflow-min.webp"
                />
                <button
                  type="button"
                  className="btn btn-default uibtn btn-image-hover ui-btn-long"
                >
                  Workflow Automation
                </button>
              </div>
            </div>

            <div
              className="image-hover-text-container2 box e"
              onClick={() => (window.location = "/cloud-services")}
            >
              <div className="image-hover-image2">
                <img
                  alt=""
                  src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/cloud_services-min.webp"
                />
                <button
                  type="button"
                  className="btn btn-default uibtn btn-image-hover ui-btn-long"
                >
                  Cloud Services
                </button>
              </div>
            </div>

            <div
              className="image-hover-text-container2 box f"
              onClick={() => (window.location = "/web-development")}
            >
              <div className="image-hover-image2">
                <img
                  alt=""
                  src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/web-min.webp"
                />
                <button
                  type="button"
                  className="btn btn-default uibtn btn-image-hover"
                >
                  Web Development
                </button>
              </div>
            </div>
            <div
              className="image-hover-text-container2 box g"
              onClick={() => (window.location = "/portal-collaboration")}
            >
              <div className="image-hover-image2">
                <img
                  alt=""
                  src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/portal-min.webp"
                />
                <button
                  type="button"
                  className="btn btn-default uibtn btn-image-hover ui-btn-long"
                >
                  Portal and Collaboration
                </button>
              </div>
            </div>

            <div
              className="image-hover-text-container2 box i"
              onClick={() => (window.location = "/qatesting")}
            >
              <div className="image-hover-image2">
                <img
                  alt=""
                  src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/qa_testing.webp"
                />
                <button
                  type="button"
                  className="btn btn-default uibtn btn-image-hover ui-btn-long"
                >
                  QA Testing
                </button>
              </div>
            </div>
            <div
              className="image-hover-text-container2 box j"
              onClick={() => (window.location = "/campaign-management")}
            >
              <div className="image-hover-image2">
                <img
                  alt=""
                  src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/campaign-min.webp"
                />
                <button
                  type="button"
                  className="btn btn-default uibtn btn-image-hover"
                >
                  Campaign Management
                </button>
              </div>
            </div>
            <div
              className="image-hover-text-container2 box j"
              onClick={() => (window.location = "/learning-management-system")}
            >
              <div className="image-hover-image2">
                <img
                  alt=""
                  src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/lms.webp"
                />

                <button
                  type="button"
                  className="btn btn-default uibtn btn-image-hover"
                >
                  Learning Management Systems
                </button>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      )}
    </div>
  );
};
