import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Collapse } from "react-bootstrap";

import image3 from "../../../images/adobe/analysis.png";

import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";

export const AdobeAnalysis = () => {
  const { width } = useViewport();
  const breakpoint = 1100;
  const [openlearning, setOpenlearning] = useState(false);

  return (
    <Container className="emastercontainer pt-3 pb-0">
      <div id="workflow"></div>
      <Row>
        <Col md={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
              <span className="bold">Primary Analysis </span> for the Campaign
            </h2>
          </ScrollAnimation>

          <AnimatedLine
            className="animateline"
            width={"200px"}
            margin={"-5px 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
        </Col>
        {width < breakpoint ? (
          <Col md={12}>
            <img
              src={image3}
              alt=""
              style={{ width: "100%", marginTop: "30px" }}
            />
            <Row>
              <Col xs={12} md={6}>
                <div className="adobe-analysis-card-mobile">
                  <div className="d-flex">
                    <h4>Pain Points</h4> <h1>01</h1>
                  </div>
                  <p>
                    The challenge the client faced was to re-engage 30,000+
                    contacts post-GDPR and convert those contacts into
                    re-engaged spending customers.
                  </p>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="adobe-analysis-card-mobile">
                  <div className="d-flex">
                    <h4>Goal</h4> <h1>02</h1>
                  </div>
                  <p>
                    Improve engagement Segment contacts by interests Generate an
                    increased unique click rate for the campaign
                  </p>
                </div>
              </Col>
              <Collapse in={openlearning}>
                <div id="collapse-learning">
                  <Col xs={12} md={6}>
                    <div className="adobe-analysis-card-mobile">
                      <div className="d-flex">
                        <h4>Our Solution</h4> <h1>03</h1>
                      </div>
                      <p>
                        The challenge the client faced was to re-engage 30,000+
                        contacts post-GDPR and convert those contacts into
                        re-engaged spending customers.
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="adobe-analysis-card-mobile">
                      <div className="d-flex">
                        <h4>Channels</h4> <h1>04</h1>
                      </div>
                      <p>
                        Responsive Email Marketing for all device screen types
                        Marketing automation
                      </p>
                    </div>
                  </Col>
                </div>
              </Collapse>
              <button
                onClick={() => setOpenlearning(!openlearning)}
                aria-controls="collapse-learning"
                aria-expanded={openlearning}
                className="d-xl-none d-lg-none collapse-btn text-center ml-3 pl-0 mt-2 mb-2"
              >
                {openlearning ? (
                  ""
                ) : (
                  <>
                    <span className="expand readmore">
                      Read More <FontAwesomeIcon icon="angle-double-right" />
                    </span>
                  </>
                )}
              </button>
            </Row>
          </Col>
        ) : (
          <Col md={12}>
            <div className="adobe-analysis-img">
              <img src={image3} alt="" />
              <div className="adobe-analysis-card">
                <div className="d-flex">
                  <h4>Pain Points</h4> <h1>01</h1>
                </div>
                <p>
                  The challenge the client faced was to re-engage 30,000+
                  contacts post-GDPR and convert those contacts into re-engaged
                  spending customers.
                </p>
              </div>

              <div className="adobe-analysis-card-2">
                <div className="d-flex">
                  <h4>Goals</h4> <h1>02</h1>
                </div>
                <p>
                  Improve engagement Segment contacts by interests Generate an
                  increased unique click rate for the campaign
                </p>
              </div>

              <div className="adobe-analysis-card-3">
                <div className="d-flex">
                  <h4>Our Solution</h4> <h1>03</h1>
                </div>
                <p>
                  The challenge the client faced was to re-engage 30,000+
                  contacts post-GDPR and convert those contacts into re-engaged
                  spending customers.
                </p>
              </div>
              <div className="adobe-analysis-card-4">
                <div className="d-flex">
                  <h4>Channel</h4> <h1>04</h1>
                </div>
                <p>
                  Responsive Email Marketing for all device screen types
                  Marketing automation
                </p>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
};
