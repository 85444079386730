import ScrollAnimation from "react-animate-on-scroll";
import { Col, Collapse, Container, Row } from "react-bootstrap";
import image1 from "../../../images/about/about/businesswomanV2.png";
import image2 from "../../../images/about/about/typing.webp";
import image3 from "../../../images/about/about/handshake.webp";
import { useViewport } from "../../common/designParts/useViewport";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";

export const AboutusInfo = () => {
  const { width } = useViewport();
  const breakpoint = 769;
  const [open, setOpen] = useState(false);
  return (
    <Container className="mcontainerpaddingnew learningmanage">
      <Row>
        <Col lg={6} md={12} sm={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
              Your implementation partner of choice
              <br />
              <span className="bold">Excellence is a Habit</span>
            </h2>
          </ScrollAnimation>
          <AnimatedLine
            className="animateline"
            width={"150px"}
            margin={"0px 0 10px 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
          <p className="mparagraphheadingtop">
            We believe that the future is digital. We also believe that using
            right technology solutions, whether for personal or corporate needs,
            enhances the progress.
            <span
              className={`d-lg-inline d-xl-inline ${
                open ? "d-inline" : "d-none"
              }`}
            >
              Hence, JMT Worldwide offers an optimised suite of software
              consulting services that enable businesses to develop business
              strategy, organizational capability, build brands, find customers,
              win tenders and achieve sustained growth.
            </span>
          </p>
          <span
            className={`d-lg-inline d-xl-inline ${
              open ? "d-inline" : "d-none"
            }`}
          >
            <ul className="homelist">
              <li>
                <p>Create Customer focussed Interaction Models</p>
              </li>
              <li>
                {" "}
                <p>
                  Build an effective Fault-tolerant, Time-tested Business
                  Continuity Plan
                </p>
              </li>

              <li>
                <p>Embrace Service Delivery Mechanisms that Can Be Measured</p>
              </li>
              <li>
                <p>Indulge in Transparent Business Engagement</p>
              </li>
              <li>
                <p>Deliver Effective and Efficient Solutions to maximize ROI</p>
              </li>
            </ul>
          </span>
          <button
            className="readmore_btn d-lg-none d-xl-none pl-0"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              ""
            ) : (
              <>
                <span className="expand readmore">
                  Read More <FontAwesomeIcon icon="angle-double-right" />
                </span>
              </>
            )}
          </button>
          <div className="d-lg-none learning-mid-img">
            <img
              alt=""
              src={image3}
              className="mgridimg1 d-none"
            />
          </div>
          <p className="mparagraphheading">
            With a metric-driven approach, we help companies build intelligent
            and real-time customer relationships.
            <span className={` d-xl-inline d-none `}>
              {" "}
              We enable this by leveraging
            the power of software technology through proprietary and best
            in-class digital marketing automation and data analytics platform.
            We provide integrated solutions that reduce costs and improve
            business agility through the combination of infrastructure and
            consulting services.
            </span>
          </p>
          {/* <div className="d-lg-none d-xl-none d-md-none learning-mid-img mt-4">
            <img
              alt=""
              src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/campaign/campaign2.png"
              className="mgridimg1"
            />
          </div> */}

          <br />
        </Col>
        <Col lg={6} md={12} sm={12} className="d-none d-md-block mt-2">
          <Row>
            <Col md={6}>
              <div class="image-box">
                <img
                  alt=""
                  src={image1}
                  className="gridimg1 d-none d-md-block"
                  style={{ maxHeight: "500px" }}
                />
                <div className="btn-floating">
                  <h3 className="expheading">08</h3>
                  <p className="expsubheading">YEAR OF EXPERIENCE</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div class="image-box img-about">
                <img
                  alt=""
                  src={image2}
                  className="gridimg2 d-none d-md-block"
                />
              </div>

              <div class="image-box img-about">
                <img
                  alt=""
                  src={image3}
                  className="gridimg3 d-none d-md-block"
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
