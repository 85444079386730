import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Collapse } from "react-bootstrap";

import image3 from "../../../images/emasters/undraw_Mobile_marketing_re_p77p.svg";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";

import { useViewport } from "../../common/designParts/useViewport";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const EmasterRelation = () => {
  const { width } = useViewport();
  const breakpoint = 992;
  const [openlearning, setOpenlearning] = useState(false);
  return (
      <>
      <div id="aboutproject"></div>
    <div style={{ backgroundColor: "#F8F8F8BA" }}>
      <Container
        className="learningmanage pb-5"
        style={{ backgroundColor: "#F8F8F8BA" }}
      >
        <Row>
          <Col lg={6} className="d-none d-lg-block"></Col>
          <Col lg={6} sm={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1 pt-3">
              Success of our <span className="bold">CRM</span> Application
                
              </h2>
            </ScrollAnimation>
            <AnimatedLine
              className="animateline"
              width={"200px"}
              margin={"-5px 0 15px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        <Row className="portal-cap-p mobile-space-inc">
          <Col lg={6}>
            <ScrollAnimation
              animateIn="fade-image-right"
              duration={2}
              animateOnce={true}
            >
              <img src={image3} alt="" className="learning-cap-img pt-5" />
            </ScrollAnimation>
          </Col>
          <Col lg={6}>
          <ul className="adobe-list">
              <li>
                <p>
                Setting Tasks and Managing contacts made easier - <span>Intuitive User Experience</span>
                </p>
              </li>
              <li>
                <p>
                It is easy to filter out the most relevant pieces of Contact information from complex data -
                  <span> Organized Data Management</span>
                </p>
              </li>
              <li>
                <p>
                The interface is available to use on Mobile, Web, Tablet Platforms - 
                  <span>Responsive Application Development</span>
                </p>
              </li>
              <Collapse in={width < breakpoint ? openlearning : true}>
              <div id="collapse-learning">
                
              <li>
                <p>
                Keeping track of Prospects, Contacts, and Accounts in one place - 
                  <span>Efficient and Secure Cloud Services</span>
                </p>
              </li>
              <li>
                <p>
                Provide an engaging learning Experience for the User’s Knowledge upgrade - 
                  <span>Remote Learning Management</span>
                </p>
              </li>
              <li>
                <p>
                Reduction of the Workload complexity by automating different processes- 
                  <span>Versatile Workflow Automation</span>
                </p>
              </li>

             
              </div>
              </Collapse>
            <button
              onClick={() => setOpenlearning(!openlearning)}
              aria-controls="collapse-learning"
              aria-expanded={openlearning}
              className="d-xl-none d-lg-none collapse-btn text-center ml-0 pl-0"
            >
              {openlearning ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Read More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
            </button>
            </ul>
           
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
};
