import React, { PureComponent } from "react";
import Layout from "../../components/layout";
import "react-multi-carousel/lib/styles.css";
import "../../components/section/cloudProcess/cloudprocess.css";
import "../../pages/cloudservices/cloudServices.css";
import "../digitaltranformation/digitaltransformation.css";
import "../campaignManagement/campaign.css";

import {
  EmasterStory,
  WorkflowCarousel, WorkflowNav, WorkflowInnovative, WorkflowServices, WorkflowInfo, WorkflowCapability, WorkflowExpertise, WorkflowProcess, WorkflowStory
} from "../../components/section";
import "../portalNcollaboration/portalCollaboration.css";
import "./workflowAutomation.css";

class WorkflowAutomation extends PureComponent {
  render() {
    return (
      <Layout>
        <WorkflowCarousel />
        <WorkflowNav />
        <WorkflowInnovative />
        <WorkflowServices />
        <WorkflowInfo />
        <WorkflowCapability />
        <WorkflowExpertise />
        <WorkflowStory />
        <WorkflowProcess />
      </Layout>
    );
  }
}

export default WorkflowAutomation;
