import { Col, Collapse, Container, Row } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import integration from "../../../images/digitaltrans/undraw_Business_decisions_re_84ag.svg";
import ScrollAnimation from "react-animate-on-scroll";
import bluearrow from "../../../images/bluearrow.png";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DigitalCapability = () => {
    const { width } = useViewport();
    const breakpoint = 992;
    const [openlearning, setOpenlearning] = useState(false);
  return (
    <div>
    <Container className="mcontainerpadding mobile-space-inc">
      <div id="cloudappsolutions"></div>
      <Row>
        <Col md={7} className="d-none d-lg-block"></Col>
        <Col lg={5} sm={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
              Digital Transformation{" "}
              <span className="bold">
                Capabilities
              </span>
            </h2>
          </ScrollAnimation>
          <AnimatedLine
              className="animateline"
              width={"200px"}
              margin={"-10px 0 25px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        <Row className="rowpadding portal-cap-p mobile-space-inc">
        <Col lg={7} md={12} className="textcenter">
            <ScrollAnimation
              animateIn="fade-image-right"
              duration={2}
              animateOnce={true}
            >
            <img alt=""  src={integration} className="learning-cap-img " />
            </ScrollAnimation>
          </Col>
          <Col md={12} lg={5} style={{ marginTop: "-50px" }} id="capability-list">
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <ul className="portal-capable-content">
                <li>Competitor Analysis and consultation</li>
                <li>Choosing effective Digital Technologies</li>
                <li>Producing Innovation, Growth and Value</li>
                <Collapse in={width < breakpoint ? openlearning : true}>
                  <div id="collapse-learning">
                    <li>Operation Efficiency</li>
                    <li>Hybrid Interface Experience</li>
                    <li>Cloud Development & Migration Services</li>
                    <li>Third party Integrations & Testing</li>
                    <li>Quality Assurance & Ops Services</li>
                    <li>AI Driven Workflows</li>
                    <li>Data Driven Insights</li>
                  </div>
                </Collapse>
              </ul>
              <button
                onClick={() => setOpenlearning(!openlearning)}
                aria-controls="collapse-learning"
                aria-expanded={openlearning}
                className="d-xl-none d-lg-none collapse-btn text-center ml-0 pl-0"
              >
                 {openlearning ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Read More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
              </button>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
