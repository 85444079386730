import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export const PortalInfo = () => {
  return (
    <div>
      <div
        className="portalbg learningbgInfo
"
      >
        <ScrollAnimation
          animateIn="fadeInRight"
          duration={2}
          animateOnce={true}
        >
          <p className="">
            By deploying a complete, intuitive collaboration solution, we drive
            productivity, efficiency, and engagement among employees, customers,
            and stakeholders.
          </p>
        </ScrollAnimation>
      </div>
    </div>
  );
};
