import ScrollAnimation from "react-animate-on-scroll";
import { Col, Collapse, Container, Row } from "react-bootstrap";

import plat1 from "../../../images/icons/flutter.png";
import plat2 from "../../../images/icons/native.png";

import plat3 from "../../../images/icons/html.png";

import plat4 from "../../../images/icons/css.png";

import plat5 from "../../../images/icons/react.png";

import plat6 from "../../../images/icons/4-2.png";
import plat7 from "../../../images/icons/swift.png";
import plat8 from "../../../images/icons/joomla.png";
import plat9 from "../../../images/icons/aws.png";
import plat10 from "../../../images/icons/hadoop.png";
import plat11 from "../../../images/icons/typescript.png";
import plat12 from "../../../images/icons/nodejs.png";
import plat13 from "../../../images/icons/java.png";
import plat14 from "../../../images/icons/mysql.png";


import icon1 from "../../../images/digitaltrans/devices.png";
import icon2 from "../../../images/digitaltrans/icons8-code-80.png";


import "react-multi-carousel/lib/styles.css";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const WorkflowExpertise = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [openExp, setOpenExp] = useState(false);
  const [openWeb, setOpenWeb] = useState(false);
  return (
    <Container className="mcontainerpadding " id="workflowexpertise">
      <Row>
        <Col md={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
              Our Development{" "}
              <span className="bold">
                <span className="bold">Platforms & Frameworks Expertise</span>
              </span>
            </h2>
          </ScrollAnimation>

          <AnimatedLine className="animateline" width={"200px"} margin={"-5px 0 20px 0"}>
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
        </Col>
      </Row>
      <br />
      <br/>
      {/*  */}
      <Row style={{ padding: "0", margin: "5% 0 2% 0" }}>
        <Col md={6} sm={6} xs={12} lg={6} xl={6}>
          <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
            <div className="campaignExpertise learningExpertise">
              <div className="portal-exp-icon learning-exp" style={{paddingTop:"25px"}}>
                <img src={icon1} alt="#" />
              </div>
              <Row>
                <Col>
                  <h4><span className="bold">Front-End Development</span></h4>
                  <AnimatedLine
                    className="animateline"
                    width={"150px"}
                    margin={"-3px auto"}
                  >
                    <ScrollAnimation
                      animateIn="fadeInLeft"
                      duration={2}
                      animateOnce={true}
                      className="no-animation"
                    >
                      <div className="line"></div>
                    </ScrollAnimation>
                  </AnimatedLine>
                </Col>
              </Row>

              <Row className="pt-5">
                <Col xs={6} md={3}>
                  <div className="exp-img">
                    <div>
                      <img src={plat1} alt="icon" />
                    </div>
                    <p>Flutter</p>
                  </div>
                </Col>
                <Col xs={6} md={3}>
                  <div className="exp-img">
                    <div>
                      <img src={plat2} alt="icon" />
                    </div>
                    <p>Native Script</p>
                  </div>
                </Col>
                <Col xs={6} md={3}>
                  <div className="exp-img">
                    <div>
                      <img src={plat3} alt="icon" />
                    </div>
                    <p>HTML5</p>
                  </div>
                </Col>

                <Col xs={6} md={3}>
                  <div className="exp-img">
                    <div>
                      <img src={plat4} alt="icon" />
                    </div>
                    <p>CSS3</p>
                  </div>
                </Col>
               </Row>
               <Collapse in={width < breakpoint ? openExp === "first" : true}>
                <div id="first">
               <Row>
                <Col xs={6} md={3}>
                  <div className="exp-img">
                    <div>
                      <img src={plat5} alt="icon" />
                    </div>
                    <p>React Native</p>
                  </div>
                </Col>

                <Col xs={6} md={3}>
                  <div className="exp-img">
                    <div>
                      <img src={plat6} alt="icon" />
                    </div>
                    <p>Angular</p>
                  </div>
                </Col>
                <Col xs={6} md={3}>
                  <div className="exp-img">
                    <div>
                      <img src={plat7} alt="icon" />
                    </div>
                    <p>Swift</p>
                  </div>
                </Col>
                <Col xs={6} md={3}>
                  <div className="exp-img">
                    <div>
                      <img src={plat8} alt="icon" />
                    </div>
                    <p>Joomla</p>
                  </div>
                </Col>
              </Row>
              </div>
              </Collapse>
              <button className="d-block d-md-none collapse-btn-services text-center mr-auto ml-auto pt-2"
              style={{marginBottom:"-35px"}}
              >
                {openExp === "first" ? (
                  <FontAwesomeIcon
                    icon="chevron-up"
                    onClick={() => setOpenExp(false)}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon="chevron-down"
                    onClick={() => setOpenExp("first")}
                    aria-controls="first"
                    aria-expanded="first"
                  />
                )}
              </button>
            </div>
          </ScrollAnimation>
        </Col>

        <Col md={6} sm={6} xs={12} lg={6} xl={6} className="wf-exp">
          <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
            <div className="campaignExpertise learningExpertise">
              <div className="portal-exp-icon learning-exp" style={{paddingTop:"25px"}}>
                <img src={icon2} alt="#" />
              </div>
              <Row>
                <Col>
                  <h4><span className="bold">Back-End Development</span></h4>
                  <AnimatedLine
                    className="animateline"
                    width={"150px"}
                    margin={"-3px auto"}
                  >
                    <ScrollAnimation
                      animateIn="fadeInLeft"
                      duration={2}
                      animateOnce={true}
                      className="no-animation"
                    >
                      <div className="line"></div>
                    </ScrollAnimation>
                  </AnimatedLine>
                </Col>
              </Row>

              <Row className="pt-5">
                <Col xs={6} md={3}>
                  <div className="exp-img">
                    <div>
                      <img src={plat9} alt="icon" />
                    </div>
                    <p>Amazon Web Services</p>
                  </div>
                </Col>
                <Col xs={6} md={3}>
                  <div className="exp-img">
                    <div>
                      <img src={plat10} alt="icon" />
                    </div>
                    <p>Hadoop</p>
                  </div>
                </Col>
                <Col xs={6} md={3}>
                  <div className="exp-img">
                    <div>
                      <img src={plat11} alt="icon" />
                    </div>
                    <p>Typescript</p>
                  </div>
                </Col>

                <Col xs={6} md={3}>
                  <div className="exp-img">
                    <div>
                      <img src={plat12} alt="icon" />
                    </div>
                    <p>NodeJS</p>
                  </div>
                </Col>
                </Row>
                <Collapse in={width < breakpoint ? openExp === "second" : true}>
                <div id="second">
                <Row>
                <Col xs={6} md={6}>
                  <div className="exp-img">
                    <div>
                      <img src={plat13} alt="icon" />
                    </div>
                    <p>Java</p>
                  </div>
                </Col>

                <Col xs={6} md={6}>
                  <div className="exp-img">
                    <div>
                      <img src={plat14} alt="icon" />
                    </div>
                    <p>mySQL</p>
                  </div>
                </Col>
              </Row>
              </div>
              </Collapse>
              <button className="d-block d-md-none collapse-btn-services text-center mr-auto ml-auto pt-2"
               style={{marginBottom:"-33px"}}
              >
                {openExp === "second" ? (
                  <FontAwesomeIcon
                    icon="chevron-up"
                    onClick={() => setOpenExp(false)}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon="chevron-down"
                    onClick={() => setOpenExp("second")}
                    aria-controls="second"
                    aria-expanded="second"
                  />
                )}
              </button>
            </div>
          </ScrollAnimation>
        </Col>
      </Row>
    </Container>
  );
};
