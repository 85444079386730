import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import rightcircle from "../../../images/rightcircle.png";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import styled from "styled-components";
import { useViewport } from "../../common/designParts/useViewport";
import learning_pro from "../../../images/learningmanage/learning-process.png";

export const LearningProcess = () => {
  const { width } = useViewport();
  const breakpoint = 992;
  const [click, setClick] = useState("assess_needs");
  const ProcessButton = styled.div`
  background:#7B7D7E;
  color:#fff;
  height:100px;
  width:100px
  text-align:center;
  padding:30px 15px 10px 15px;
  position: absolute;
  border-radius:50%;
  font-size:12px;
  cursor:pointer;
 
  top:${(props) => props.top};
  left:${(props) => props.left};
`;

  return (
    <div>
      {" "}
      <div id="learningappsolutions"></div>
      <Container className="mcontainerpadding mb-5">
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                Our LMS <span className="bold"> Process Steps</span>
              </h2>
            </ScrollAnimation>
            <AnimatedLine
              className="animateline"
              width={"150px"}
              margin={"-5px 0 35px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
       
        {width < breakpoint ? (
          <Row>
              <Col md={12}>
                  <img src={learning_pro} alt="" className="learning-process-img mt-1 mr-auto ml-auto"/>
              </Col>
          </Row>
        ) : (
          <Row className="mt-4 mb-4">
            <Col md={8} lg={9} sm={12} xs={12}>
              <div className="process_circle">
                <ProcessButton
                  top={"0"}
                  left={"0"}
                  className={`learning-pro-btn ${click === "assess_needs" ? "activeLearning" : ""}`}
                  onClick={() => setClick("assess_needs")}
                >
                  Assess
                  <br /> Needs
                </ProcessButton>

                <ProcessButton
                  top={"-4em"}
                  left={"13em"}

                  className={`learning-pro-btn-2 ${click === "design_spec" ? "activeLearning" : ""}`}
                  onClick={() => setClick("design_spec")}
                >
                  Design <br /> Specifications
                </ProcessButton>
                <ProcessButton
                  top={"8em"}
                  left={"25em"}
                  className={`learning-pro-btn-3 pt-3 ${click === "test_software" ? "activeLearning" : ""}`}
                  onClick={() => setClick("test_software")}
                >
                  Design/
                  <br />
                  Develop/
                  <br />
                  Test Software
                </ProcessButton>
                <ProcessButton
                  top={"20em"}
                  left={"20em"}
                  className={`learning-pro-btn-4 ${click === "implement_system" ? "activeLearning" : ""}`}
                  onClick={() => setClick("implement_system")}
                >
                  Implement <br />
                  Systems
                </ProcessButton>
                <ProcessButton
                  top={"23em"}
                  left={"5em"}
                  className={`learning-pro-btn-5 ${click === "support_oper" ? "activeLearning" : ""}`}
                  onClick={() => setClick("support_oper")}
                >
                  Support <br />
                  Operations
                </ProcessButton>
                <ProcessButton
                  top={"14em"}
                  left={"-4em"}
                  className={`learning-pro-btn-6 ${click === "evaluate" ? "activeLearning" : ""}`}
                  onClick={() => setClick("evaluate")}
                >
                  Evaluate <br />
                  Performance
                </ProcessButton>
              </div>
            </Col>

            <Col
              md={4}
              lg={3}
              className="initiancolumn mobile-pro-card d-sm-none d-md-block d-xs-none"
            >
              <div
                className="card initiancard"
                style={
                  click === "assess_needs"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                        <span className="bold">01</span>
                      </span>
                      <span className="inip">Assess Needs</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                      Having an in-depth understanding of your training needs.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                      Draw a clear picture about your learners, the learning
                      content and the performance objectives.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                      Get the Client’s feedback and sharing experience.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* 2 */}
              <div
                className="card initiancard"
                style={
                  click === "design_spec"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                        <span className="bold">02</span>
                      </span>
                      <span className="inip">Design Specifications</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                      Analysis of your objectives and create customized learning
                      solutions that better meet your needs.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">Plan Detailed design document.</p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">Estimate the project timelines.</p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                      Explore the Price options in a formal proposal.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* 3 */}
              <div
                className="card initiancard"
                style={
                  click === "test_software"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                        <span className="bold">03</span>
                      </span>
                      <span className="inip">Design the LMS Software</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                      Proof of concept development phase.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                      Sketch and design of a suitable solution to give you an
                      idea of the end product.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                      After initial approval, storyboarding begins.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                      Iteration of the design decision until goal is met.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* 4 */}
              <div
                className="card initiancard"
                style={
                  click === "implement_system"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                        <span className="bold">04</span>
                      </span>
                      <span className="inip">Implement Systems</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                      Bringing the storyboard and designs to life.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                      The developed software sent for usability review and
                      identification of functionality issues.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                      Client review and successive iterations based on feedback.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* 5 */}
              <div
                className="card initiancard"
                style={
                  click === "support_oper"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                        <span className="bold">05</span>
                      </span>
                      <span className="inip">Support Operations</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                      LMS Software is approved and signed off as a pre-final
                      step.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                      Work closely with the client to deploy the developed
                      eLearning module to the learners.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* 6 */}
              <div
                className="card initiancard"
                style={
                  click === "evaluate"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                        <span className="bold">06</span>
                      </span>
                      <span className="inip">Evaluate Performance</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">Customer insight analysis.</p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                      Evaluation of the software performance.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                      Customer feedback and sharing experience.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};
