import React, { PureComponent } from "react";
import Layout from "../../components/layout";
import "react-multi-carousel/lib/styles.css";
import "../../components/section/cloudProcess/cloudprocess.css";
import "../../pages/cloudservices/cloudServices.css";
import "../../pages/digitaltranformation/digitaltransformation.css";
import {
 QaCarousel, QaNav, QaDigital, QaServices, QaInfo, QaCapability, QaExpertise, QaMasters, QaProcess
} from "../../components/section";
import "./qatesting.css";

class QaTesting extends PureComponent {
  render() {
    return (
      <Layout>
        <QaCarousel />
        <QaNav />
        <QaDigital />
        <QaServices />
        <QaInfo />
        <QaCapability />
        <QaExpertise />
        <QaMasters />
        <QaProcess />
      </Layout>
    );
  }
}

export default QaTesting;
