import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Image, Row } from "react-bootstrap";
import "./style.scss";

export const InfoBarSection = () => {
  return (
    <div className="infobar-container">
      <Container fluid>
        <Container>
            <Row >
          <Col sm={6} md={6} lg={6} xl={3} xs={6} className="p-0">
            <Row >
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <ScrollAnimation
                  animateIn="fadeInRight"
                  duration={2}
                  animateOnce={true}
                >
                  <div className="icon-container">
                    <Image src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/clients.webp" className="" />
                  </div>
                </ScrollAnimation>
              </Col>
              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <p className="clientheading">
                  <span className="bold">12</span>
                </p>
                <h4 className="clientsubheading m-0 p-0">Happy Clients</h4>
              </Col>
            </Row>
          </Col>
          <Col sm={6} md={6} lg={6} xl={3} xs={6} className="p-0">
            <Row>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <ScrollAnimation
                  animateIn="fadeInRight"
                  duration={2}
                  animateOnce={true}
                >
                  <div className="icon-container">
                    <Image src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/resources.webp" className="" />
                  </div>
                </ScrollAnimation>
              </Col>
              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <p className="clientheading">
                  <span className="bold">50</span>
                </p>
                <h4 className="clientsubheading">Certified Resources</h4>
              </Col>
            </Row>
          </Col>
          <Col sm={6} md={6} lg={6} xl={3} xs={6} className="p-0">
            <Row>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <ScrollAnimation
                  animateIn="fadeInRight"
                  duration={2}
                  animateOnce={true}
                >
                  <div className="icon-container">
                    <Image src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/project.webp" className="icon2" />
                  </div>
                </ScrollAnimation>
              </Col>
              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <p className="clientheading">
                  <span className="bold">12</span>
                </p>
                <h4 className="clientsubheading">Done Projects</h4>
              </Col>
            </Row>
          </Col>
          <Col sm={6} md={6} lg={6} xl={3} xs={6} className="p-0">
            <Row>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <ScrollAnimation
                  animateIn="fadeInRight"
                  duration={2}
                  animateOnce={true}
                >
                  <div className="icon-container">
                    <Image src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/exp.webp" className="icon2" />
                  </div>
                </ScrollAnimation>
              </Col>
              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <p className="clientheading">
                  <span className="bold">08</span>
                </p>
                <h4 className="clientsubheading">Years of experience</h4>
              </Col>
            </Row>
          </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};
