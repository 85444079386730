import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import linebg from "../../../images/uiux-deliver/custom.png";
import { useViewport } from "../../common/designParts/useViewport";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import web_process from "../../../images/uiux-deliver/uiProcess.png";
export const UiUxProcess = () => {
  const { width } = useViewport();
  const breakpoint = 992;
  return (
    <>
    <div id="uiuxprocess"></div>
      <Container className="mcontainerpadding">
          
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                Our UI/UX
                <span className="bold"> Process Steps</span>
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"150px"}
              margin={"-5px 0 0px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
      </Container>
      <Container>
        {width < breakpoint ? (
          <Row>
            <Col md={12} className="mb-3">
              <img src={web_process} alt="" style={{ width: "100%" }} />
            </Col>
          </Row>
        ) : (
          <div>
            <Row className="uiProcessbtn">
              <Col md={4}>
                <div className="ProcessContent">
                  <Row className="mb-2">
                    <h3>01</h3>
                    <h5>Discovery</h5>
                  </Row>
                  <Row>
                    <ul>
                      <li>Understand the requirements.</li>
                      <li>Create User personas.</li>
                      <li>Define the Use cases.</li>
                    </ul>
                  </Row>
                </div>
                <button>Discovery</button>
                <div className="uiLine"></div>
              </Col>
              <Col md={4}>
                <div className="ProcessContent">
                  <Row className="mb-2">
                    <h3>02</h3>
                    <h5>Research</h5>
                  </Row>
                  <Row>
                    <ul>
                      <li>Analyze the requirements.</li>
                      <li>Analyze Painpoints and scenarios.</li>
                      <li>Research the users.</li>
                    </ul>
                  </Row>
                </div>
                <button>Research</button>
                <div className="uiLine2"></div>
              </Col>
              <Col md={4}>
                <div className="ProcessContent">
                  <Row className="mb-2">
                    <h3>03</h3>
                    <h5>Sketch</h5>
                  </Row>
                  <Row>
                    <ul>
                      <li>Gather the ideas.</li>
                      <li>Draw sketches and wireframes.</li>
                      <li>Evaluate and re-draw.</li>
                    </ul>
                  </Row>
                </div>
                <button style={{ marginLeft: "22%" }}>Sketch</button>
                <div className="uiLine3"></div>
              </Col>
            </Row>
            <Row className="uiProcessBg">
              <img src={linebg} alt="img" />
            </Row>
            <Row className="uiProcessbtn">
              <Col md={4}>
                <div className="uiLine-btm"></div>
                <button>Evaluate</button>
                <div className="ProcessContent">
                  <Row className="mb-2">
                    <h3>04</h3>
                    <h5>Evaluate</h5>
                  </Row>
                  <Row>
                    <ul>
                      <li>Gather the ideas.</li>
                      <li>Draw sketches and wireframes.</li>
                      <li>Evaluate and re-draw.</li>
                    </ul>
                  </Row>
                </div>
              </Col>
              <Col md={4}>
                <div className="uiLine-btm2"></div>
                <button>Implement</button>
                <div className="ProcessContent">
                  <Row className="mb-2">
                    <h3>05</h3>
                    <h5>Implement</h5>
                  </Row>
                  <Row>
                    <ul>
                      <li>Implement the functionality.</li>
                      <li>Build the Experience.</li>
                      <li>Build the User journey.</li>
                    </ul>
                  </Row>
                </div>
              </Col>
              <Col md={4}>
                <div className="uiLine-btm3"></div>
                <button>Design</button>
                <div className="ProcessContent">
                  <Row className="mb-2">
                    <h3>06</h3>
                    <h5>Design</h5>
                  </Row>
                  <Row>
                    <ul>
                      <li>Perform the Usability Testing.</li>
                      <li>Create audit reports.</li>
                      <li>Identify Improvements.</li>
                    </ul>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </>
  );
};
