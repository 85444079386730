import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import image1 from "../../../images/digitaltrans/digitaltrans/dt-1.webp";
import image2 from "../../../images/digitaltrans/digitaltrans/dt-2.webp";
import image3 from "../../../images/digitaltrans/digitaltrans/dt-3.webp";
import image4 from "../../../images/digitaltrans/digitaltrans/dt-4.webp";
import arrowimg from "../../../images/arrow.svg";
import { useViewport } from "../../common/designParts/useViewport";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import imagemob1 from "../../../images/digitaltrans/digitaltrans/digi1.webp";
import imagemob2 from "../../../images/digitaltrans/digitaltrans/digi2.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const InnovativeDigital = () => {
  const { width } = useViewport();
  const breakpoint = 800;
  const [open, setOpen] = useState(false);
  return (
    <Container className="mcontainerpaddingnew learningmanage ">
      <Row>
        <Col lg={7} md={12} sm={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h3 className="subheader">
              Harness the power of technology to create impact
             </h3>
             <h2 className="h2heading1">
              <span className="bold">
                Re-define the future of your business
              </span>
            </h2>
          </ScrollAnimation>
          <AnimatedLine
            className="animateline"
            width={"250px"}
            margin={"-5px 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
          <p className="mparagraphheadingtop">
            To deliver Digital Transformation solutions, we analyze and
            understand how to bring about change in terms of your organization’s
            approach and culture.
            <span
              className={`d-lg-inline d-xl-inline ${
                open ? "d-inline" : "d-none"
              }`}
            >
              &nbsp;Our team believes in starting small and strategically progressing
              to a larger vision and helping choose technology solutions that
              meet your long-term goals.
            </span>
          </p>
          <span
            className={`d-lg-inline d-xl-inline ${
              open ? "d-inline" : "d-none"
            }`}
          >
            
            <ul className="homelist">
              <li>
                <p>
                  Develop a holistic approach for physical to digital
                  transformation.
                </p>
              </li>
              <li>
                <p>
                  Deliver personalized, intelligent, and Cloud-driven
                  experiences.
                </p>
              </li>

              <li>
                <p>
                  Accomplish business excellence by adopting cutting-edge
                  technologies.
                </p>
              </li>
              <li>
                <p>Reduce costs and risks with informed decision-making.</p>
              </li>
              <li>
                <p>
                  Scale your system quickly with changing business landscapes.
                </p>
              </li>
              <li>
                <p>
                  Accelerate the development cycle of business processes by
                  enabling innovation.
                </p>
              </li>
            </ul>
          </span>
          <button
            className="readmore_btn d-lg-none d-xl-none pl-0"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              ""
            ) : (
              <>
                <span className="expand readmore">
                  Read More <FontAwesomeIcon icon="angle-double-right" />
                </span>
              </>
            )}
          </button>
          <div className="d-lg-none d-xl-none d-md-none learning-mid-img">
            <img alt="" src={imagemob1} className="mgridimg1" />
          </div>
          <p className="mparagraphheading">
            Our highly experienced cross-functional team will guide you through
            technical and functional feasibilities of the project to elaborate
            and validate digital transformation consulting.
            <span className={` d-xl-inline d-none `}>
              {" "}
              By leveraging the potential of Data Analytics, Automation, Cloud,
              AI, we assure that your business is taken to the next level. Our
              team conducts analysis, diagnosis, and optimization of your IT
              landscape in order to ensure it is aligned with your vision. We
              apply an innovative, scalable, secure and technology-agnostic
              approach to enable our clients to remodel their existing structure
              and reap lasting business benefits with our solutions.
            </span>
          </p>
          <div className="d-lg-none d-xl-none d-md-none learning-mid-img mt-4">
            <img alt="" src={imagemob2} className="mgridimg1" />
          </div>

          <br />
        </Col>
        <Col lg={5} md={12} sm={12} className="d-none d-md-block mt-2">
          <Row>
            <Col md={6} className="columntop">
              <div>
                <img alt="" src={image1} className="mgridimg1" />
              </div>
              <div>
                <img alt="" src={image2} className="mgridimg2" />
              </div>
            </Col>
            <Col md={6} className="mt-5">
              <br />
              <div>
                <img alt="" src={image3} className="mgridimg1" />
              </div>
              <br />

              <div>
                <img alt="" src={image4} className="mgridimg1" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
