import React from "react";
import { Container, Row, Col, Image, Collapse } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import dev1 from "../../../images/icons/knowledge (1).png";
import dev2 from "../../../images/icons/elearning.png";
import dev3 from "../../../images/icons/it-modern.png";
import dev4 from "../../../images/icons/online-course.png";
import dev5 from "../../../images/icons/Outline.png";
import dev6 from "../../../images/icons/knowledge.png";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ServiceCard from "../../common/designParts/serviceCardComponent";

export const LearningServices = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [open, setOpen] = useState(false);
  const [openservice, setOpenservice] = useState(false);
  return (
    <div>
      <div id="learningservice"></div>
      <div className="mbg-img">
        <Container
          className="mcontainerpadding "
          style={{ background: "none" }}
        >
          <Row>
            <Col md={12}>
              <ScrollAnimation
                animateIn="animate-text"
                duration={5}
                animateOnce={true}
              >
                <h2 className="h2heading1">Our <span className="bold">Learning Management Services</span></h2>
              </ScrollAnimation>

              <AnimatedLine
                className="animateline"
                width={"200px"}
                margin={"-10px 0"}
                
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                  className="no-animation"
                >
                  <div className="line"></div>
                </ScrollAnimation>
              </AnimatedLine>
            </Col>
          </Row>
          <br />

          <Row>
            <Col md={6} lg={4}>
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div className="card digicard">
                  <div className="portal-service-img">
                    <Image src={dev1} className="serveimg" />
                  </div>
                  <p className="devheading"><span className="bold">Facile Learning Management</span></p>
                  <p className="devparagraph">
                    Manage courses and activities seamlessly, adding new courses
                    in multiple formats, customizing learning paths, and
                    handling certifications for the L&D experts.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={6} lg={4}>
              <ServiceCard
                id="collapse-services-dtl"
                heading="Modern LMS Development"
                image={dev2}
                content="It is the ideal replacement for an outdated LMS that
              does not fully cover your business requirements. We can
              safely transition your content from a legacy LMS to a
              new, secure one quickly and securely."
              />
            </Col>
            <Col md={6} lg={4}>
              <ServiceCard
                id="collapse-service-dtl2"
                heading="Custom LMS integrations"
                image={dev3}
                content="If you would like to use a single interface for your LMS
                alongside other platforms you may already have at your
                disposal. The LMS can be integrated with any software."
              />
            </Col>

            <Collapse
              in={width < breakpoint ? open : true}
              className="collapse-div"
            >
              <div id="collapse-services-learning">
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl3"
                    heading="Virtual Classroom Development & Integration"
                    image={dev4}
                    content=" Customizing and populating open source conferencing
                systems, or developing virtual classrooms for live
                online classes."
                  />
                </Col>
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl4"
                    heading="Content Conversion & Management"
                    image={dev5}
                    content="In addition to video and document conversions
                (PowerPoint, PDF, Doc), we also provide
                instructional design services to help you maximize
                your learning experience."
                  />
                </Col>
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl5"
                    heading="Bespoke LMS Development"
                    image={dev6}
                    content="A learning management system built from scratch that
                meets all your short-term and long-term goals and
                excels on its user interface and user experience."
                  />
                </Col>
              </div>
            </Collapse>
            <button
              onClick={() => setOpen(!open)}
              aria-controls="collapse-services-learning"
              aria-expanded={open}
              className="d-xl-none d-lg-none d-md-none collapse-btn text-center mr-auto ml-auto pt-2"
            >
              {open ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Read More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
            </button>
          </Row>
        </Container>
      </div>
    </div>
  );
};
