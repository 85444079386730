import React from "react";
import { Container, Row, Col, Image, Collapse } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import dev1 from "../../../images/qatesting/automation(1).png";
import dev2 from "../../../images/qatesting/manual.svg";
import dev3 from "../../../images/qatesting/web.png";
import dev4 from "../../../images/qatesting/mobile(1).png";
import dev5 from "../../../images/qatesting/api.png";
import dev6 from "../../../images/qatesting/security.png";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ServiceCard from "../../common/designParts/serviceCardComponent";

export const QaServices = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [open, setOpen] = useState(false);
  const [openservice, setOpenservice] = useState(false);
  return (
    <div>
      <div id="services"></div>
      <div className="mbg-img">
        <Container
          className="mcontainerpadding "
          style={{ background: "none" }}
        >
          <Row>
            <Col md={12}>
              <ScrollAnimation
                animateIn="animate-text"
                duration={5}
                animateOnce={true}
              >
                <h2 className="h2heading1">Our QA Testing <span className="bold">Services</span></h2>
              </ScrollAnimation>

              <AnimatedLine
                className="animateline"
                width={"200px"}
                margin={"-5px 0 0 0"}
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                  className="no-animation"
                >
                  <div className="line"></div>
                </ScrollAnimation>
              </AnimatedLine>
            </Col>
          </Row>
          <br />

          <Row>
            <Col md={6} lg={4}>
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div className="card digicard">
                  <div className="portal-service-img">
                    <Image src={dev1} className="" />
                  </div>
                  <p className="devheading"><span className="bold">Automation Testing</span></p>
                  <p className="devparagraph">
                    Our company provides automated testing services for a wide
                    range of applications. Automated tests will help you create
                    better websites, applications, or software with less effort
                    and in less time.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={6} lg={4}>
              <ServiceCard
                id="collapse-services-dtl"
                heading="Manual Testing"
                image={dev2}
                content="By manually testing your product, We can help you identify vulnerabilities that cannot be detected automatically and get feedback about its look and feel."
              />
            </Col>
            <Col md={6} lg={4}>
              <ServiceCard
                id="collapse-service-dtl2"
                heading="Functional Testing"
                image={dev3}
                content="Tests are conducted at various levels of granularity to ensure complete compliance with the functional requirements of the software.

                ."
              />
            </Col>

            <Collapse
              in={width < breakpoint ? open : true}
              className="collapse-div"
            >
              <div id="collapse-services-learning">
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl3"
                    heading="Usability Testing"
                    image={dev4}
                    content="Testing for usability involves ensuring that solution elements are logically arranged, content is conveniently laid out, and usage steps are as few as possible."
                  />
                </Col>
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl4"
                    heading="Performance Testing"
                    image={dev5}
                    content="Tests are conducted to determine the performance of the system components in a specific environment. This testing validates the resource usage, response time, scalability, and reliability of the product."
                  />
                </Col>
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl5"
                    heading="Security Testing"
                    image={dev6}
                    content="By identifying all possible security risks in a system, we perform risk assessment and penetration testing to block hackers or malicious threats.
                    "
                  />
                </Col>
              </div>
            </Collapse>
            <button
              onClick={() => setOpen(!open)}
              aria-controls="collapse-services-learning"
              aria-expanded={open}
              className="d-xl-none d-lg-none d-md-none collapse-btn text-center mr-auto ml-auto pt-2"
            >
             {open ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Expand More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
            </button>
          </Row>
        </Container>
      </div>
    </div>
  );
};
