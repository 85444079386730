import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { BrowserView, MobileView } from "react-device-detect";
import rightcircle from "../../../images/rightcircle.png";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import web_process from "../../../images/cloud/process.png";

export const CloudProcess = () => {
  const [select, setselect] = useState("advisory");
  const { width } = useViewport();
  const breakpoint = 992;
  const [webProcess, setWebprocess] = useState("research");
  return (
    <div style={{ background: "#fff" }}>
      <div id="cloudprocessstep"></div>
      <Container className="mcontainerpadding" style={{ background: "#fff" }}>
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                Our Cloud Services <span className="bold">Process Steps</span>
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"200px"}
              margin={"-5px 0 -pxpx 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        {width < breakpoint ? (
          <Row>
            <Col md={12}>
              <img src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/process.png" alt="" style={{ width: "100%" }} />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md={7}>
              <BrowserView>
                <div className="circle">
                  <Container fluid>
                    <Row>
                      <Col md={12} className="textcenter">
                        <div className="migrationservice">
                          <p className="greyp">02</p>
                          <div
                            className={`processtepscontainer`}
                            id="migration"
                            onClick={() => {
                              setselect("migration");
                    
                            }}
                            style={select === "migration" ? {background:"#2579d8",fontWeight:"bold"}: {background:"grey"}}
                          >
                            <p className="pt-3">Migration Services</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="cloudprocessrow">
                      <Col md={6} className="textcenter">
                        <p className="greyp1">01</p>
                        <div className={`advisoryservice`}>
                          <div
                            className="processtepscontainer"
                            id="advisory"
                            onClick={() => {
                             setselect("advisory")
                            }}
                            style={select === "advisory" ? {background:"#2579d8",fontWeight:"bold"}: {background:"grey"}}
                          >
                            <p>
                              Advisory
                              <br /> Services
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md={6} className="textcenter">
                        <p className="greyp3">03</p>
                        <div class="managementservice">
                          <div className="processtepscontainer"
                            style={select === "management" ? {background:"#2579d8",fontWeight:"bold"}: {background:"grey"}}
                          
                          >
                            <p
                              id="management"
                              onClick={() => {
                                setselect("management")
                              }}
                              className="pt-3"
                            >
                              Management Services
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </BrowserView>
            
            </Col>
            <Col md={5}>
              <div
                className="card cloudprocesscard"
                id="advisoryservice"
                style={select === "advisory" ? {display:"block"}: {display:"none"}}
                
              >
                <Row>
                  <Col md={12}>
                    <p>
                      <span className="inih">
                        <span className="bold">01</span>
                      </span>
                      <span className="inip">Advisory Services</span>
                    </p>
                  </Col>
                </Row>
                <br />
               
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Framework for public, private, and hybrid cloud
                      approaches, cloud types (IaaS, PaaS, and SaaS).
                    </p>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Building Reference architectures, security policy and
                      Cloud Management platform.
                    </p>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">Cloud Strategy and Roadmap.</p>
                  </Col>
                </Row>
              </div>
              <div id="migrationservice" 
                style={select === "migration" ? {display:"block"}: {display:"none"}}
              
              >
                <div className="card cloudprocesscard">
                  <Row>
                    <Col md={12}>
                      <p>
                        <span className="inih">
                          <span className="bold">02</span>
                        </span>
                        <span className="inip">Migration Services</span>
                      </p>
                    </Col>
                  </Row>
                  <br />
               
                  <Row>
                    <Col md={2}>
                      <img
                        alt=""
                        src={rightcircle}
                        className="rightcircleimg"
                      />
                    </Col>
                    <Col md={10}>
                      <p className="inicontent">
                        Implementation services for Workload Automation.
                      </p>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={2}>
                      <img
                        alt=""
                        src={rightcircle}
                        className="rightcircleimg"
                      />
                    </Col>
                    <Col md={10}>
                      <p className="inicontent">
                        Migration services for an easy onboard from another
                        automation solution.
                      </p>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={2}>
                      <img
                        alt=""
                        src={rightcircle}
                        className="rightcircleimg"
                      />
                    </Col>
                    <Col md={10}>
                      <p className="inicontent">
                        App development for specific integrations with third
                        party applications.
                      </p>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={2}>
                      <img
                        alt=""
                        src={rightcircle}
                        className="rightcircleimg"
                      />
                    </Col>
                    <Col md={10}>
                      <p className="inicontent">
                        Health Check Assessment and Optimization Services.
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
              <div id="managementservice" 
                style={select === "management" ? {display:"block"}: {display:"none"}}
              
              >
                <div className="card cloudprocesscard">
                  <Row>
                    <Col md={12}>
                      <p>
                        <span className="inih">
                          <span className="bold">03</span>
                        </span>
                        <span className="inip">Management Services</span>
                      </p>
                    </Col>
                  </Row>
                  <br />
                 
                  <Row>
                    <Col md={2}>
                      <img
                        alt=""
                        src={rightcircle}
                        className="rightcircleimg"
                      />
                    </Col>
                    <Col md={10}>
                      <p className="inicontent">
                        Leverage new and existing IT investments while
                        minimizing risk and cost.
                      </p>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={2}>
                      <img
                        alt=""
                        src={rightcircle}
                        className="rightcircleimg"
                      />
                    </Col>
                    <Col md={10}>
                      <p className="inicontent">
                        Ensure cloud governance, compliance and visibility
                        across your IT environment with a digital self-service
                        model.
                      </p>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={2}>
                      <img
                        alt=""
                        src={rightcircle}
                        className="rightcircleimg"
                      />
                    </Col>
                    <Col md={10}>
                      <p className="inicontent">
                        Improve infrastructure availability and uptime with
                        automation and self-service capabilities.
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};
