import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "react-bootstrap";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import logo1 from "../../../images/xtime/Tableau_Logo.png";
import logo2 from "../../../images/xtime/download.png";
import logo3 from "../../../images/xtime/aws.png";
import logo4 from "../../../images/xtime/java.png";

export const XtimeTechnology = () => {
  return (
    <Container className="xtimeAbout pt-3 pb-2 ">
      <Row>
        <Col md={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1 ">
              Our <span className="bold">Technology Stack</span>
            </h2>
          </ScrollAnimation>

          <AnimatedLine className="animateline" width={"100px"} margin={"-10px 0"}>
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
        </Col>
      </Row>
      <Row className="pt-5">
        <Col md={6}>
          <div className="xtime-technology">
            <h4>Business Intelligence Tools</h4>
            <AnimatedLine
              className="animateline"
              width={"100px"}
              margin={"0 auto"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>

            <Row className="xtime-tech-logo">
              <Col xs={6}>
                <div>
                  <img src={logo1} alt="" />
                </div>
                <p>Tableau</p>
              </Col>
              <Col xs={6}>
                <div>
                  <img src={logo2} alt="" />
                </div>
                <p>Micro Strategy</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col md={6}>
          <div className="xtime-technology">
            <h4>Backend Implementation </h4>
            <AnimatedLine
              className="animateline"
              width={"100px"}
              margin={"0 auto"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>

            <Row className="xtime-tech-logo">
              <Col xs={6}>
                <div>
                  <img src={logo3} alt="" />
                </div>
                <p>Amazon Web Services</p>
              </Col>
              <Col xs={6}>
                <div>
                  <img src={logo4} alt="" />
                </div>
                <p>Java</p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
