import { Container } from "react-bootstrap";

export const ProjectEmasterCarousel = () => {
  return (
    <div className="hero-styles emasterhero">
     <Container>
        <p className="mwhiteparagraph" style={{ overflowX: "hidden" }}>
        <h1 className="cloudcaptionheading">eMASTERS</h1>

          <h4>
          A lead generation project  for internal Sales Team Collaboration and Marketing Management  
          </h4>
        </p>
      </Container>
    </div>
  );
};
