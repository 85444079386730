import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../designParts/animatedUnderline";
import linkedin from "../../../images/icons/linkedin.png";
import { Link } from "react-router-dom";
export const Footer = () => {
  return (
    <div>
      <Container fluid className="footercontainer">
        <div className="footer-cont">
          <Row>
            <Col md={4} sm={4} xs={12}>
              <h2 className="footerheading mt-3">
                <span className="bold">Industries</span>
              </h2>

              <AnimatedLine
                className="animateline "
                width={"50px"}
                margin={"0 0"}
                id="footeranimate"
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                  className="no-animation"
                >
                  <div className="line"></div>
                </ScrollAnimation>
              </AnimatedLine>

              <p className="footerparagraph">Retail Industry</p>
              <p className="footerparagraph">Healthcare Industry</p>
              <p className="footerparagraph">Travel Industry</p>
              <p className="footerparagraph">Automotive Industry</p>
              <p className="footerparagraph">Banking & Finance Industry</p>
            </Col>
            <Col md={4} sm={4} xs={12}>
              <h2 className="footerheading mt-3 ">
                <span className="bold">Core Services</span>
              </h2>
              <AnimatedLine
                className="animateline"
                width={"50px"}
                margin={"0 0"}
                id="footeranimate"
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                  className="no-animation"
                >
                  <div className="line"></div>
                </ScrollAnimation>
              </AnimatedLine>

              <p
                className="footerparagraph"
                onClick={() => (window.location = "/web-development")}
              >
                Web Development
              </p>
              <p
                className="footerparagraph"
                onClick={() => (window.location = "/mobile-development")}
              >
                Mobile Development
              </p>
              <p
                className="footerparagraph"
                onClick={() => (window.location = "/cloud-services")}
              >
                Cloud Services
              </p>
              <p className="footerparagraph" onClick={() => (window.location = "/workflow-automation")}>Workflow Automation</p>
              <p
                className="footerparagraph"
                onClick={() => (window.location = "/ui-ux-services")}
              >
                UI/UX Solutions
              </p>
            </Col>
            <Col md={4} sm={4} xs={12}>
              <h2 className="footerheading social mt-3">
                <span className="bold">Connect on Social</span>
              </h2>
              <AnimatedLine
                className="animateline "
                width={"50px"}
                margin={"0px 0"}
                id="footeranimate"
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                  className="no-animation"
                >
                  <div className="line"></div>
                </ScrollAnimation>
              </AnimatedLine>

              <a href="https://www.linkedin.com/in/jmtllc/" target="_blank">
                <p className="footerparagraph">
                  <img src={linkedin} alt="linkedin" className="social-icon" />{" "}
                  LinkedIn
                </p>
              </a>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid className="footerbottom">
        <p>Copyright © 2021 JMT Worldwide LLC All rights reserved</p>
      </Container>
    </div>
  );
};
