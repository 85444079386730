import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "react-bootstrap";
import { useViewport } from "../../common/designParts/useViewport";
import image3 from "../../../images/emasters/Untitled 2.png";
import emasterlogo from "../../../images/emasters/emasters.png";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const EmastersAbout = () => {
  const { width } = useViewport();
  const breakpoint = 769;
  const [open, setOpen] = useState(false);
  return (
    <Container className="mcontainerpaddingnew learningmanage">
      <Row className="mt-3">
        <Col lg={7} md={12} sm={12} className="mb-5">
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
              In-bound<span className="bold"> Customer Relationship Management (CRM)
              </span>{" "}
              <br /> Application for Lead Generation
            </h2>
          </ScrollAnimation>

          <AnimatedLine
            className="animateline"
            width={"150px"}
            margin={"-5px 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>

          <p className="mparagraphheadingtop">
            <img src={emasterlogo} alt="emasters" className="emasterslogo" />
            eMASTERS platform includes an amalgamation of tools and capabilities
            you need to execute marketing, sales, campaigns, and targets. This
            Cloud-based Hybrid platform application boasts of powerful
            individual functionalities, but it comes alive when paired
            interdependently.
          </p>
          <span
            className={`d-lg-inline d-xl-inline ${
              open ? "d-inline" : "d-none"
            }`}
          >
            <p className="mparagraphheading">
              By using an efficient CRM tool, eMASTERS has helped the Marketing
              team increase sales, improve productivity, and better serve their
              customers. By reducing operational costs and increasing
              time-to-market, the users can manage and assign requests made by
              prospects - while reducing operational costs.
            </p>

          </span>
          <button
            className="readmore_btn d-lg-none d-xl-none mt-3 pl-0"
            onClick={() => setOpen(!open)}
          >
           {open ? (
              ""
            ) : (
              <>
                <span className="expand readmore">
                  Read More <FontAwesomeIcon icon="angle-double-right" />
                </span>
              </>
            )}
          </button>
        </Col>
        <Col lg={5} md={12} sm={12} className="mt-5 ">
          <Row>
            <Col>
              <div className="emaster-about-img m-auto">
                <img src={image3} className="mgridimg1" alt="" />
              </div>
              <br />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
