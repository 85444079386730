import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row, Card, Collapse } from "react-bootstrap";
import "./style.scss";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export const AssistYourBusiness = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [open, setOpen] = useState(false);

  return (
    <section className="flow-card-container">
      <Container>
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text animate-text-home"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                <span className="bold">How We</span> Assist Your Business
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"150px"}
              margin={"-5px auto 15px auto"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>

        <Row className="flow-card-box-row mb-5">
          {width < breakpoint ? (
            <>
              <Col sm={12} md={3} lg={3} xl={3}>
                <ScrollAnimation
                  animateIn="fadeInRight"
                  duration={2}
                  animateOnce={true}
                >
                  <Card>
                    <Card.Img variant="top" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/assist-1.webp" />
                    <Card.Body>
                      <Card.Text className="num-title">01</Card.Text>
                      <Card.Title>Choose a service</Card.Title>
                      <Card.Text>
                        We help you choose the right target service for your
                        business challenge from the wide selection of
                        possibilities that we offer.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </ScrollAnimation>
              </Col>
              <Col sm={12} md={3} lg={3} xl={3}>
                <ScrollAnimation
                  animateIn="fadeInRight"
                  duration={2}
                  animateOnce={true}
                >
                  <Card>
                    <Card.Img variant="top" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/assist-2.webp" />
                    <Card.Body>
                      <Card.Text className="num-title">02</Card.Text>
                      <Card.Title>Request a meeting</Card.Title>
                      <Card.Text>
                        Whatever your preference is, we are available round the
                        clock to discuss ideas either face-to-face or through
                        virtual channels.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </ScrollAnimation>
              </Col>
              <Collapse in={open}>
                <span id="collapse-assist">
                  <Col sm={12} md={3} lg={3} xl={3}>
                    <ScrollAnimation
                      animateIn="fadeInRight"
                      duration={2}
                      animateOnce={true}
                    >
                      <Card>
                        <Card.Img variant="top" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/assist-3.webp" />
                        <Card.Body>
                          <Card.Text className="num-title">03</Card.Text>
                          <Card.Title>Receive a custom plan</Card.Title>
                          <Card.Text>
                            We take pride in transparent pricing strategies
                            along with clearly defined scopes of work, project
                            milestones, and billing.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </ScrollAnimation>
                  </Col>

                  <Col sm={12} md={3} lg={3} xl={3}>
                    <ScrollAnimation
                      animateIn="fadeInRight"
                      duration={2}
                      animateOnce={true}
                    >
                      <Card>
                        <Card.Img variant="top" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/assist-4.webp" />
                        <Card.Body>
                          <Card.Text className="num-title">04</Card.Text>
                          <Card.Title>Deploy the project</Card.Title>
                          <Card.Text>
                            We make sure the solution we offer hits the
                            bulls-eye and nails your Goal.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </ScrollAnimation>
                  </Col>
                </span>
              </Collapse>

              <button
                onClick={() => setOpen(!open)}
                aria-controls="collapse-assist"
                aria-expanded={open}
                className="d-xl-none d-lg-none collapse-btn mr-auto pt-2"
              >
                {open ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Read More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
              </button>
            </>
          ) : (
            <>
              <Col sm={12} md={3} lg={3} xl={3}>
                <ScrollAnimation
                  animateIn="fadeInRight"
                  duration={2}
                  animateOnce={true}
                >
                  <Card>
                    <Card.Img variant="top" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/assist-1.webp" />
                    <Card.Body>
                      <Card.Text className="num-title">01</Card.Text>
                      <Card.Title>Choose a service</Card.Title>
                      <Card.Text>
                        We help you choose the right target service for your
                        business challenge from the wide selection of
                        possibilities that we offer.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </ScrollAnimation>
              </Col>
              <Col sm={12} md={3} lg={3} xl={3}>
                <ScrollAnimation
                  animateIn="fadeInRight"
                  duration={2}
                  animateOnce={true}
                >
                  <Card>
                    <Card.Img variant="top" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/assist-2.webp" />
                    <Card.Body>
                      <Card.Text className="num-title">02</Card.Text>
                      <Card.Title>Request a meeting</Card.Title>
                      <Card.Text>
                        Whatever your preference is, we are available round the
                        clock to discuss ideas either face-to-face or through
                        virtual channels.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </ScrollAnimation>
              </Col>
              <Col sm={12} md={3} lg={3} xl={3}>
                <ScrollAnimation
                  animateIn="fadeInRight"
                  duration={2}
                  animateOnce={true}
                >
                  <Card>
                    <Card.Img variant="top" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/assist-3.webp" />
                    <Card.Body>
                      <Card.Text className="num-title">03</Card.Text>
                      <Card.Title>Receive a custom plan</Card.Title>
                      <Card.Text>
                        We take pride in transparent pricing strategies along
                        with clearly defined scopes of work, project milestones,
                        and billing.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </ScrollAnimation>
              </Col>

              <Col sm={12} md={3} lg={3} xl={3}>
                <ScrollAnimation
                  animateIn="fadeInRight"
                  duration={2}
                  animateOnce={true}
                >
                  <Card>
                    <Card.Img variant="top" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/assist-4.webp" />
                    <Card.Body>
                      <Card.Text className="num-title">04</Card.Text>
                      <Card.Title>Deploy the project</Card.Title>
                      <Card.Text>
                        We make sure the solution we offer hits the bulls-eye
                        and nails your Goal.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </ScrollAnimation>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </section>
  );
};
