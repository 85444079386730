import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import w1 from "../../../images/xtime/w1.png";
import w2 from "../../../images/xtime/w2.png";
import w3 from "../../../images/xtime/w3.png";
import w4 from "../../../images/xtime/w4.png";
import w5 from "../../../images/xtime/w5.png";
import w6 from "../../../images/xtime/w6.png";
import w7 from "../../../images/xtime/w7.png";
import w8 from "../../../images/xtime/w8.png";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const XtimeWorkflow = () => {
    const { width } = useViewport();
    const breakpoint = 768;
    const [open, setOpen] = useState(false);
  return (
    <div className="xtime-grey-bg mt-5 " id="workflow">
      <Container className="xtimeAbout pt-3 pb-4 ">
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1 ">
                A peep into the{" "}
                <span className="bold">Scheduler product Workflow</span>
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"250px"}
              margin={"-10px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        <Row className="pt-5">
          <Col md={6} className="xtime-workflow">
            <img src={w1} alt="" />
          </Col>
          <Col md={6} className="xtime-workflow">
            <img src={w2} alt="" />
          </Col>
          <Col md={6} className="xtime-workflow">
            <img src={w3} alt="" />
          </Col>

          <Collapse
              in={width < breakpoint ? open : true}
              className="collapse-div"
            >
              <div id="collapse-services-learning">

          <Col md={6} className="xtime-workflow">
            <img src={w4} alt="" />
          </Col>
          <Col md={6} className="xtime-workflow">
            <img src={w5} alt="" />
          </Col>
          <Col md={6} className="xtime-workflow">
            <img src={w6} alt="" />
          </Col>
          <Col md={6} className="xtime-workflow">
            <img src={w7} alt="" />
          </Col>
          <Col md={6} className="xtime-workflow">
            <img src={w8} alt="" />
          </Col>
          </div>
          </Collapse>
        </Row>
        <button
              onClick={() => setOpen(!open)}
              aria-controls="collapse-services-learning"
              aria-expanded={open}
              className="d-xl-none d-lg-none d-md-none collapse-btn text-center mr-auto ml-auto pt-2"
            >
              
              {open ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Expand More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
            </button>
      </Container>
    </div>
  );
};
