import ScrollAnimation from "react-animate-on-scroll";
import { Col, Collapse, Container, Row } from "react-bootstrap";
import icon2 from "../../../images/digitaltrans/icons8-cloud-80.png";
import icon1 from "../../../images/digitaltrans/icons8-big-data-50.png";
import icon3 from "../../../images/digitaltrans/icons8-code-80.png";
import icon4 from "../../../images/digitaltrans/devices.png";

import plat1 from "../../../images/icons/kafka.png";
import plat2 from "../../../images/icons/Logos-vertical/mongodb.png";

import plat3 from "../../../images/icons/spark.png";

import plat4 from "../../../images/icons/elasticsearch.png";

import plat5 from "../../../images/icons/hadoop.png";

import plat6 from "../../../images/icons/tableau.png";
import plat7 from "../../../images/icons/aws.png";
import plat9 from "../../../images/icons/mysql.png";

import plat8 from "../../../images/icons/mysql.png";
import plat19 from "../../../images/icons/docker.png";
import plat10 from "../../../images/icons/codeship.png";
import plat11 from "../../../images/icons/python.png";
import plat12 from "../../../images/icons/java.png";
import plat13 from "../../../images/icons/4-2.png";
import plat14 from "../../../images/icons/react.png";
import plat15 from "../../../images/icons/js.png";
import plat16 from "../../../images/icons/flutter.png";
import plat17 from "../../../images/icons/native.png";
import plat18 from "../../../images/icons/nodejs.png";
import plat20 from "../../../images/icons/dynamo.png";

import "react-multi-carousel/lib/styles.css";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DigitalExpertise = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [openExp, setOpenExp] = useState(false);
  const [openWeb, setOpenWeb] = useState(false);
  return (
    <Container className="mcontainerpadding ">
      <div id="expertise"></div>
      <Row>
        <Col md={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
              Our Development{" "}
              <span className="bold">
                <span className="bold">Platforms & Frameworks Expertise</span>
              </span>
            </h2>
          </ScrollAnimation>
          <AnimatedLine className="animateline" width={"250px"} margin={"0 0"}>
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
        </Col>
      </Row>
      <br />

      <Row>
        <Col md={6} sm={6} xs={12} lg={6} xl={3}>
          <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
            <div class="numberCircle textcenter">
              <img alt="" src={icon1} className="platimage" />
            </div>
            <div
              className="card platcard digi-platforms"
              style={{ height: "auto" }}
            >
              <p className="devheading">
              <span className="bold">Big Data <br /> Development</span>
              <AnimatedLine className="animateline" width={"200px"} margin={"7px auto"}>
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
              </p>
            
              <div class="mflex-container digi-ex-cont">
                <div>
                  <img alt="" src={plat1} className="platimgnew " />

                  <p className="platparagraph">kafka</p>
                </div>
                <div>
                  <img alt="" src={plat2} className="platimgnew " />
                  <p className="platparagraph">MongoDB</p>
                </div>
              </div>
              <div class="mflex-container digi-ex-cont">
                <div>
                  <img alt="" src={plat3} className="platimgnew" />

                  <p className="platparagraph">Apache Spark</p>
                </div>
                <div>
                  <img alt="" src={plat4} className="platimgnew" />
                  <p className="platparagraph">ElasticSearch</p>
                </div>
              </div>
              <Collapse in={width < breakpoint ? openExp === "first" : true}>
                <div id="first">
                  <div class="mflex-container">
                    <div>
                      <img alt="" src={plat5} className="platimgnew" />

                      <p className="platparagraph">Hadoop</p>
                    </div>
                    <div>
                      <img alt="" src={plat6} className="xcodeimg" />
                      <p className="platparagraph">Tableau</p>
                    </div>
                  </div>
                </div>
              </Collapse>

              <button className="d-xl-none d-lg-none d-md-none collapse-btn-services text-center mr-auto ml-auto pt-2">
                {openExp === "first" ? (
                  <FontAwesomeIcon
                    icon="chevron-up"
                    onClick={() => setOpenExp(false)}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon="chevron-down"
                    onClick={() => setOpenExp("first")}
                    aria-controls="first"
                    aria-expanded="first"
                  />
                )}
              </button>
            </div>
          </ScrollAnimation>
        </Col>

        {/* section 2 */}
        <Col md={6} sm={6} xs={12} lg={6} xl={3}>
          <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
            <div class="numberCircle textcenter">
              <img alt="" src={icon2} className="platimage" />
            </div>
            <Row className="mr-0 ml-0">
              <div className="card platcard digi-platforms mb-0">
                <p className="devheading">
                <span className="bold"> Cloud Computing <br /> Technologies</span>
                <AnimatedLine className="animateline" width={"200px"} margin={"7px auto"}>
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
                </p>
                
                <div class="mflex-container digi-ex-cont">
                  <div>
                    <img
                      alt=""
                      src={plat7}
                      className="platimgnew pt-2"
                     
                    />

                    <p className="platparagraph">Amazon Web Services</p>
                  </div>
                </div>
              </div>
            </Row>
            <Row className="mr-0 ml-0">
              <div className="card platcard digi-platforms mt-4">
                <p className="devheading"><span className="bold">Database</span>
                <AnimatedLine className="animateline" width={"200px"} margin={"7px auto"}>
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
                </p>
               
                <div class="mflex-container digi-ex-cont">
                  <div>
                    <img alt="" src={plat9} className="platimgnew " />

                    <p className="platparagraph">MySQL</p>
                  </div>
                  <div>
                    <img alt="" src={plat20} className="platimgnew " />
                    <p className="platparagraph">DynamoDB</p>
                  </div>
                </div>
              </div>
            </Row>
          </ScrollAnimation>
        </Col>

        {/* section 3 */}
        <Collapse
          in={width < breakpoint ? openWeb : true}
          className="collapse-div"
        >
          <div id="collapse-services-learning">
            <Col md={6} sm={6} xs={12} lg={6} xl={3}>
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div class="numberCircle textcenter">
                  <img alt="" src={icon3} className="platimage" />
                </div>
                <Row className="mr-0 ml-0">
                  <div className="card platcard digi-platforms mb-0">
                    <p className="devheading">
                    <span className="bold"> Code Integration & <br /> Deployment</span>
                    <AnimatedLine className="animateline" width={"200px"} margin={"7px auto"}>
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
                    </p>

                    <div class="mflex-container ">
                      <div>
                        <img alt="" src={plat19} className="platimgnew pt-2" />

                        <p className="platparagraph">Docker</p>
                      </div>
                      <div>
                        <img alt="" src={plat10} className="platimgnew pt-2" />

                        <p className="platparagraph">Codeship</p>
                      </div>
                    </div>
                  </div>
                </Row>
                <Row className="mr-0 ml-0">
                  <div className="card platcard digi-platforms mt-4">
                    <p className="devheading"><span className="bold">Languages</span>
                    <AnimatedLine className="animateline" width={"200px"} margin={"5px auto"}>
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
                    </p>
                   
                    <div class="mflex-container digi-ex-cont">
                      <div>
                        <img
                          alt=""
                          src={plat11}
                          className="platimgnew "
                         
                        />

                        <p className="platparagraph">Python</p>
                      </div>
                      <div>
                        <img alt="" src={plat12} className="platimgnew " />
                        <p className="platparagraph">Java</p>
                      </div>
                    </div>
                  </div>
                </Row>
              </ScrollAnimation>
            </Col>
            {/* section 4 */}
            <Col md={6} sm={6} xs={12} lg={6} xl={3}>
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div class="numberCircle textcenter">
                  <img alt="" src={icon4} className="platimage" />
                </div>
                <div
                  className="card platcard digi-platforms"
                 
                >
                  <p className="devheading">
                  <span className="bold"> Web/Mobile App <br /> Development</span>
                  <AnimatedLine className="animateline" width={"200px"} margin={"7px auto"}>
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
                  </p>
                 
                  <div class="mflex-container ">
                    <div>
                      <img alt="" src={plat13} className="platimgnew " />

                      <p className="platparagraph">Angular</p>
                    </div>
                    <div>
                      <img alt="" src={plat14} className="platimgnew " />
                      <p className="platparagraph">React Native</p>
                    </div>
                  </div>
                  <div class="mflex-container ">
                    <div>
                      <img alt="" src={plat15} className="platimgnew" />

                      <p className="platparagraph">Javascript</p>
                    </div>
                    <div>
                      <img alt="" src={plat16} className="platimgnew" />
                      <p className="platparagraph">Flutter</p>
                    </div>
                  </div>
                  <div class="mflex-container">
                    <div>
                      <img alt="" src={plat17} className="platimgnew" />

                      <p className="platparagraph">Native Script</p>
                    </div>
                    <div>
                      <img alt="" src={plat18} className="xcodeimg pt-3" />
                      <p className="platparagraph">NodeJS</p>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
          </div>
        </Collapse>
        <button
              onClick={() => setOpenWeb(!openWeb)}
              aria-controls="collapse-services-learning"
              aria-expanded={openWeb}
              className="d-xl-none d-lg-none d-md-none collapse-btn text-center mr-auto ml-auto pt-2"
            >
             {openWeb ? (
              ""
            ) : (
              <>
                <span className="expand readmore">
                  Expand More <FontAwesomeIcon icon="angle-double-right" />
                </span>
              </>
            )}
            </button>
      </Row>

     
    </Container>

    //

    // circle menu
  );
};
