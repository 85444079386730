import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import rightcircle from "../../../images/rightcircle.png";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import web_process from "../../../images/webdevelopment/webprocess.png";
import { useViewport } from "../../common/designParts/useViewport";
export const WebProcess = () => {
    const { width } = useViewport();
    const breakpoint = 992;
  const [webProcess, setWebprocess] = useState("research");
  return (
    <div>
      {" "}
      <div id="process"></div>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                Our Web Development
                <span className="bold">
                  <span className="bold"> Process Steps</span>
                </span>
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"200px"}
              margin={"-5px 0 15px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        {width < breakpoint ? (
          <Row>
              <Col md={12} className="mb-3">
                  <img src={web_process} alt="" style={{width:"100%"}}/>
              </Col>
          </Row>
        ) : (
        <Row>
          <Col md={9}>
            <section
              class="ps-timeline-sec"
              style={{ margin: "200px 0 420px 10%" }}
            >
              <div class="container">
                <ol class="ps-timeline" style={{ margin: "0" }}>
                  <li style={{ width: "23%" }}>
                    <div class="img-handler-bot">
                      <div
                        className={`bluediv ${
                          webProcess === "research" && "active"
                        }`}
                        onClick={() => setWebprocess("research")}
                      >
                        <p className="bluep">Research and analysis</p>
                      </div>
                    </div>
                    <div class="ps-top">
                      <h3 className="blueh">01</h3>
                    </div>
                    <span class="ps-sp-bot"></span>
                  </li>
                  <li style={{ width: "23%" }}>
                    <div class="img-handler-top" style={{ left: "-40%" }}>
                      <div
                        className={`bluediv ${
                          webProcess === "frontend" && "active"
                        }`}
                        onClick={() => setWebprocess("frontend")}
                      >
                        <p className="bluep">
                          Front-End
                          <br />
                          Development
                        </p>
                      </div>
                    </div>
                    <div class="ps-bot">
                      <h3 className="blueh">02</h3>
                    </div>
                    <span class="ps-sp-top"></span>
                  </li>

                  <li style={{ width: "23%" }}>
                    <div class="img-handler-bot">
                      <div
                        className={`bluediv ${
                          webProcess === "backend" && "active"
                        }`}
                        onClick={() => setWebprocess("backend")}
                      >
                        <p className="bluep">Back-end Development</p>
                      </div>
                    </div>
                    <div class="ps-top">
                      <h3 className="blueh">03</h3>
                    </div>
                    <span class="ps-sp-bot"></span>
                  </li>

                  <li style={{ border: "none", width: "23%" }}>
                    <div class="img-handler-top">
                      <div
                        className={`bluedivnew  ${
                          webProcess === "qatesting" && "active"
                        }`}
                        onClick={() => setWebprocess("qatesting")}
                      >
                        <p className="bluep">
                          QA & Testing
                        </p>
                      </div>
                    </div>
                    <div class="ps-bot">
                      <h3 className="blueh">04</h3>
                    </div>
                    <span class="ps-sp-top"></span>
                  </li>
                </ol>
              </div>
            </section>
          </Col>

          <Col md={3} className="initiancolumn mobile-pro-card pt-5">
            <div
              className="card initiancard"
              style={
                webProcess === "research"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Row>
                <Col md={12}>
                  <p>
                    <span className="inih">
                      <span className="bold">01</span>
                    </span>
                    <span className="inip" style={{ fontSize: "16px" }}>
                      Research and analysis
                    </span>
                  </p>
                </Col>
              </Row>

              <br />
              <Row>
                <Col md={2}>
                  <img alt="" src={rightcircle} className="rightcircleimg" />
                </Col>
                <Col md={10}>
                  <p className="inicontent">Understanding needs and goals.</p>
                </Col>
              </Row>

              <Row>
                <Col md={2}>
                  <img alt="" src={rightcircle} className="rightcircleimg" />
                </Col>
                <Col md={10}>
                  <p className="inicontent">Determining Project Scope.</p>
                </Col>
              </Row>

              <Row>
                <Col md={2}>
                  <img alt="" src={rightcircle} className="rightcircleimg" />
                </Col>
                <Col md={10}>
                  <p className="inicontent">Setting Timelines and estimates.</p>
                </Col>
              </Row>
            </div>
            {/* second */}
            <div
              className="card initiancard"
              style={
                webProcess === "frontend"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Row>
                <Col md={12}>
                  <p>
                    <span className="inih">
                      <span className="bold">02</span>
                    </span>
                    <span className="inip" style={{ fontSize: "16px" }}>
                      Front End Development
                    </span>
                  </p>
                </Col>
              </Row>

              <br />
              <Row>
                <Col md={2}>
                  <img alt="" src={rightcircle} className="rightcircleimg" />
                </Col>
                <Col md={10}>
                  <p className="inicontent">Mapping user’s journey.</p>
                </Col>
              </Row>

              <Row>
                <Col md={2}>
                  <img alt="" src={rightcircle} className="rightcircleimg" />
                </Col>
                <Col md={10}>
                  <p className="inicontent">UI/UX Design.</p>
                </Col>
              </Row>

              <Row>
                <Col md={2}>
                  <img alt="" src={rightcircle} className="rightcircleimg" />
                </Col>
                <Col md={10}>
                  <p className="inicontent">Front-end architecture setup.</p>
                </Col>
              </Row>
            </div>
            {/* third */}
            <div
              className="card initiancard"
              style={
                webProcess === "backend"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Row>
                <Col md={12}>
                  <p>
                    <span className="inih">
                      <span className="bold">03</span>
                    </span>
                    <span className="inip" style={{ fontSize: "16px" }}>
                      Back End Development
                    </span>
                  </p>
                </Col>
              </Row>

              <br />
              <Row>
                <Col md={2}>
                  <img alt="" src={rightcircle} className="rightcircleimg" />
                </Col>
                <Col md={10}>
                  <p className="inicontent">Programming and Scripting.</p>
                </Col>
              </Row>

              <Row>
                <Col md={2}>
                  <img alt="" src={rightcircle} className="rightcircleimg" />
                </Col>
                <Col md={10}>
                  <p className="inicontent">Back-end architecture setup.</p>
                </Col>
              </Row>

              <Row>
                <Col md={2}>
                  <img alt="" src={rightcircle} className="rightcircleimg" />
                </Col>
                <Col md={10}>
                  <p className="inicontent">
                    Accessibility and security compliance.
                  </p>
                </Col>
              </Row>
            </div>
            {/* fourth */}
            <div
              className="card initiancard"
              style={
                webProcess === "qatesting"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Row>
                <Col md={12}>
                  <p>
                    <span className="inih">
                      <span className="bold">04</span>
                    </span>
                    <span className="inip" style={{ fontSize: "16px" }}>
                      QA & Testing
                    </span>
                  </p>
                </Col>
              </Row>

              <br />
              <Row>
                <Col md={2}>
                  <img alt="" src={rightcircle} className="rightcircleimg" />
                </Col>
                <Col md={10}>
                  <p className="inicontent">Deployment of the product.</p>
                </Col>
              </Row>

              <Row>
                <Col md={2}>
                  <img alt="" src={rightcircle} className="rightcircleimg" />
                </Col>
                <Col md={10}>
                  <p className="inicontent">Usability Testing and review.</p>
                </Col>
              </Row>

              <Row>
                <Col md={2}>
                  <img alt="" src={rightcircle} className="rightcircleimg" />
                </Col>
                <Col md={10}>
                  <p className="inicontent">Repeated Iterations.</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        )}
      </Container>
    </div>
  );
};
