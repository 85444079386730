import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import Analysis from "../../../images/xtime/inspect-prouct-hero-1.png";
import styled from "styled-components";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const XtimeAnalysis = () => {
  const { width } = useViewport();
  const breakpoint = 1200;
  const [openWeb, setOpenWeb] = useState(false);
  const AnalysisContent = styled.div`
  background:#fff;
  min-height:150px;
  width:300px
  text-align:left;
  padding:15px;
  position: absolute;
  font-size:14px;
  box-shadow:var(--shd,0 1px 4px rgba(0,0,0,.6));
  top:${(props) => props.top};
  left:${(props) => props.left};
`;
  return (
    <Container className="learningmanage mt-2">
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
              <span className="bold">Primary Analysis</span> for the Project
            </h2>
          </ScrollAnimation>

          <AnimatedLine
            className="animateline"
            width={"150px"}
            margin={"-5px 0 25px 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
        </Col>
      </Row>
      <Row className="d-xl-none">
        <Col xs={12}>
          
            <img src={Analysis} alt="app" style={{width:"100%"}} />
         
        </Col>
        <Col xs={12}>
          <div className="mobile-app-feature-card">
            <div className="d-flex">
              {/* <Dashboard/> */}
              <h3>Pain Points</h3>
              <h1>01</h1>
            </div>
            <p>
              The client needed a way to manage and Implement SaaS Based
              Solutions for Xtime’s products to increase customer experience
              during vehicle service.
            </p>
          </div>

          <div className="mobile-app-feature-card">
            <div className="d-flex">
              {/* <ContactsIcon/> */}
              <h3>Key Objectives</h3>
              <h1>02</h1>
            </div>
            <p>Product Integration with Dealers Service Lane Tools.</p>
            <p>Regulatory Compliance with NHTSA.</p>
            <p>Application Migration from On-Premise to Cloud.</p>
            <p>Real-time integration with Vehicle built-in displays.</p>
          </div>
          <Collapse in={width < breakpoint ? openWeb === true : false}>
            <div id="hide">
              <div className="mobile-app-feature-card">
                <div className="d-flex">
                  {/* <TrackChangesIcon/> */}
                  <h3>Our Solution</h3>
                  <h1>03</h1>
                </div>
                <p>
                  Enhance the workflow for the X-time products by managing API
                  Integrations and improve the Branding of the product.
                </p>
              </div>

              <div className="mobile-app-feature-card">
                <div className="d-flex">
                  {/* <CalendarTodayIcon/> */}
                  <h3>Channels</h3>
                  <h1>04</h1>
                </div>
                <p>
                  iOS Application for scheduling requests Website for scheduling
                  and management Tablet Application for service providers.
                </p>
              </div>
            </div>
          </Collapse>
          <button
            onClick={() => setOpenWeb(!openWeb)}
            aria-controls="collapse-services-learning"
            aria-expanded={openWeb}
            className="d-xl-none collapse-btn text-center mr-auto ml-auto pt-2"
          >
         {openWeb ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Expand More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
          </button>
        </Col>
      </Row>
      <Row className="d-none d-xl-flex">
        <Col md={12}>
          <div className="xtime-analysis-img">
            <img src={Analysis} alt="" />
            <AnalysisContent top={"-5px"} left={"60%"} className="analysis-cont">
              <div className="xtime-analysis-heading">
                <h4>Pain Points </h4> <h2>01</h2>
              </div>
              <p>
                The client needed a way to manage and Implement SaaS Based
                Solutions for Xtime’s products to increase customer experience
                during vehicle service.
              </p>
            </AnalysisContent>
            <AnalysisContent
              top={"81%"}
              left={"60%"}
              className="analysis-cont analysis-cont-2"
            >
              <div className="xtime-analysis-heading">
                <h4>Channels</h4> <h2>04</h2>
              </div>
              <p>
                iOS Application for scheduling requests Website for scheduling
                and management Tablet Application for service providers.
              </p>
            </AnalysisContent>
            <AnalysisContent
              top={"10%"}
              left={"10%"}
              className="analysis-cont-3"
            >
              <div className="xtime-analysis-heading">
                <h4>Channels</h4> <h2>02</h2>
              </div>
              <p>Product Integration with Dealers Service Lane Tools.</p>
              <p>Regulatory Compliance with NHTSA.</p>
              <p>Application Migration from On-Premise to Cloud.</p>
              <p>Real-time integration with Vehicle built-in displays.</p>
            </AnalysisContent>
            <AnalysisContent
              top={"75%"}
              left={"10%"}
              className="analysis-cont-3 analysis-cont-4"
            >
              <div className="xtime-analysis-heading">
                <h4>Our Solution</h4> <h2>03</h2>
              </div>
              <p>
                Enhance the workflow for the X-time products by managing API
                Integrations and improve the Branding of the product.
              </p>
            </AnalysisContent>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
