import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Collapse } from "react-bootstrap";

import image3 from "../../../images/adobe/undraw_Newsletter_re_wrob.svg";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";

export const AdobeRelation = () => {
  const { width } = useViewport();
  const breakpoint = 992;
  const [openlearning, setOpenlearning] = useState(false);
  return (
    <div style={{ backgroundColor: "#F8F8F8" }}>
        <div id="features"></div>
      <Container
        className="learningmanage pb-5 "
        style={{ backgroundColor: "#F8F8F8" }}
      >
        <Row>
          <Col lg={6} className="d-none d-lg-block"></Col>
          <Col lg={6} sm={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1 pt-3">
                Success of our
                <span className="bold"> Campaign Management Service</span>
              </h2>
            </ScrollAnimation>
            <AnimatedLine
              className="animateline"
              width={"200px"}
              margin={"-5px 0 0px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        <Row className="portal-cap-p">
          <Col lg={6}>
            <ScrollAnimation
              animateIn="fade-image-right"
              duration={2}
              animateOnce={true}
            >
              <img src={image3} alt="" className="learning-cap-img pt-5" style={{marginTop:"0%"}} />
            </ScrollAnimation>
          </Col>
          <Col lg={6}>
            <ul className="adobe-list">
              <li> 
                <p>
                  Personalized contextually relevant emails in line with the
                  customer experience - <span>Newsletter Customization</span>
                </p>
              </li>
              <li>
                <p>
                  Discovered comprehensive view of the customers to create
                  personalized campaigns quickly -
                  <span>Organized Data Management</span>
                </p>
              </li>
              <li>
                <p>
                  Designed Adaptive layouts for Mobile, Web, Tablet Platforms -
                  <span>Responsive Campaign Development</span>
                </p>
              </li>
              <Collapse in={width < breakpoint ? openlearning : true}>
              <div id="collapse-learning">
                
              <li>
                <p>
                  Our proactive campaign services helped increase ROI and
                  reduced ownership costs -
                  <span>Effective Cloud Service Usage</span>
                </p>
              </li>
              <li>
                <p>
                  Optimized the campaigns from creating segments to integrating
                  messages to distributing them in real time -
                  <span>Robust Workflow Management</span>
                </p>
              </li>
              <li>
                <p>
                  Re-designed the UI and increase the engagement rate by nearly
                  30% to build product credibility-
                  <span>Enhanced User Experience</span>
                </p>
              </li>

              <li>
                <p>
                  Offered recommendations based on Metrics generated from the
                  campaigns -<span>Proactive Marketing Management</span>
                </p>
              </li>
              </div>
              </Collapse>
            <button
              onClick={() => setOpenlearning(!openlearning)}
              aria-controls="collapse-learning"
              aria-expanded={openlearning}
              className="d-xl-none d-lg-none collapse-btn text-center ml-0 pl-0"
            >
              {openlearning ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Read More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
            </button>
            </ul>
           
          
          </Col>
        </Row>
      </Container>
    </div>
  );
};
