import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import mobile from "../../../images/emasters/mobile.png";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";

export const EmasterApp = () => {
  const { width } = useViewport();
  const breakpoint = 1200;
  const [openWeb, setOpenWeb] = useState(false);
  return (
    <Container className="learningmanage mt-5">
        <div id="features"></div>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
              <span className="bold">Primary Features</span> of the App
            </h2>
          </ScrollAnimation>

          <AnimatedLine
            className="animateline"
            width={"150px"}
            margin={"0px 0 25px 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
        </Col>
      </Row>
      <Row className="d-xl-none">
        <Col xs={12}>
          <div className="mobile-image mobile-space-inc">
            <img src={mobile} alt="app" />
          </div>
        </Col>
        <Col xs={12}>
          <div className="mobile-app-feature-card">
            <div className="d-flex">
              {/* <Dashboard/> */}
              <h3>Dashboard</h3>
              <h1>01</h1>
            </div>
            <p>
              Monitor real-time snapshots to extract meaningful insights about
              the user’s performance. Use Filters to scale down data
              effectively.
            </p>
          </div>

          <div className="mobile-app-feature-card">
            <div className="d-flex">
              {/* <ContactsIcon/> */}
              <h3>Contacts</h3>
              <h1>02</h1>
            </div>
            <p>
              Organize contacts and data by creating views and automating
              actions. Resolve contacts - associate a contact to a particular
              category or do more.
            </p>
          </div>
          <Collapse in={width < breakpoint ? openWeb === true : false}>
            <div id="hide">
              <div className="mobile-app-feature-card">
                <div className="d-flex">
                  {/* <TrackChangesIcon/> */}
                  <h3>Goals</h3>
                  <h1>03</h1>
                </div>
                <p>
                  Track a contact’s performance against daily/weekly goals. With
                  the help of their coach, they can identify obstacles and set
                  themselves up for success.
                </p>
              </div>

              <div className="mobile-app-feature-card">
                <div className="d-flex">
                  {/* <CalendarTodayIcon/> */}
                  <h3>Calendar</h3>
                  <h1>04</h1>
                </div>
                <p>
                  Track, Manage, Assign and Report on the tasks and activities
                  that constitute your contact’s relationships on specific and
                  future dates.
                </p>
              </div>

              <div className="mobile-app-feature-card">
                <div className="d-flex">
                  <h3>Campaign</h3>
                  <h1>05</h1>
                </div>
                <p>
                  Boost a contact’s engagement by choosing from a wide array of
                  pre-designed Email/Text Marketing Campaigns.
                </p>
              </div>

              <div className="mobile-app-feature-card">
                <div className="d-flex">
                  <h3>Roadmap to CFT</h3>
                  <h1>06</h1>
                </div>
                <p>
                  Empower the team members by providing them a learning path to
                  advance to new heights. With access to links and videos in the
                  Knowledge Base, remote learning is possible.
                </p>
              </div>
            </div>
          </Collapse>
          <button
            onClick={() => setOpenWeb(!openWeb)}
            aria-controls="collapse-services-learning"
            aria-expanded={openWeb}
            className="d-xl-none collapse-btn pt-0 pl-0 pb-4"
          >
             {openWeb ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Read More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
          </button>
        </Col>
      </Row>
      <Row className="d-none d-xl-flex">
        <Col md={3}>
          <div className="app-feature-card">
            <div className="d-flex">
              {/* <Dashboard/> */}
              <h3>Dashboard</h3>
              <h1>01</h1>
            </div>
            <p>
              Monitor real-time snapshots to extract meaningful insights about
              the user’s performance. Use Filters to scale down data
              effectively.
            </p>
          </div>

          <div className="app-feature-card">
            <div className="d-flex">
              {/* <ContactsIcon/> */}
              <h3>Contacts</h3>
              <h1>02</h1>
            </div>
            <p>
              Organize contacts and data by creating views and automating
              actions. Resolve contacts - associate a contact to a particular
              category or do more.
            </p>
          </div>

          <div className="app-feature-card">
            <div className="d-flex">
              {/* <TrackChangesIcon/> */}
              <h3>Goals</h3>
              <h1>03</h1>
            </div>
            <p>
              Track a contact’s performance against daily/weekly goals. With the
              help of their coach, they can identify obstacles and set
              themselves up for success.
            </p>
          </div>
        </Col>
        <Col md={6}>
          <div className="mobile-image">
            <img src={mobile} alt="app" />
          </div>
        </Col>
        <Col md={3}>
          <div className="app-feature-card right-card">
            <div className="d-flex">
              {/* <CalendarTodayIcon/> */}
              <h3>Calendar</h3>
              <h1>04</h1>
            </div>
            <p>
              Track, Manage, Assign and Report on the tasks and activities that
              constitute your contact’s relationships on specific and future
              dates.
            </p>
          </div>

          <div className="app-feature-card right-card">
            <div className="d-flex">
              <h3>Campaign</h3>
              <h1>05</h1>
            </div>
            <p>
              Boost a contact’s engagement by choosing from a wide array of
              pre-designed Email/Text Marketing Campaigns.
            </p>
          </div>

          <div className="app-feature-card right-card">
            <div className="d-flex">
              <h3>Roadmap to CFT</h3>
              <h1>06</h1>
            </div>
            <p>
              Empower the team members by providing them a learning path to
              advance to new heights. With access to links and videos in the
              Knowledge Base, remote learning is possible.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
