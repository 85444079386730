import { Col, Collapse, Container, Row } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import ScrollAnimation from "react-animate-on-scroll";
import bluearrow from "../../../images/bluearrow.png";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export const CampaignCapability = () => {
  const { width } = useViewport();
  const breakpoint = 992;
  const [openlearning, setOpenlearning] = useState(false);
  return (
    <div>
      <Container className="mcontainerpadding ">
        {/* <div id="campaignp"></div> */}
        <Row className="mobile-space-inc">
          <Col md={7} className="d-none d-lg-block"></Col>
          <Col lg={5} sm={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                Email <span className="bold">Campaign Management </span>
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"200px"}
              margin={"-10px 0 15px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        <Row className="rowpadding portal-cap-p mobile-space-inc">
          <Col lg={7} md={12} className="textcenter">
            <ScrollAnimation
              animateIn="fade-image-right"
              duration={2}
              animateOnce={true}
            >
              <img
                src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/campaign-v2/undraw_mobile_testing_reah.svg"
                alt=""
                className="learning-cap-img"
                style={{maxWidth:"80%"}}
              />
            </ScrollAnimation>
          </Col>
          <Col
            md={12}
            lg={5}
            style={{ marginTop: "-50px" }}
            id="capability-list"
          >
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <ul className="portal-capable-content">
                <li>Data Management</li>
                <li>Email Design and Coding</li>
                <li>Campaign Development and Production</li>
                <Collapse in={width < breakpoint ? openlearning : true}>
                  <div id="collapse-learning">
                    <li>Content Coordination</li>
                    <li>Dedicated Account Manager</li>
                    <li>Email Deployment</li>
                    <li>A/B Testing and Reporting</li>
                    <li>Campaign management and optimization</li>
                    <li>Determine the causes of deliverability issues</li>
                    <li>Reputation analysis</li>
                  </div>
                </Collapse>
              </ul>
              <button
                onClick={() => setOpenlearning(!openlearning)}
                aria-controls="collapse-learning"
                aria-expanded={openlearning}
                className="d-xl-none d-lg-none collapse-btn text-center ml-0 pl-0"
              >
                {openlearning ? (
                  ""
                ) : (
                  <>
                    <span className="expand readmore">
                      Read More <FontAwesomeIcon icon="angle-double-right" />
                    </span>
                  </>
                )}
              </button>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
