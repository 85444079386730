import { Col, Container, Row } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";

export const AdobeNav = () => {
  return (
    <Container fluid className="d-none d-md-block">
      <Row>
        <Col md={12} className="pl-0 pr-0">
          <div class="mobileflex-container">
            <div>
              <a href="/adobe#aboutproject" className="blacknav">
                About the project
              </a>
            </div>
            <div>
              <a href="/adobe#features" className="blacknav">
                Features
              </a>
            </div>
            <div>
              <a href="/adobe#workflow" className="blacknav">
                Workflow
              </a>
            </div>
            <div>
              <a href="/adobe#process" className="blacknav">
                Process Steps
              </a>
            </div>
            <div>
              <a href="/adobe#whychooseus" className="blacknav">
                Why choose us?
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
