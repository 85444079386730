import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "react-bootstrap";
import mob1 from "../../../images/emasters/wireframe1.png";
import mob2 from "../../../images/emasters/wireframe2.png"
import mob3 from "../../../images/emasters/wireframe3.png"
import mob4 from "../../../images/emasters/wireframe4.png"
import { AnimatedLine } from "../../common/designParts/animatedUnderline";



export const EmasterWireframe = () => {
  return (
    <Container className="emastercontainer pt-3 pb-0" id="workflow">
       
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
            A peep into the  <span className="bold">App’s Wireframes</span>
            </h2>
          </ScrollAnimation>

          <AnimatedLine
              className="animateline"
              width={"150px"}
              margin={"0px 0 10px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>

        </Col>
      </Row>
      <Row>
          <Col lg={3} md={6} xs={12}>

              <div className="app-features">
                  <img src={mob1} alt=""/>

              </div>
              
          </Col>
          <Col lg={3} md={6} xs={12}>

              <div className="app-features">
                  <img src={mob2} alt=""/>

              </div>
              
          </Col>
          <Col lg={3} md={6} xs={12}>

              <div className="app-features">
                  <img src={mob3} alt=""/>

              </div>
              
          </Col>
          <Col lg={3} md={6} xs={12}>

              <div className="app-features">
                  <img src={mob4} alt=""/>

              </div>
              
          </Col>
    </Row>
    </Container>
  );
};
