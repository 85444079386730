import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "react-bootstrap";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import cisco from "../../../images/about/cisco.png";
import adobe from "../../../images/about/adobe.png";
import google from "../../../images/about/google.png";
import infosys from "../../../images/about/infosys.png";
import apple from "../../../images/about/apple.png";
import milestone from "../../../images/about/milestone.png";
import xtime from "../../../images/about/xtime.png";
import ocz from "../../../images/about/ocz.png";
import cox from "../../../images/about/cox.png";
import applause from "../../../images/about/applause.png";
import { useViewport } from "../../common/designParts/useViewport";


export const AboutusClients = () => {
  
  return (
    <Container className="xtimeAbout pt-5 pb-3">
      <Row>
        <Col md={6}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1 ">
              <span className="bold">Our Clients</span>
            </h2>
          </ScrollAnimation>

          <AnimatedLine
            className="animateline"
            width={"70px"}
            margin={"-5px 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
        </Col>
      </Row>
      {/* {width < breakpoint ? (
          <Row className="mb-3 industries-carousel">
        <AliceCarousel mouseTracking items={items} responsive={responsive} 
        autoPlayInterval={1500}
        autoPlay={true}
        disableDotsControls={true}
        disableButtonsControls={false}
        mouseTracking
       
        />
        </Row>
      ) : (
     */}
      <Row>
        <ul className="about-clients">
          <li>
            <div>
              <img src={cisco} alt="cisco" />
            </div>
          </li>
          <li>
            <div>
              <img src={adobe} alt="cisco" />
            </div>
          </li>
          <li>
            <div>
              <img src={google} alt="cisco" />
            </div>
          </li>
          <li>
            <div>
              <img src={infosys} alt="cisco" />
            </div>
          </li>
          <li>
            <div>
              <img src={apple} alt="cisco" />
            </div>
          </li>
        </ul>
        <ul className="about-clients">
          <li>
            <div>
              <img src={milestone} alt="cisco" />
            </div>
          </li>
          <li>
            <div>
              <img src={xtime} alt="cisco" />
            </div>
          </li>
          <li>
            <div>
              <img src={ocz} alt="cisco" />
            </div>
          </li>
          <li>
            <div>
              <img src={cox} alt="cisco" />
            </div>
          </li>
          <li>
            <div>
              <img src={applause} alt="cisco" />
            </div>
          </li>
        </ul>
      </Row>
      
    </Container>
  );
};
