import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "react-bootstrap";
import mob1 from "../../../images/emasters/emasterApp/emasterApp.png";
import mob2 from "../../../images/emasters/emasterApp/app-2.png";
import mob3 from "../../../images/emasters/emasterApp/app-3.png";
import mob4 from "../../../images/emasters/emasterApp/app-4.png";
import mob5 from "../../../images/emasters/emasterApp/app-5.png";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import line from "../../../images/icons/white_line.png";

export const EmasterScreens = () => {
  return (
    <div style={{ backgroundColor: "#f8f8f8" }}>
      <Container className="emastercontainer-2 pr-0 pl-0 pt-3 pb-0 mt-4">
        <Row className="pr-5 pl-5">
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                <span className="bold">High Fidelity</span> Screens
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"100px"}
              margin={"-5px 0 10px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
      </Container>
      <Container fluid className="emastercontainer-2 pr-0 pl-0 pt-0 pb-5 mb-5">
        <Row className="screens-bg">
          <Col lg={6} xs={12} className="d-none d-lg-block"></Col>
          <Col lg={6} xs={12}>
            <div className="app-sc-line-a d-none d-lg-block">
              Contact Manager
              <img src={line} alt="" />
            </div>
            <img src={mob1} alt="" className="app-sc-a" />
            <div className="app-sc-name d-block d-lg-none">Contact Manager</div>
          </Col>
          <Col lg={6} xs={12}>
            <div className="app-sc-line-b d-none d-lg-flex align-items-baseline">
              <img src={line} alt="" />
              Dashboard
            </div>
            <img src={mob2} alt="" className="app-sc-a app-sc-b" />
            <div className="app-sc-name d-block d-lg-none">Dashboard</div>
          </Col>
          <Col lg={6} xs={12} className="d-none d-lg-block"></Col>
          <Col lg={6} xs={12} className="d-none d-lg-block"></Col>
          <Col lg={6} xs={12}>
            <div className="app-sc-line-a d-none d-lg-block">
              Campaigns
              <img src={line} alt="" />
            </div>
            <img src={mob3} alt="" className="app-sc-a app-sc-c" />
            <div className="app-sc-name d-block d-lg-none">Campaigns</div>
          </Col>
          <Col lg={6} xs={12} className="d-none d-lg-block">
            <div className="app-sc-line-b d-none d-lg-flex align-items-baseline">
              <img src={line} alt="" />
              Calendar
            </div>
            <img src={mob4} alt="" className="app-sc-a app-sc-b" />
            <div className="app-sc-name d-block d-lg-none">Calendar</div>
          </Col>
          <Col lg={6} xs={12} className="d-none d-lg-block"></Col>
          <Col lg={6} xs={12} className="d-none d-lg-block"></Col>
          <Col lg={6} xs={12} className="d-none d-lg-block">
            <div className="app-sc-line-a d-none d-lg-block" style={{bottom:"65%"}}>
              Goals
              <img src={line} alt="" />
            </div>
            <img src={mob5} alt="" className="app-sc-a app-sc-c" />
            <div className="app-sc-name d-block d-lg-none">Goals</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
