import React from "react";
import { Container, Row, Col, Image, Collapse } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import dev1 from "../../../images/webdevelopment/code.svg";
import dev2 from "../../../images/webdevelopment/cross-platform.svg";
import dev3 from "../../../images/webdevelopment/application modernization.svg";
import dev4 from "../../../images/webdevelopment/website.svg";
import dev5 from "../../../images/webdevelopment/user-experience.svg";
import dev6 from "../../../images/webdevelopment/it modern.svg";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ServiceCard from "../../common/designParts/serviceCardComponent";

export const WebTransformService = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [open, setOpen] = useState(false);
  const [openservice, setOpenservice] = useState(false);
  return (
    <div>
      <div id="webservice"></div>
      <div className="mbg-img">
        <Container
          className="mcontainerpadding "
          style={{ background: "none" }}
        >
          <Row>
            <Col md={12}>
              <ScrollAnimation
                animateIn="animate-text"
                duration={5}
                animateOnce={true}
              >
                <h2 className="h2heading1">
                  Our Web Development <span className="bold">Services</span>
                </h2>
              </ScrollAnimation>

              <AnimatedLine
                className="animateline"
                width={"200px"}
                margin={"-5px 0 0 0"}
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                  className="no-animation"
                >
                  <div className="line"></div>
                </ScrollAnimation>
              </AnimatedLine>
            </Col>
          </Row>
          <br />

          <Row>
            <Col md={6} lg={4}>
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div className="card digicard">
                  <div className="portal-service-img">
                    <Image src={dev1} className="serveimg" />
                  </div>
                  <p className="devheading"><span className="bold">Custom Web App Development</span></p>
                  <p className="devparagraph">
                    We develop web solutions that solve business challenges. We
                    make sure the web solution is in line with your business
                    requirements. Custom web apps streamline your business
                    activities.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={6} lg={4}>
              <ServiceCard
                id="collapse-services-dtl"
                heading="Cross-platform Apps"
                image={dev2}
                content="Our web app development company develops web and mobile applications that work across operating systems, whether Android, iOS, or Windows and brings the best user experience."
              />
            </Col>
            <Col md={6} lg={4}>
              <ServiceCard
                id="collapse-service-dtl2"
                heading="Product re-engineering"
                image={dev3}
                content="Our application integration address your development, design, integration, testing & deployment requirements to connect unique functionality & data with modern architectures & platforms."
              />
            </Col>

            <Collapse
              in={width < breakpoint ? open : true}
              className="collapse-div"
            >
              <div id="collapse-services-learning">
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl3"
                    heading="Web Portal Development"
                    image={dev4}
                    content=" To enhance your business activity, we develop platforms to easily communicate with customers. We develop enterprise portals like B2B, B2C etc."
                  />
                </Col>
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl4"
                    heading="Enhanced User Experience"
                    image={dev5}
                    content="We can work on all devices so as to help increase the audience reach and engagement of your brand by providing user-friendly Interfaces and also enhance the existing structure."
                  />
                </Col>
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl5"
                    heading="Technology Consulting"
                    image={dev6}
                    content="We have gained knowledge about the functional know-how of these industries. We are focused to become your reliable IT partner to discuss OS, server, software, platform, and technology consulting."
                  />
                </Col>
              </div>
            </Collapse>
            <button
              onClick={() => setOpen(!open)}
              aria-controls="collapse-services-learning"
              aria-expanded={open}
              className="d-xl-none d-lg-none d-md-none collapse-btn text-center mr-auto ml-auto pt-2"
            >
            {open ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Expand More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
            </button>
          </Row>
        </Container>
      </div>
    </div>
  );
};
