import { Container } from "react-bootstrap";

export const PortalCarousel = () => {
  return (
    <div className="hero-styles portalhero">
      <Container>
        <p className="mwhiteparagraph" style={{ overflowX: "hidden" }}>
          <h1 className="cloudcaptionheading">Portal & Collaboration</h1>

          <h4>Developing Customized Portal for Business Operations</h4>
        </p>
      </Container>
    </div>
  );
};
