import React, { PureComponent } from "react";
import Layout from "../../components/layout";
import "react-multi-carousel/lib/styles.css";
import "../../components/section/cloudProcess/cloudprocess.css";
import "../../pages/cloudservices/cloudServices.css";
import "../digitaltranformation/digitaltransformation.css";
import "../campaignManagement/campaign.css";

import {
  UiCarousel,
  UiUxNav,
  UiUxInnovative,
  UxServices,
  UiUxInfo,
  UiUxCapability,
  UiUxExpertise,
  UiUxDeliver,
  UiUxProcess, EmasterStory
} from "../../components/section";
import "./uistyle.css";

class UiUxServices extends PureComponent {
  render() {
    return (
      <Layout>
        <UiCarousel />
        <UiUxNav />
        <UiUxInnovative />
        <UxServices />
        <UiUxInfo />
        <UiUxCapability />
        <UiUxExpertise />
        <EmasterStory />
        <UiUxProcess />
        <UiUxDeliver />
      </Layout>
    );
  }
}

export default UiUxServices;
