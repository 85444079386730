import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";

import plat1 from "../../../images/uiux-deliver/photoshop-cc.svg";
import plat2 from "../../../images/uiux-deliver/adobe-illustrator-cc.svg";

import plat3 from "../../../images/uiux-deliver/indesign-cc.svg";

import plat4 from "../../../images/uiux-deliver/adobe-xd.svg";

import plat5 from "../../../images/uiux-deliver/sketch-2.svg";

import plat6 from "../../../images/uiux-deliver/figma-1.svg";
import plat7 from "../../../images/uiux-deliver/marvel-6.svg";
import plat8 from "../../../images/uiux-deliver/invision.svg";

import "react-multi-carousel/lib/styles.css";
import { useState } from "react";
import { useViewport } from "../../common/designParts/useViewport";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";

export const UiUxExpertise = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  
  return (
    <Container className="mcontainerpadding ">
        <div id="uiuxexperties"></div>
      <Row>
        <Col md={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
            Our Design 
              <span className="bold"> Platforms & Frameworks Expertise</span>
            </h2>
          </ScrollAnimation>
          <AnimatedLine
            className="animateline"
            width={"200px"}
            margin={"-5px 0 0 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
        </Col>
      </Row>
      <br />

      {/*  */}
      <Row>
        <Col lg={6} style={{ padding: "0 5%" }}>
          <div className="campaignExpertise">
            <Row>
              <h4>Adobe Creative Suite</h4>
            </Row>

            <Row className="pt-5">
              <Col col={6}>
                <div className="exp-img">
                  <img src={plat1} alt="icon" />
                  <p>Adobe Photoshop</p>
                </div>
              </Col>
              <Col col={6}>
                <div className="exp-img">
                  <img src={plat2} alt="icon" />
                  <p>Adobe Illustrator</p>
                </div>
              </Col>
            </Row>
            <Row className="pt-3">
              <Col col={6}>
                <div className="exp-img">
                  <img src={plat3} alt="icon" />
                  <p>Adobe Indesign</p>
                </div>
              </Col>
              <Col col={6}>
                <div className="exp-img">
                  <img src={plat4} alt="icon" />
                  <p>Adobe Experience Design</p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        <Col lg={6} style={{ padding: "0 5%" }}>
          <div className="campaignExpertise">
            <Row>
              <h4>Other Design Tools</h4>
            </Row>

            <Row className="pt-5">
              <Col col={6}>
                <div className="exp-img">
                  <img src={plat5} alt="icon" />
                  <p>Sketch</p>
                </div>
              </Col>
              <Col col={6}>
                <div className="exp-img">
                  <img src={plat6} alt="icon" />
                  <p>Figma</p>
                </div>
              </Col>
            </Row>
            <Row className="pt-3">
              <Col col={5}>
                <div className="exp-img">
                  <img src={plat7} alt="icon" />
                  <p>Marvel</p>
                </div>
              </Col>
              <Col col={6}>
                <div className="exp-img">
                  <img src={plat8} alt="icon" />
                  <p>Invision</p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
