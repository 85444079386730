import React, { PureComponent } from "react";
import Layout from "../components/layout";
import "react-multi-carousel/lib/styles.css";
import { HomeCarousel } from "../components/carousel";
import {
  PartnerSection,
  AssistYourBusiness,
  SuccessStories,
  InfoBarSection,
  IndustriesWeServe,
  OurProjectSection,
  OurRelationship,
  OurFocusAreaSection,
} from "../components/section";
class Home extends PureComponent {
  render() {
    return (
      <Layout>
        <HomeCarousel />

        <PartnerSection />

        <OurRelationship />

        <OurFocusAreaSection />

        <OurProjectSection />

        <IndustriesWeServe />

        <InfoBarSection />

        <SuccessStories />

        <AssistYourBusiness />
      </Layout>
    );
  }
}

export default Home;
