import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export const LearningInfo = () => {
  return (
    <div>
      <div className="learningbgInfo">
        <ScrollAnimation
          animateIn="fadeInRight"
          duration={2}
          animateOnce={true}
        >
          <p className="">
            With the latest tools and technology, we create E-Learning products
            that guarantee a smooth, efficient, and engaging learning and
            teaching experience.
          </p>
        </ScrollAnimation>
      </div>
    </div>
  );
};
