import React from "react";
import { Container, Row, Col, Image, Collapse } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import dev1 from "../../../images/portal/document.png";
import dev2 from "../../../images/portal/platform.png";
import dev3 from "../../../images/portal/portalicon.png";
import dev4 from "../../../images/portal/architecture.png";
import dev5 from "../../../images/portal/strategy.png";
import dev6 from "../../../images/portal/icons8-e-commerce-64.png";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ServiceCard from "../../common/designParts/serviceCardComponent";

export const PortalServices = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [open, setOpen] = useState(false);
  const [openservice, setOpenservice] = useState(false);
  return (
    <div>
      <div id="portalservice"></div>
      <div className="mbg-img">
        <Container
          className="mcontainerpadding "
          style={{ background: "none" }}
        >
          <Row>
            <Col md={12}>
              <ScrollAnimation
                animateIn="animate-text"
                duration={5}
                animateOnce={true}
              >
                <h2 className="h2heading1">
                  Our Portal & Collaboration{" "}
                  <span className="bold">Services</span>
                </h2>
              </ScrollAnimation>

              <AnimatedLine
                className="animateline"
                width={"200px"}
                margin={"-5px 0"}
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                  className="no-animation"
                >
                  <div className="line"></div>
                </ScrollAnimation>
              </AnimatedLine>
            </Col>
          </Row>
          <br />

          <Row>
            <Col md={6} lg={4}>
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div className="card digicard">
                  <div className="portal-service-img">
                    <Image src={dev1} className="serveimg" />
                  </div>
                  <p className="devheading"><span className="bold">Document Management</span></p>
                  <p className="devparagraph">
                    Storage, retrieval, and distribution of documents to
                    relevant users. A user authentication process enables
                    documents to be secured from being shared with non-specified
                    parties.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={6} lg={4}>
              <ServiceCard
                id="collapse-services-dtl"
                heading="Unified Platform"
                image={dev2}
                content="With a single unified portal, all stakeholders like employees, directors, vendors, suppliers, and customers can exchange information together, and customers’ queries can be handled quickly and automatically."
              />
            </Col>
            <Col md={6} lg={4}>
              <ServiceCard
                id="collapse-service-dtl2"
                heading="Content Management"
                image={dev3}
                content="We customise workflows, replicate sites, and improve content delivery performance and availability, while reducing operating costs simultaneously."
              />
            </Col>

            <Collapse
              in={width < breakpoint ? open : true}
              className="collapse-div"
            >
              <div id="collapse-services-learning">
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl3"
                    heading="Architecture Development"
                    image={dev4}
                    content="Developing a test strategy, developing a deployment framework and designing the portal ecosystem."
                  />
                </Col>
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl4"
                    heading="Migration & Strategy Development"
                    image={dev5}
                    content="Migrating across platforms, performing version upgrades, developing portal roadmaps, managing releases, and managing organizational change."
                  />
                </Col>
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl5"
                    heading="E-commerce and Retail Enablement"
                    image={dev6}
                    content="E-commerce platforms and solutions need to develop, maintain, and enforce standards, policies, processes and guidelines."
                  />
                </Col>
              </div>
            </Collapse>
            <button
              onClick={() => setOpen(!open)}
              aria-controls="collapse-services-learning"
              aria-expanded={open}
              className="d-xl-none d-lg-none d-md-none collapse-btn text-center mr-auto ml-auto pt-2"
            >
              {open ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Expand More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
            </button>
          </Row>
        </Container>
      </div>
    </div>
  );
};
