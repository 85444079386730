import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import rightcircle from "../../../images/rightcircle.png";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import workflow from "../../../images/workflowAutomation/workflow.png";

export const WorkflowProcess = () => {
  const [click, setClick] = useState("process");
  const { width } = useViewport();
  const breakpoint = 768;

  return (
    <div>
      <div id="workflowprocess"></div>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                Our Workflow Automation
                <span className="bold"> Process Steps</span>
              </h2>
            </ScrollAnimation>

            <div className="animatedline-six" style={{ margin: 0 }}>
              <AnimatedLine
                className="animateline"
                width={"150px"}
                margin={"0px 0 20px 0"}
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                  className="no-animation"
                >
                  <div className="line"></div>
                </ScrollAnimation>
              </AnimatedLine>
            </div>
          </Col>
        </Row>
        <br className="d-none d-md-block" />
        {width < breakpoint ? (
          <Row>
            <Col col={12}>
              <img
                src={workflow}
                alt="workflow"
                className="workflow"
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md={8} sm={12} xs={12}>
              <div className="workflowProcess mb-5">
                <div
                  className={`workflowProBtn ${
                    click === "process" && "active"
                  }`}
                  onClick={() => setClick("process")}
                >
                  Identify Workflow Processes
                </div>
                <div
                  className={`workflowProBtn-2 ${
                    click === "software" && "active"
                  }`}
                  onClick={() => setClick("software")}
                >
                  Implement Workflow Process Software
                </div>
                <div
                  className={`workflowProBtn wbtn-3 ${
                    click === "tasks" && "active"
                  }`}
                  onClick={() => setClick("tasks")}
                >
                  Select Tasks for Automation
                </div>
                <div
                  className={`workflowProBtn-2 wbtn-4 ${
                    click === "optimize" && "active"
                  }`}
                  onClick={() => setClick("optimize")}
                >
                  Optimize Workflow Processes
                </div>
                <div
                  className={`workflowProBtn wbtn-5 ${
                    click === "efficiency" && "active"
                  }`}
                  onClick={() => setClick("efficiency")}
                >
                  Analyse Business Process Efficiency
                </div>
              </div>
            </Col>

            <Col
              md={4}
              className="initiancolumn mobile-pro-card d-sm-none d-md-block d-xs-none mb-5"
            >
              <div
                className="card initiancard"
                style={
                  click === "process"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                        <span className="bold">01</span>
                      </span>
                      <span className="inip">Identify Workflow Processes</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Deconstructing Processes into chunks.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">Conducting a process analysis.</p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Identifying different Optimization Strategies.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">Business Workflow management.</p>
                  </Col>
                </Row>
              </div>
              {/* 2 */}
              <div
                className="card initiancard"
                style={
                  click === "software"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                        <span className="bold">02</span>
                      </span>
                      <span className="inip">
                        Implement Workflow Process Software
                      </span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Collaboration and distribution support.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Signature collection and approval.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Approval process eliminates bottlenecks that could impact
                      productivity.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">Saves time and effort.</p>
                  </Col>
                </Row>
              </div>
              {/* 3 */}
              <div
                className="card initiancard"
                style={
                  click === "tasks" ? { display: "block" } : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                        <span className="bold">03</span>
                      </span>
                      <span className="inip">Select Tasks for Automation</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Split major tasks into smaller task chunks to account for
                      automation.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Identify a suitable Workflow for the sub-steps.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      identify the process and the technical feasibility of the
                      automation.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Find if the workflow could maximise cost and effort
                      savings.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* 4 */}
              <div
                className="card initiancard"
                style={
                  click === "optimize"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                        <span className="bold">04</span>
                      </span>
                      <span className="inip">Optimize Workflow Processes</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Optimize the solution to deliver the routing or automation
                      needed to keep operations running smoothly.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Adjustment of software settings related to automated or
                      manual procedures will be necessary in order to change the
                      ways processes are carried out in the office or on the
                      field.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* 5 */}
              <div
                className="card initiancard"
                style={
                  click === "efficiency"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                        <span className="bold">05</span>
                      </span>
                      <span className="inip">
                        Analyze Business Process Efficiency
                      </span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">Reconfigure existing processes.</p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Distributing documents centrally.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Identifying how our process could be fine-tuned to
                      increase efficiency.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img src={rightcircle} className="rightcircleimg" alt="" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Automated signature collection, verification and
                      form-fill-ups.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};
