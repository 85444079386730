import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "react-bootstrap";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import mahesh from "../../../images/about/about/mahesh.webp";
import tanushree from "../../../images/about/tanushree.png";
import mridul from "../../../images/about/about/mridulV2.webp";

export const AboutTeam = () => {
  return (
    <Container className="xtimeAbout pt-5 pb-3">
      
          
          </Container>
  )
}