import { Container } from "react-bootstrap";

export const QaCarousel = () => {
  return (
    <div className="hero-styles qahero">
       <Container>
        <p className="mwhiteparagraph" style={{ overflowX: "hidden" }}>
          <h1 className="cloudcaptionheading">QA Testing</h1>

          <h4>
          Build Continuous Quality Into Your End-To-End Development Process

          </h4>
        </p>
      </Container>
    </div>
  );
};
