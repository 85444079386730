import React, { PureComponent } from "react";
import Layout from "../../components/layout";
import "react-multi-carousel/lib/styles.css";
import "../../pages/mobiledevelopment/mobiledevelopment.scss";
import "./portfolio.css";
import { Col, Container, Row } from "react-bootstrap";
import xtimewhite from "../../images/xtimewhite.png";
import adobewhite from "../../images/adobe.png";
import emasters from "../../images/emasters-white.png";
import portfoliomobile from "../../images/portfolio/undraw_Mobile_wireframe_re_jxui.png";
import ScrollAnimation from "react-animate-on-scroll";

import { PortFolioCarousel } from "../../components/section";
import { AnimatedLine } from "../../components/common/designParts/animatedUnderline";

class PortFolio extends PureComponent {
  render() {
    return (
      <Layout>
        <PortFolioCarousel />

        <Container className="mcontainerpaddingnew learningmanage">
          <Row className="mb-4">
            <Col lg={7} md={12} sm={12}>
              <ScrollAnimation
                animateIn="animate-text"
                duration={5}
                animateOnce={true}
              >
                <h2 className="h2heading1">
                  Trusted Partner for your next Implementation
                  <br />
                  <span className="bold">Our Work at a Glimpse</span>
                </h2>
              </ScrollAnimation>
              <AnimatedLine
                className="animateline"
                width={"300px"}
                margin={"-5px 0 10px 0"}
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                  className="no-animation"
                >
                  <div className="line"></div>
                </ScrollAnimation>
              </AnimatedLine>

              <p className="mparagraphheading">
                The key to our success is helping brands win. Our focus on
                customer outcomes helps us develop beautiful, engaging
                experiences that promote real business outcomes. Having
                established ourselves as a leader in providing excellent
                software solutions, our products are designed to transform your
                business, enabling you to reach the success you desire.
               
              </p>
              
            </Col>
            <br/>

            <Col lg={5} md={12} sm={12} className="d-none d-md-block mt-2">
              
                  <div>
                    <img alt="" src={portfoliomobile} className="mgridimg1 mt-3 aboutimg1" />
                  </div>
              
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={4}>
              <a href="/emasters" target="_blank">
                <div className="portfolio-img">
                  <ScrollAnimation
                    animateIn="fadeInRight"
                    duration={2}
                    animateOnce={true}
                  >
                    <div className="portfolio-overlay"></div>

                    <img src={emasters} className="portfolio-logo" alt=""></img>
                  </ScrollAnimation>
                  <div className="portfolio-overlay-text">
                    <span className="overlay-btn">Emasters</span>
                    <p>
                      In-House Customer Relationship Management Hybrid
                      Application for iOS and Android platform
                    </p>
                  </div>
                </div>
              </a>
            </Col>
            <Col xs={12} md={4}>
              <a href="/adobe" target="_blank">
                <div className="portfolio-img portfolio-img-2">
                  <ScrollAnimation
                    animateIn="fadeInRight"
                    duration={2}
                    animateOnce={true}
                  >
                    <div className="portfolio-overlay"></div>

                    <img
                      src={adobewhite}
                      className="portfolio-logo"
                      alt=""
                    ></img>
                  </ScrollAnimation>
                  <div className="portfolio-overlay-text">
                    <span className="overlay-btn">Campaign Management</span>
                    <p>
                      Development of a mobile-friendly template for the monthly
                      Adobe newsletter using brand and creative guidelines
                    </p>
                  </div>
                </div>
              </a>
            </Col>

            <Col xs={12} md={4}>
              <a href="/xtime" target="_blank">
                <div className="portfolio-img portfolio-img-3">
                  <ScrollAnimation
                    animateIn="fadeInRight"
                    duration={2}
                    animateOnce={true}
                  >
                    <div className="portfolio-overlay"></div>

                    <img
                      src={xtimewhite}
                      className="portfolio-logo"
                      alt=""
                    ></img>
                  </ScrollAnimation>
                  <div className="portfolio-overlay-text">
                    <span className="overlay-btn">Cloud Services</span>
                    <p>
                      Development of a cloud-based Customer Relationship
                      Management (CRM) and scheduling platform for different
                      automotive clients
                    </p>
                  </div>
                </div>
              </a>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default PortFolio;
