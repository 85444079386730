import { Col, Collapse, Container, Row } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import integration from "../../../images/workflowAutomation/int.png";
import ScrollAnimation from "react-animate-on-scroll";
import bluearrow from "../../../images/bluearrow.png";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export const WorkflowCapability = () => {
  const { width } = useViewport();
  const breakpoint = 992;
  const [openlearning, setOpenlearning] = useState(false);
  return (
    <div>
      <Container className="mcontainerpadding mobile-space-inc">
        <div id="cloudappsolutions"></div>
        <Row>
          <Col md={7} className="d-none d-lg-block"></Col>
          <Col lg={5} sm={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">Our Automation <span className="bold">Capabilities</span></h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"150px"}
              margin={"-10px 0 20px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        <Row className="rowpadding portal-cap-p mobile-space-inc">
          <Col lg={7} md={12} className="textcenter">
            <ScrollAnimation
              animateIn="fade-image-right"
              duration={2}
              animateOnce={true}
            >
              <img src={integration} alt="" className="learning-cap-img" />
            </ScrollAnimation>
          </Col>
          <Col md={12} lg={5} style={{ marginTop: "-50px" }} id="capability-list">
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <ul className="portal-capable-content">
                <li>Real Time Reporting</li>
                <li>Infrastructure Management</li>
                <li>Cloud based Automation</li>
                <Collapse in={width < breakpoint ? openlearning : true}>
                  <div id="collapse-learning">
                    <li>Legacy system migration</li>
                    <li>Data Backup and recovery</li>
                    <li>SAAS, PAAS, IAAS based Automations</li>
                    <li>Document handling and eSignatures</li>
                    <li>Customizing Applications</li>
                    <li>Infrastructure Deployment</li>
                    <li>Performance testing</li>
                  </div>
                </Collapse>
              </ul>
              <button
                onClick={() => setOpenlearning(!openlearning)}
                aria-controls="collapse-learning"
                aria-expanded={openlearning}
                className="d-xl-none d-lg-none collapse-btn text-center ml-0 pl-0"
              >
                {openlearning ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Expand More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
              </button>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
