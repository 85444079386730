import React, { PureComponent } from "react";
import Layout from "../../components/layout";
import "react-multi-carousel/lib/styles.css";
import "../../components/section/cloudProcess/cloudprocess.css";
import "../../pages/cloudservices/cloudServices.css";
import "../digitaltranformation/digitaltransformation.css";
import "../campaignManagement/campaign.css";

import {
  PortalCarousel,
  PortalNav,
  PortalInnovative,
  PortalServices,
  PortalInfo,
  PortalCapability,
  PortalExpertise,
  PortalStory,
  PortalProcess,
} from "../../components/section";
import "./portalCollaboration.css";

class PortalCollaboration extends PureComponent {
  render() {
    return (
      <Layout>
        <PortalCarousel />
        <PortalNav />
        <PortalInnovative />
        <PortalServices />
        <PortalInfo />
        <PortalCapability />
        <PortalExpertise />
        <PortalStory />
        <PortalProcess />
      </Layout>
    );
  }
}

export default PortalCollaboration;
