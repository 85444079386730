



import "react-multi-carousel/lib/styles.css";


export const FounderInfo = () => {
  return (
    <>
      
    </>
  );
};
