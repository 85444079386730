import React, { PureComponent } from "react";
import Layout from "../../components/layout";
import "react-multi-carousel/lib/styles.css";
import '../../components/section/cloudProcess/cloudprocess.css'
import '../../pages/cloudservices/cloudServices.css'
import {
    CloudCarousel,
    CloudSectionNav,
    InnovationCloud,
    CloudDevelopmentService,
    ImplementCloud,
    IntegrationCloud,
    CloudExpertise,
    CloudSuccessStories,
    CloudProcess
  } from "../../components/section";



class CloudServices extends PureComponent {
    render() {
        return (
            <Layout>
                <CloudCarousel />
                <CloudSectionNav />
                <InnovationCloud />
                <CloudDevelopmentService />
                <ImplementCloud />
                <IntegrationCloud />
                <CloudExpertise />
                <CloudSuccessStories />
                <CloudProcess />
            </Layout>
        );
    }
}

export default CloudServices;
