import React from "react";
import { Container } from "react-bootstrap";
import aboutJmt from "../../../images/about/about/about-banner.63f1bbdb.webp"

export const AboutusCarousel = () => {
  return (
   <div className="about_hero">
       <img src={aboutJmt} alt="about" />
   </div>
  );
};
