import React from "react";
import cloudvideo from "../../../images/cloudvideo.mp4";

export const ImplementCloud = () => {
  return (
    <div className="cloudbgInfo" >
      <section class="parallax-container-cloud">
        <video
          playsinline="playsinline"
          autoplay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/cloudvideo.mp4" type="video/mp4" />
        </video>

        <p>
        We create and implement Cloud Applications that are Browser-agnostic, Future-proof and Scalable. we will reshape your Cloud Strategies and take it to the next level in the simplest and the most effective way.
        </p>
      </section>
    </div>
  );
};
