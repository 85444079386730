import { Col, Collapse, Container, Row } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import integration from "../../../images/integrationimg.png";
import ScrollAnimation from "react-animate-on-scroll";
import bluearrow from "../../../images/bluearrow.png";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export const IntegrationCloud = () => {
  const { width } = useViewport();
  const breakpoint = 992;
  const [openlearning, setOpenlearning] = useState(false);
  return (
    <div style={{ background: "#fff" }} id="cloudappsolutions" className="mobile-space-inc">
      <Container className="mcontainerpadding ">
        <div id="cloudappsolutions"></div>
        <Row>
          <Col lg={7} className="d-none d-lg-block"></Col>
          <Col lg={5} md={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                <span className="bold">Cloud App Solution</span> & Integration
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"150px"}
              margin={"-5px 0 15px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        <Row className="rowpadding portal-cap-p mobile-space-inc">
          <Col lg={7} md={12} className="textcenter ">
            <ScrollAnimation
              animateIn="fade-image-right"
              duration={2}
              animateOnce={true}
            >
              <img src={integration} alt="" className="learning-cap-img"  />
            </ScrollAnimation>
          </Col>
          <Col
            md={12}
            lg={5}
            style={{ marginTop: "-50px" }}
            id="capability-list"
          >
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <ul className="portal-capable-content ">
                <li>Cloud Advisory Services</li>
                <li>Cloud Native Development</li>
                <li>Cloud Migration & Modernization</li>
                <Collapse in={width < breakpoint ? openlearning : true}>
                  <div id="collapse-learning">
                    <li>Multi-Cloud Managed</li>
                    <li>Cloud App Development</li>
                    <li>Server less Architecture</li>
                    <li>Implementation & Deployment</li>
                    <li>Platform As A Service</li>
                    <li>Software As A Service</li>
                   
                  </div>
                </Collapse>
              </ul>
              <button
                onClick={() => setOpenlearning(!openlearning)}
                aria-controls="collapse-learning"
                aria-expanded={openlearning}
                className="d-xl-none d-lg-none collapse-btn text-center ml-0 pl-0"
              >
                {openlearning ? (
                  ""
                ) : (
                  <>
                    <span className="expand readmore">
                      Read More <FontAwesomeIcon icon="angle-double-right" />
                    </span>
                  </>
                )}
              </button>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
