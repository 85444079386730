import React, { PureComponent } from "react";
import Layout from "../../components/layout";
import "react-multi-carousel/lib/styles.css";
import "../../components/section/cloudProcess/cloudprocess.css";
import "../../pages/cloudservices/cloudServices.css";
import "../digitaltranformation/digitaltransformation.css";
import "../campaignManagement/campaign.css";

import {
  EmasterStory,
  LearningNav,
  LearningInnovative,
  LearningServices,
  LearningInfo,
  LearningCapability,
  LearningExpertise,
  LearningProcess,LearningCarousel
} from "../../components/section";
import "../portalNcollaboration/portalCollaboration.css";
import "./learningManagement.css";

class PortalCollaboration extends PureComponent {
  render() {
    return (
      <Layout>
        <LearningCarousel />
        <LearningNav />
        <LearningInnovative />
        <LearningServices />
        <LearningInfo />
        <LearningCapability />
        <LearningExpertise />
        <EmasterStory />
        <LearningProcess />
      </Layout>
    );
  }
}

export default PortalCollaboration;
