import { Col, Container, Row } from "react-bootstrap"
import "react-multi-carousel/lib/styles.css";


export const PortalNav = () => {
    return (
        <Container fluid  className="d-none d-md-block">
            <Row>
                <Col md={12} className="pl-0 pr-0">
                    <div class="mobileflex-container">
                        <div><a href="/portal-collaboration#portalservice" className="blacknav">Our Mobile Services</a></div>
                        <div><a href="/portal-collaboration#process" className="blacknav">Working Process</a></div>
                        <div><a href="/portal-collaboration#portalstory" className="blacknav">Case Studies</a></div>
                        <div><a href="/portal-collaboration#expertise" className="blacknav">Our Expertise</a></div>
                       
                    </div>
                </Col>
            </Row>
        </Container>

    )
}