import React, { PureComponent } from "react";
import Layout from "../../components/layout";
import "react-multi-carousel/lib/styles.css";
import '../../pages/mobiledevelopment/mobiledevelopment.css'
import '../../pages/mobiledevelopment/mobiledevelopment.scss'
import {
    MobileAppCarousel,
    ImaginationMobile,
    MobileDevelopmentService,
    MobileBackgroundProduct,
    MobileExpertise,
    MobileSectionNav,
    MobileProcess,
    MobileAppMaster
  } from "../../components/section";



class MobileDevelopment extends PureComponent {
    render() {
        return (
            <Layout>
                <MobileAppCarousel />
                <MobileSectionNav />
                <ImaginationMobile />
                <MobileDevelopmentService />
                <MobileBackgroundProduct />
                <MobileExpertise />
                <MobileAppMaster />
                <MobileProcess />
            </Layout>
        );
    }
}

export default MobileDevelopment;
