import { Container } from "react-bootstrap";

export const CloudCarousel = () => {
  return (
    <div className="hero-styles chero">
      <Container>
        <p className="mwhiteparagraph" style={{ overflowX: "hidden" }}>
          <h1 className="cloudcaptionheading">Cloud Services</h1>

          <h4>Increasing business agility with cloud development services</h4>
        </p>
      </Container>
    </div>
  );
};
