import React, { PureComponent } from "react";
import Layout from "../../components/layout";
import "react-multi-carousel/lib/styles.css";
import "../../pages/mobiledevelopment/mobiledevelopment.css";
import "../../pages/mobiledevelopment/mobiledevelopment.scss";
import {
  AdobeProCarousel,
  AdobeNav,
  AdobeAbout,
  AdobeRelation,
  AdobeAnalysis,
  AdobeCampaign,
  AdobeProcess,
  AdobeNewsletter,
  AdobeTechnology,
  AdobeFinalDesign,
  FounderInfo,
} from "../../components/section";
import "../emasters/emasters.css";
import "./adobe.css";

class AdobePro extends PureComponent {
  render() {
    return (
      <Layout>
        <AdobeProCarousel />
        <AdobeNav />
        <AdobeAbout />
        <AdobeRelation />
        <AdobeAnalysis />
        <AdobeCampaign />
        <AdobeProcess />
        <AdobeNewsletter />
        <AdobeTechnology />
        <AdobeFinalDesign />
        <FounderInfo />
      </Layout>
    );
  }
}

export default AdobePro;
