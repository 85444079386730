import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row, Card } from "react-bootstrap";
import "./style.scss";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { Link } from "react-router-dom";
import { useViewport } from "../../common/designParts/useViewport";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


export const SuccessStories = () => {
  const { width } = useViewport();
  const breakpoint = 992;
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
  };
  return (
    <section className="story-box-container">
      <Container>
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text animate-text-home"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                <span className="bold">Client's</span> Success Stories
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"150px"}
              margin={"0px auto 15px auto"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>

       
        {width < breakpoint ? (
          <Slider {...settings} className="row box-container pb-5">
            <div>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <ScrollAnimation
                  animateIn="fadeInRight"
                  duration={2}
                  animateOnce={true}
                >
                  <Link to={"./adobe"} target="_blank">
                    <Card>
                      <Card.Img
                        variant="top"
                        src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/client1.webp"
                      />
                      <Card.Body>
                        <Card.Title>CAMPAIGN MANAGEMENT</Card.Title>
                        <img
                          src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/adobe.png"
                          alt="adobe"
                          className="story-logo-img"
                        />
                        <Card.Text>
                          Development of a mobile-friendly template for the
                          monthly Adobe newsletter using brand and creative
                          guidelines.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </ScrollAnimation>
              </Col>
            </div>
            <div>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <ScrollAnimation
                  animateIn="fadeInRight"
                  duration={2}
                  animateOnce={true}
                >
                  <Link to={"./emasters"} target="_blank">
                    <Card>
                      <Card.Img
                        variant="top"
                        src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/client2.webp"
                      />
                      <Card.Body>
                        <Card.Title>UI/UX SERVICES</Card.Title>
                        <img
                          src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/emasters-white.png"
                          alt="emasters"
                          className="story-logo-img"
                        />
                        <Card.Text className="home-v2-story">
                          In-House Customer Relationship Management Hybrid
                          Application for iOS and Android platform.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </ScrollAnimation>
              </Col>
            </div>
            <div>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <ScrollAnimation
                  animateIn="fadeInRight"
                  duration={2}
                  animateOnce={true}
                >
                  <Link to={"./xtime"} target="_blank">
                    <Card>
                      <Card.Img
                        variant="top"
                        src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/client3.webp"
                      />
                      <Card.Body>
                        <Card.Title>CLOUD SERVICES</Card.Title>
                        <img
                          src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/xtimewhite.png"
                          alt="xtime"
                          style={{ maxWidth: "150px" }}
                        />
                        <Card.Text>
                          Development of a cloud-based Customer Relationship
                          Management (CRM) and scheduling platform for different
                          automotive clients.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </ScrollAnimation>
              </Col>
            </div>
          </Slider>
        ) : (
            <Row className="box-container" style={{ padding: "0 0 2em 0" }}>
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className="home-v2-story-box"
            >
              <ScrollAnimation
                animateIn="fadeInRight"
                duration={2}
                animateOnce={true}
              >
                <Link to={"./emasters"} target="_blank">
                  <Card>
                    <Card.Img
                      variant="top"
                      src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/client1.webp"
                    />
                    <Card.Body>
                      <Card.Title>UI/UX SERVICES</Card.Title>
                      <img
                        src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/emasters-white.png"
                        alt="emasters"
                        className="story-logo-img"
                      />
                      <Card.Text>
                        In-House Customer Relationship Management Hybrid
                        Application for iOS and Android platform.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </ScrollAnimation>
            </Col>

            <Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className="home-v2-story-box"
            >
              <ScrollAnimation
                animateIn="fadeInRight"
                duration={2}
                animateOnce={true}
              >
                <Link to={"./adobe"} target="_blank">
                  <Card>
                    <Card.Img
                      variant="top"
                      src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/client2.webp"
                    />
                    <Card.Body>
                      <Card.Title>CAMPAIGN MANAGEMENT</Card.Title>
                      <img
                        src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/adobe.png"
                        alt="adobe"
                        className="story-logo-img"
                      />
                      <Card.Text className="home-v2-story">
                        Development of a mobile-friendly template for the
                        monthly Adobe newsletter using brand and creative
                        guidelines.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </ScrollAnimation>
            </Col>

            <Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className="home-v2-story-box"
            >
              <ScrollAnimation
                animateIn="fadeInRight"
                duration={2}
                animateOnce={true}
              >
                <Link to={"./xtime"} target="_blank">
                  <Card>
                    <Card.Img
                      variant="top"
                      src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/client3.webp"
                    />
                    <Card.Body>
                      <Card.Title>CLOUD SERVICES</Card.Title>
                      <img
                        src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/xtimewhite.png"
                        alt="xtime"
                        className="story-logo-img"
                      />
                      <Card.Text>
                        Development of a cloud-based Customer Relationship
                        Management (CRM) and scheduling platform for different
                        automotive clients.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </ScrollAnimation>
            </Col>
          </Row>
        )}
        {/* </Row> */}
      </Container>
    </section>
  );
};
