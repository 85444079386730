import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import image_a from "../../../images/adobe/adobe-tech-1.webp";
import image_b from "../../../images/adobe/adobe-tech-2.webp";
import image_c from "../../../images/adobe/adobe-tech-3.webp";
import image_d from "../../../images/adobe/adobe-tech-4.png";
import image_e from "../../../images/adobe/adobe-tech-5.webp";

import "react-multi-carousel/lib/styles.css";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";

export const AdobeFinalDesign = () => {
  return (
    <div style={{backgroundColor:"#f8f8f8"}}>
      <div id="whychooseus"></div>
      <Container className="emastercontainer-2 pt-3"  style={{paddingBottom:"100px"}}>
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                Final Design with <span className="bold">Outcomes</span>
              </h2>
            </ScrollAnimation>
            <AnimatedLine
              className="animateline"
              width={"200px"}
              margin={"-10px 0 20px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        <Col md={12} className="d-none d-lg-block">
          <div className="adobe-design ">
            <img src={image_a} alt="" />
            <img src={image_b} alt="" />
            <img src={image_c} alt="" />
            <img src={image_d} alt="" />
            <img src={image_e} alt="" />
          </div>
        </Col>
        <Col md={12} className="d-xs-block d-lg-none">
          <div className="adobe-design-mobile">
           
            <img src={image_a} alt=""  className="mb-5"/>
            <img src={image_d} alt="" />
          </div>
        </Col>

        {/*  */}
        <Row></Row>
      </Container>
    </div>
  );
};
