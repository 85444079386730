import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import reactlogo from "../../../images/emasters/react-seeklogo.com.svg";
import flutterlogo from "../../../images/emasters/flutter.png";
import xamarin from "../../../images/emasters/xamarin-seeklogo.com.svg";
import amazon from "../../../images/emasters/1200px-Amazon_Web_Services_Logo.png";
import xcode from "../../../images/emasters/xcode.png";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const EmasterTechnology = () => {
    const { width } = useViewport();
    const breakpoint = 768;
    const [open, setOpen] = useState(false);
  return (
    <Container fluid className="emastercontainer-2 pt-3 pb-5" id="whyus">
      <Row>
        <Col md={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
              Our <span className="bold">Technology Stack</span>
            </h2>
          </ScrollAnimation>

          <AnimatedLine
              className="animateline"
              width={"150px"}
              margin={"-10px 0 0 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
        </Col>
      </Row>
      <Row className="emastertech">
        
          <Col xs={6} md={2}>
            <img src={reactlogo} alt="react native" />
            <p className="text-center p-3">React Native</p>
          </Col >
          <Col xs={6} md={2}>
            <img src={flutterlogo} alt="flutter" />
            <p className="text-center p-3">Flutter</p>
          </Col>
          <Collapse
              in={width < breakpoint ? open : true}
              className="collapse-div"
            >
              <div id="collapse-services-learning">
          <Col xs={6} md={2}>
            <img src={xamarin} alt="xamarin" />
            <p className="text-center p-3">Xamarin</p>
          </Col>
          <Col xs={6} md={2}>
            <img src={amazon} alt="aws" />
            <p className="text-center p-3">AWS</p>
          </Col>
          <Col xs={6} md={2}>
            <img src={xcode} alt="xcode" />
            <p className="text-center p-3">Xcode</p>
          </Col>
          <Col xs={6} className="d-xs-block d-md-none">

          </Col>
          </div>
        </Collapse>
        <button
              onClick={() => setOpen(!open)}
              aria-controls="collapse-services-learning"
              aria-expanded={open}
              className="d-xl-none d-lg-none d-md-none collapse-btn text-center mr-auto ml-auto pt-2"
            >
               {open ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Expand More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
            </button>
       
      </Row>
    </Container>
  );
};
