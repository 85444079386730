import { Col, Collapse, Container, Row } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import integration from "../../../images/portal/undraw_Online_collaboration_re_bkpm.svg";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export const PortalCapability = () => {
  const { width } = useViewport();
  const breakpoint = 992;
  const [openlearning, setOpenlearning] = useState(false);
  return (
    <div>
      <Container className="mobile-space-inc mt-5">
        <div id="cloudappsolutions"></div>
        <Row>
          <Col md={7} className="d-none d-lg-block"></Col>
          <Col lg={5} sm={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                Portal & Collaboration{" "}
                <span className="bold">Capabilities</span>
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"200px"}
              margin={"-10px 0 10px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        <Row className="rowpadding portal-cap-p mobile-space-inc">
          <Col lg={7} md={12} className="textcenter">
            <ScrollAnimation
              animateIn="fade-image-right"
              duration={2}
              animateOnce={true}
            >
              <img src={integration} alt="" className="learning-cap-img pt-4" />
            </ScrollAnimation>
          </Col>
          <Col
            md={12}
            lg={5}
            style={{ marginTop: "-50px" }}
            id="capability-list"
          >
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <ul className="portal-capable-content ">
                <li>Process optimization</li>
                <li>Cost Reduction</li>
                <li>Document Management</li>
                <Collapse in={width < breakpoint ? openlearning : true}>
                  <div id="collapse-learning">
                    <li>Discussing Strategies and Consultation</li>
                    <li>Productivity Improvement</li>
                    <li>Successful Business Ecosystem Creation</li>
                    <li>Communication methods across Multi-channels</li>
                    <li>Unified Portal connections between users</li>
                    <li>Enterprise, B2B and B2C portal solutions</li>
                    <li>User Experience Design</li>
                  </div>
                </Collapse>
              </ul>
              <button
                onClick={() => setOpenlearning(!openlearning)}
                aria-controls="collapse-learning"
                aria-expanded={openlearning}
                className="d-xl-none d-lg-none collapse-btn text-center ml-0 pl-0"
              >
                {openlearning ? (
                  ""
                ) : (
                  <>
                    <span className="expand readmore">
                      Read More <FontAwesomeIcon icon="angle-double-right" />
                    </span>
                  </>
                )}
              </button>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
