import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export const MobileBackgroundProduct = () => {
  return (
    <div>
      <div className="learningbgInfo hero">
        <ScrollAnimation
          animateIn="fadeInRight"
          duration={2}
          animateOnce={true}
        >
          <p className="">
          Our Progressive Web Apps offer native-like features and apply to any device, anywhere, with a single codebase.
          </p>
        </ScrollAnimation>
      </div>
    </div>
  );
};
