import ScrollAnimation from "react-animate-on-scroll";
import { Col, Collapse, Container, Row } from "react-bootstrap";
import plat1 from "../../../images/plat1.png";
import plat2 from "../../../images/plat2.png";
import plat3 from "../../../images/plat3.png";
import plat4 from "../../../images/plat4.png";
import swift from "../../../images/icons/swift.png";
import objective from "../../../images/icons/objective.png";
import swiftui from "../../../images/icons/swiftui.png";
import cocoa from "../../../images/icons/cocoa.png";
import swiftlint from "../../../images/icons/swiftlint.png";
import xcode from "../../../images/icons/xcode.png";
import iphone from "../../../images/iphone.png";
import ipad from "../../../images/ipad.png";
import jetpack from "../../../images/icons/2-5.png";
import java from "../../../images/icons/java.png";
import kotlin from "../../../images/icons/kotlin.png";
import fastlane from "../../../images/icons/2-7.png";
import gradle from "../../../images/icons/gradle.png";
import andphone from "../../../images/andphone.png";
import andtablet from "../../../images/andtablet.png";
import react from "../../../images/icons/react.png";
import flutter from "../../../images/icons/flutter.png";
import native from "../../../images/icons/native.png";
import javascript from "../../../images/icons/js.png";
import mobile from "../../../images/mobile.png";
import tablet from "../../../images/tablet.png";
import desktop from "../../../images/desktop.png";
import polymer from "../../../images/icons/polymer.png";
import redux from "../../../images/icons/redux.png";
import angular from "../../../images/icons/4-2.png";
import "react-multi-carousel/lib/styles.css";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const MobileExpertise = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [openExp, setOpenExp] = useState(false);
  const [openWeb, setOpenWeb] = useState(false);
  return (
    <Container className="mcontainerpadding ">
      <div id="expertise"></div>
      <Row>
        <Col md={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
              Our Development{" "}
              <span className="bold">Platforms & Frameworks Expertise</span>
            </h2>
          </ScrollAnimation>
          <AnimatedLine
            className="animateline"
            width={"250px"}
            margin={"0 0"}
           
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={6} sm={12} xs={12} lg={6} xl={3}>
          <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
            <div class="numberCircle textcenter">
              <img alt="" src={plat1} className="platimage" />
            </div>
            <div className="card platcard">
              <p className="devheading">
                <span className="bold">
                  iOS App
                  <br /> Development
                </span>
                <AnimatedLine
                  className="animateline"
                  width={"200px"}
                  margin={"5px auto"}
                 
                >
                  <ScrollAnimation
                    animateIn="fadeInLeft"
                    duration={2}
                    animateOnce={true}
                    className="no-animation"
                  >
                    <div className="line"></div>
                  </ScrollAnimation>
                </AnimatedLine>
              </p>

              <div class="mflex-container">
                <div>
                  <img alt="" src={swift} className="platimgnew" />
                  <p className="platparagraph">Swift</p>
                </div>
                <div>
                  <img alt="" src={objective} className="platimgnew" />
                  <p className="platparagraph">Objective-C</p>
                </div>
              </div>
              <div class="mflex-container">
                <div>
                  <img alt="" src={swiftui} className="platimgnew" />
                  <p className="platparagraph">Swift UI</p>
                </div>
                <div>
                  <img alt="" src={cocoa} className="platimgnew" />
                  <p className="platparagraph">Cocoa Pods</p>
                </div>
              </div>
              <Collapse in={width < breakpoint ? openExp === "first" : true}>
                <div id="first">
                  <div class="mflex-container">
                    <div>
                      <img alt="" src={swiftlint} className="platimgnew" />
                      <p className="platparagraph">SwiftLint</p>
                    </div>
                    <div>
                      <img alt="" src={xcode} className="xcodeimg" />
                      <p className="platparagraph">Xcode</p>
                    </div>
                  </div>
                  <Row className="textcenter">
                    <Col md={12}>
                      <p className="devicep">Devices</p>
                    </Col>
                  </Row>

                  <div class="mflex-container">
                    <div>
                      <img alt="" src={iphone} className="iphoneimg" />
                      <p className="platparagraph">Iphone</p>
                    </div>
                    <div>
                      <img alt="" src={ipad} className="ipadimg" />
                      <p className="ipadp">Ipad</p>
                    </div>
                  </div>
                </div>
              </Collapse>
              <button className="d-xl-none d-lg-none d-md-none collapse-btn-services text-center mr-auto ml-auto pt-2">
                {openExp === "first" ? (
                  <FontAwesomeIcon
                    icon="chevron-up"
                    onClick={() => setOpenExp(false)}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon="chevron-down"
                    onClick={() => setOpenExp("first")}
                    aria-controls="first"
                    aria-expanded="first"
                  />
                )}
              </button>
            </div>
          </ScrollAnimation>
        </Col>

        <Col md={6} sm={12} xs={12} lg={6} xl={3}>
          <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
            <div class="numberCircle textcenter">
              <img alt="" src={plat2} className="platimage" />
            </div>
            <div className="card platcard">
              <p className="devheading">
                <span className="bold">
                  {" "}
                  Android App
                  <br /> Development
                </span>
                <AnimatedLine
                  className="animateline"
                  width={"200px"}
                  margin={"5px auto"}
                  
                >
                  <ScrollAnimation
                    animateIn="fadeInLeft"
                    duration={2}
                    animateOnce={true}
                    className="no-animation"
                  >
                    <div className="line"></div>
                  </ScrollAnimation>
                </AnimatedLine>
              </p>

              <div class="mflex-container">
                <div>
                  <img alt="" src={jetpack} className="platimgnew" />
                  <p className="platparagraph">Jetpack</p>
                </div>
                <div>
                  <img alt="" src={java} className="platimgnew" />
                  <p className="platparagraph">Java</p>
                </div>
              </div>
              <div class="mflex-container">
                <div>
                  <img alt="" src={kotlin} className="platimgnew" />
                  <p className="platparagraph">Kotlin</p>
                </div>
                <div>
                  <img alt="" src={fastlane} className="platimgnew" />
                  <p className="platparagraph">Fastlane</p>
                </div>
              </div>
              <Collapse in={width < breakpoint ? openExp === "second" : true}>
                <div id="second">
                  <div class="mflex-container">
                    <div>
                      <img alt="" src={gradle} className="platimgnew" />
                      <p className="platparagraph">Gradle</p>
                    </div>
                  </div>
                  <Row className="textcenter">
                    <Col md={12} className="textcenter">
                      <p className="devicep">Devices</p>
                    </Col>
                  </Row>

                  <div class="mflex-container">
                    <div>
                      <img alt="" src={andphone} className="iphoneimg" />
                      <p className="platparagraph">Android Phone</p>
                    </div>
                    <div>
                      <img alt="" src={andtablet} className="andtabletimg" />
                      <p className="platparagraph">Android Tablet</p>
                    </div>
                  </div>
                </div>
              </Collapse>
              <button className="d-xl-none d-lg-none d-md-none collapse-btn-services text-center mr-auto ml-auto pt-2">
                {openExp === "second" ? (
                  <FontAwesomeIcon
                    icon="chevron-up"
                    onClick={() => setOpenExp(false)}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon="chevron-down"
                    onClick={() => setOpenExp("second")}
                    aria-controls="second"
                    aria-expanded="second"
                  />
                )}
              </button>
            </div>
          </ScrollAnimation>
        </Col>
        <Collapse
          in={width < breakpoint ? openWeb : true}
          className="collapse-div"
        >
          <div id="collapse-services-learning">
            <Col md={6} sm={12} xs={12} lg={6} xl={3}>
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div class="numberCircle textcenter">
                  <img alt="" src={plat3} className="platimage" />
                </div>
                <div className="card platcard">
                  <p className="devheading">
                    <span className="bold">
                      {" "}
                      Cross-Platform <br />
                      Development
                    </span>
                    <AnimatedLine
                      className="animateline"
                      width={"200px"}
                      margin={"5px auto"}
                      
                    >
                      <ScrollAnimation
                        animateIn="fadeInLeft"
                        duration={2}
                        animateOnce={true}
                        className="no-animation"
                      >
                        <div className="line"></div>
                      </ScrollAnimation>
                    </AnimatedLine>
                  </p>

                  <div class="mflex-container">
                    <div>
                      <img alt="" src={react} className="platimgnew" />
                      <p className="platparagraph">React Native</p>
                    </div>
                    <div>
                      <img alt="" src={flutter} className="platimgnew" />
                      <p className="platparagraph">Flutter</p>
                    </div>
                  </div>
                  <div class="mflex-container">
                    <div>
                      <img alt="" src={native} className="platimgnew" />
                      <p className="platparagraph">Native Script</p>
                    </div>
                    <div>
                      <img alt="" src={javascript} className="platimgnew" />
                      <p className="platparagraph">Javascript</p>
                    </div>
                  </div>
                  <Collapse
                    in={width < breakpoint ? openExp === "third" : true}
                  >
                    <div id="third">
                      <Row className="textcenter crossdiv">
                        <Col md={12} className="textcenter">
                          <p className="devicep">Devices</p>
                        </Col>
                      </Row>
                      <div class="mflex-container">
                        <div>
                          <img alt="" src={mobile} className="iphoneimg" />
                          <p className="platparagraph">Phone</p>
                        </div>
                        <div>
                          <img alt="" src={tablet} className="tabletimg" />
                          <p className="tabletp">Tablet</p>
                        </div>
                        <div>
                          <img alt="" src={desktop} className="deskimg" />
                          <p className="desktopp">Desktop</p>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                  <button className="d-xl-none d-lg-none d-md-none collapse-btn-services text-center mr-auto ml-auto pt-2">
                    {openExp === "third" ? (
                      <FontAwesomeIcon
                        icon="chevron-up"
                        onClick={() => setOpenExp(false)}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon="chevron-down"
                        onClick={() => setOpenExp("third")}
                        aria-controls="third"
                        aria-expanded="third"
                      />
                    )}
                  </button>
                </div>
              </ScrollAnimation>
            </Col>

            <Col md={6} sm={12} xs={12} lg={6} xl={3}>
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div class="numberCircle textcenter">
                  <img alt="" src={plat4} className="platimage" />
                </div>
                <div className="card platcard">
                  <p className="devheading">
                    <span className="bold">
                      {" "}
                      Progressive App <br />
                      Development
                    </span>
                    <AnimatedLine
                      className="animateline"
                      width={"200px"}
                      margin={"5px auto"}
                     
                    >
                      <ScrollAnimation
                        animateIn="fadeInLeft"
                        duration={2}
                        animateOnce={true}
                        className="no-animation"
                      >
                        <div className="line"></div>
                      </ScrollAnimation>
                    </AnimatedLine>
                  </p>

                  <div class="mflex-container">
                    <div>
                      <img alt="" src={polymer} className="platimgnew" />
                      <p className="platparagraph">Polymer</p>
                    </div>
                    <div>
                      <img alt="" src={redux} className="platimgnew" />
                      <p className="platparagraph">Redux</p>
                    </div>
                  </div>
                  <div class="mflex-container">
                    <div>
                      <img alt="" src={angular} className="platimgnew" />
                      <p className="platparagraph">Angular</p>
                    </div>
                    <div>
                      <img alt="" src={react} className="platimgnew" />
                      <p className="platparagraph">React Native</p>
                    </div>
                  </div>
                  <Collapse
                    in={width < breakpoint ? openExp === "fourth" : true}
                  >
                    <div id="fourth">
                      <Row className="textcenter crossdiv">
                        <Col md={12}>
                          <p className="devicep">Devices</p>
                        </Col>
                      </Row>

                      <div class="mflex-container">
                        <div>
                          <img alt="" src={desktop} className="desktopimg" />
                          <p className="platparagraph">Desktop</p>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                  <button className="d-xl-none d-lg-none d-md-none collapse-btn-services text-center mr-auto ml-auto pt-2">
                    {openExp === "fourth" ? (
                      <FontAwesomeIcon
                        icon="chevron-up"
                        onClick={() => setOpenExp(false)}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon="chevron-down"
                        onClick={() => setOpenExp("fourth")}
                        aria-controls="fourth"
                        aria-expanded="fourth"
                      />
                    )}
                  </button>
                </div>
              </ScrollAnimation>
            </Col>
          </div>
        </Collapse>
        <button
          onClick={() => setOpenWeb(!openWeb)}
          aria-controls="collapse-services-learning"
          aria-expanded={openWeb}
          className="d-xl-none d-lg-none d-md-none collapse-btn text-center mr-auto ml-auto pt-2"
        >
          {openWeb ? (
            ""
          ) : (
            <>
              <span className="expand readmore">
                Expand More <FontAwesomeIcon icon="angle-double-right" />
              </span>
            </>
          )}
        </button>
      </Row>
    </Container>
  );
};
