import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import image1 from "../../../images/qatesting/1.png";
import image2 from "../../../images/qatesting/2.png";
import image3 from "../../../images/qatesting/3.png";
import image4 from "../../../images/qatesting/4.png";

import arrowimg from "../../../images/arrow.svg";
import { useViewport } from "../../common/designParts/useViewport";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import imagemob1 from "../../../images/qatesting/mob1.png";
import imagemob2 from "../../../images/qatesting/mob2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const QaDigital = () => {
  const { width } = useViewport();
  const breakpoint = 1025;
  const [open, setOpen] = useState(false);
  return (
    <Container className="mcontainerpaddingnew learningmanage">
      <Row>
        <Col lg={7} md={12} sm={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            
            <h3 className="subheader">
              Product supremacy through Quality Assurance
            </h3>
            <h2 className="h2heading1">
              <span className="bold">Validate every step of your Workflow</span>
            </h2>
          </ScrollAnimation>
          <AnimatedLine
            className="animateline"
            width={"300px"}
            margin={"-5px 0 10px 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
          <p className="mparagraphheadingtop">
            Our commitment to Quality Engineering, Quality Assurance, and
            Digital Performance is delivered by the industry’s most exemplary
            test methodologies, tools, and services.
            <span
              className={`d-lg-inline d-xl-inline ${
                open ? "d-inline" : "d-none"
              }`}
            >
              {" "}
              In addition, our extensive range of software testing solutions
              ensure that your application is fully functional and bug-free.
            </span>
          </p>
          <span
            className={`d-lg-inline d-xl-inline ${
              open ? "d-inline" : "d-none"
            }`}
          >
           
            <ul className="homelist">
              <li>
                <p>
                  Mitigate technical and business risks through early
                  development consulting.
                </p>
              </li>
              <li>
                <p>
                  Improve software quality with the prevention of high-severity
                  defects.
                </p>
              </li>
              <li>
                <p>
                  Build customer’s trust by delivering flawless software
                  solutions.
                </p>
              </li>
              <li>
                <p>
                  Achieve actionable results for newly designed, untested
                  software modules.
                </p>
              </li>
              <li>
                <p>
                  Fast-track your software release and gain a competitive
                  advantage.
                </p>
              </li>
              <li>
                <p>
                  Improve application performance by leveraging our agile QA
                  approach.
                </p>
              </li>
            </ul>
          </span>
          <button
            className="readmore_btn d-lg-none d-xl-none pl-0"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              ""
            ) : (
              <>
                <span className="expand readmore">
                  Read More <FontAwesomeIcon icon="angle-double-right" />
                </span>
              </>
            )}
          </button>
          <div className="d-lg-none d-xl-none d-md-none learning-mid-img mb-2">
            <img alt="" src={imagemob1} className="mgridimg1" />
          </div>
          <p className="mparagraphheading">
            With our robust Quality Assurance process, we ensure you will
            receive a quality transformation that meets your need for expedited
            deployment.
            <span className={`d-xl-inline d-none `}>
              {" "}
              In addition to helping you define quality goals, we thoroughly
              examine each one and provide a set of steps to take in order to
              improve the quality of your software so that it meets performance
              requirements in all platforms. We integrate manual and automated
              testing and partner with trusted automation frameworks to help you
              accelerate releases, increase test coverage, and find more bugs
              before the software enters production. Our exhaustive evaluation
              of your software ensures that it is up-to-date, secure, and in
              compliance with all regulations.
            </span>
          </p>
          <div className="d-lg-none d-xl-none d-md-none learning-mid-img mt-4">
            <img alt="" src={imagemob2} className="mgridimg1" />
          </div>

          <br />
        </Col>
        <Col lg={5} md={12} sm={12} className="d-none d-md-block mt-2">
          <Row>
            <Col md={6} className="columntop">
              <div>
                <img alt="" src={image1} className="mgridimg1" />
              </div>
              <div>
                <img alt="" src={image2} className="mgridimg2" />
              </div>
            </Col>
            <Col md={6} className="mt-5">
              <div>
                <img alt="" src={image3} className="mgridimg1" />
              </div>
              <br />
              <div>
                <img alt="" src={image4} className="mgridimg1" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
