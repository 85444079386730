import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export const WorkflowInfo = () => {
  return (
    <div>
      <div className="learningbgInfo workflowbg">
        <ScrollAnimation
          animateIn="fadeInRight"
          duration={2}
          animateOnce={true}
        >
          <p className="">
            When implemented successfully, Workflow Automation has a direct
            impact on the quality of customer interaction and increases the
            business value of the organization.
          </p>
        </ScrollAnimation>
      </div>
    </div>
  );
};
