import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "react-bootstrap";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import xtime1 from "../../../images/xtime/engage-tablet2_V2.png";
import xtime2 from "../../../images/xtime/HOLD_302159992_AR_-1_SPBBGGYHUIDW.png";
import xtime3 from "../../../images/xtime/inspect-macbook-1.png";
import xtime4 from "../../../images/xtime/Xtime-Mobile.png";

export const XtimeFinaldesign = () => {
  return (
   
      <Container className="xtimeAbout pt-3 pb-4 ">
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1 ">
               
                <span className="bold">Final Design</span>
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"100px"}
              margin={"-10px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        <Row className="d-none d-xl-block">
            <div className="xtime-final-images mb-5">
                <img src={xtime1} alt=""/>
                <img src={xtime2} alt=""/>
                <img src={xtime3} alt=""/>
                <img src={xtime4} alt=""/>

            </div>
          
        </Row>
        <Row className="d-xl-none">
            <div className="xtime-final-img-mobile">
            <img src={xtime1} alt=""/>
            <p>Xtime Engage</p>
            </div>

            <div className="xtime-final-img-mobile">
            <img src={xtime2} alt=""/>
            <p>Xtime Schedule</p>
            </div>
            <div className="xtime-final-img-mobile">
            <img src={xtime3} alt=""/>
            <p>Xtime Inspect</p>
            </div>
            <div className="xtime-final-img-mobile xtime-mobile">
            <img src={xtime4} alt=""/>
            <p>Xtime Inspect</p>
            </div>
        </Row>
      </Container>
  
  );
};
