import React from "react";
import { Container, Row, Col, Image, Collapse } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";

import dev1 from "../../../images/uiux-deliver/zoom.svg";
import dev2 from "../../../images/uiux-deliver/icon.svg";
import dev3 from "../../../images/uiux-deliver/cross-platform.svg";
import dev4 from "../../../images/uiux-deliver/website.svg";
import dev5 from "../../../images/uiux-deliver/code.svg";
import dev6 from "../../../images/uiux-deliver/mobile-development.svg";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ServiceCard from "../../common/designParts/serviceCardComponent";

export const UxServices = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [open, setOpen] = useState(false);
  const [openservice, setOpenservice] = useState(false);
  return (
    <div>
      <div id="uiuxservice"></div>
      <div className="mbg-img">
        <Container
          className="mcontainerpadding "
          style={{ background: "none" }}
        >
          <Row>
            <Col md={12}>
              <ScrollAnimation
                animateIn="animate-text"
                duration={5}
                animateOnce={true}
              >
                <h2 className="h2heading1">
                  Our UI/UX <span className="bold"> Services</span>
                </h2>
              </ScrollAnimation>

              <AnimatedLine
                className="animateline"
                width={"150px"}
                margin={"-5px 0"}
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                  className="no-animation"
                >
                  <div className="line"></div>
                </ScrollAnimation>
              </AnimatedLine>
            </Col>
          </Row>
          <br />

          <Row>
            <Col md={6} lg={4}>
              <ScrollAnimation
                animateIn="fadeIn"
                duration={2}
                animateOnce={true}
              >
                <div className="card digicard">
                  <div className="portal-service-img">
                    <Image src={dev1} className="serveimg" />
                  </div>
                  <p className="devheading"><span className="bold">User Research</span></p>
                  <p className="devparagraph">
                    Creating appealing user experiences begins with conducting
                    user research, creating wireframes, performing user testing,
                    and studying user personas.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={6} lg={4}>
              <ServiceCard
                id="collapse-services-dtl"
                heading="Agile UI Design"
                image={dev2}
                content="Our UI designers focus on every single element on the screen. The user interface is streamlined with interactions, buttons, fonts, and palette."
              />
            </Col>
            <Col md={6} lg={4}>
              <ServiceCard
                id="collapse-service-dtl2"
                heading="UX Design"
                image={dev3}
                content="With the latest technology and tools, our team can make your idea a reality. Using our process and approach to design, we ensure that the final product works and looks as you intended."
              />
            </Col>

            <Collapse
              in={width < breakpoint ? open : true}
              className="collapse-div"
            >
              <div id="collapse-services-learning">
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl3"
                    heading="SaaS App design"
                    image={dev4}
                    content="By using data-driven design decisions, our UI/UX team helps you create Software applications your users will love."
                  />
                </Col>
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl4"
                    heading="Website Design"
                    image={dev5}
                    content="Need a new website for your business or an upgrade to a new platform? We have a dedicated team which understands market dynamics and help reach your target audience."
                  />
                </Col>
                <Col md={6} lg={4}>
                  <ServiceCard
                    id="collapse-service-dtl5"
                    heading="Mobile Design"
                    image={dev6}
                    content="We create designs for iOS, Android, and hybrid apps. UI/UX designers from our team follow Apple and Google guidelines to design interfaces tailored to your project."
                  />
                </Col>
              </div>
            </Collapse>
            <button
              onClick={() => setOpen(!open)}
              aria-controls="collapse-services-learning"
              aria-expanded={open}
              className="d-xl-none d-lg-none d-md-none collapse-btn text-center mr-auto ml-auto pt-2"
            >
              {open ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Expand More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
            </button>
          </Row>
        </Container>
      </div>
    </div>
  );
};
