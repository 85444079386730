import ScrollAnimation from "react-animate-on-scroll";
import { Container, Image } from "react-bootstrap";
import "./style.scss";
import lineimg from "../../../images/line.svg";
import { useHistory } from "react-router-dom";

export const OurProjectSection = () => {
    const history = useHistory();
  return (
    <section className="our-project-section">
      <Container fluid>
        <video
          playsinline="playsinline"
          autoplay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home-v2/video (1).mp4" type="video/mp4" />
        </video>
        <ScrollAnimation
          animateIn="slideInLeft"
          duration={2}
          animateOnce={true}
        >
          <div class="text-over">
            <p>
              JMT Worldwide LLC provides a complete stack of software consulting
              services to help clients deal their onerous business challenges
              with a balanced and measurable approach to innovation. Partner
              with us for an unparalleled technological experience.
            </p>
            <Image src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/home/line.svg" className="lineproject" />
            <br />
            <button type="button" class="btn btn-default" onClick={()=>window.location = './portfolio'}>
              VIEW OUR PROJECTS
            </button>
          </div>
        </ScrollAnimation>
      </Container>
    </section>
  );
};
