import ScrollAnimation from "react-animate-on-scroll";
import { Badge, Col, Container, Image, Row } from "react-bootstrap";
import "../emasterStory/style.scss";
import logo from "../../../images/digitaltrans/Group-9 copy.png";
import image1 from "../../../images/mob.png";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";

export const PortalStory = () => {
  return (
    <div className="emaster-section pt-5 pb-5">
      <div id="portalstory"></div>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                Our
                <span className="bold">
                  <span className="bold"> Success Stories</span>
                </span>
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"100px"}
              margin={"-5px 0 10px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>

        <div className="detail-section ">
          <Row>
            <Col xs={8} sm={8} md={8} lg={8} xl={8} className="adjust-position">
              <div className="detail">
                <Image
                  src={logo}
                  fluid
                  style={{
                    maxWidth: "60%",
                    display: "block",
                    margin: "10px 0",
                  }}
                />
                <h3>
                  Hybrid Application for Customer Relationship Management.
                </h3>

                <div className="badge-section d-none d-lg-block">
                  <Badge variant="danger">iOS</Badge>
                  <Badge variant="danger">ANDROID</Badge>
                  <Badge variant="danger">
                    CUSTOMER RELATIONSHIP MANAGEMENT (CRM)
                  </Badge>
                  <Badge variant="danger">CLOUD SERVICES</Badge>
                  <Badge variant="danger">UI/UX SERVICES</Badge>
                  <Badge variant="danger">MOBILE APP DEVELOPMENT</Badge>
                </div>
                <p className="d-none d-lg-block">
                  The eMASTERS app was developed to facilitate collaboration
                  amongst the internal sales team members. Corporate users can
                  generate, collate, and distribute their work without having
                  their managers intervene, thereby saving both time and money.
                </p>
              </div>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4} className="adjust-position-img">
              <div className="image-detail">
                <Image src={image1} fluid />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
