import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "react-bootstrap";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import border from "../../../images/xtime/line.svg";

export const XtimeProcess = () => {
  return (
    <Container className="learningmanage mt-3" id="process">
      <Row>
        <Col md={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1 ">
              Our
              <span className="bold"> Process Flow</span>
            </h2>
          </ScrollAnimation>

          <AnimatedLine className="animateline" width={"100px"} margin={"-10px 0 10px 0"}>
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
        </Col>
      </Row>
      <Row className="xtime-process-sec">
        <Col xs={2} lg={5}>
          <h1>01</h1>
        </Col>
        <Col xs={10} lg={7}>
          <div className="xtime-process-cont">
           <p>Data from different OE centres around the selected area.</p>
          </div>
        </Col>
        <Col xs={2} lg={5}></Col>
        <Col xs={10} lg={7}>
            <img src={border} alt="" className="process-line"/>
        </Col>
        <Col xs={2} lg={5}>
          <h1>02</h1>
        </Col>
        <Col xs={10} lg={7}>
          <div className="xtime-process-cont">
           <p>Integration with suitable API to filter and perform operation.</p>
          </div>
        </Col>
        <Col xs={2} lg={5}></Col>
        <Col xs={10} lg={7}>
            <img src={border} alt="" className="process-line"/>
        </Col>
        <Col xs={2} lg={5}>
          <h1>03</h1>
        </Col>
        <Col xs={10} lg={7}>
          <div className="xtime-process-cont">
           <p>Perform the service selected by the <br/> user.
            </p>
          </div>
        </Col>
        <Col xs={2} lg={5}></Col>
        <Col xs={10} lg={7}>
            <img src={border} alt="" className="process-line"/>
        </Col>
        <Col xs={2} lg={5}>
          <h1>04</h1>
        </Col>
        <Col xs={10} lg={7}>
          <div className="xtime-process-cont">
           <p>Generate the reports and analytics for the service.</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
