import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";

export const CampaignProcess = () => {
    const { width } = useViewport();
    const breakpoint = 768;
  return (
    <div>
      <div id="campaignprocess"></div>
      <Container
        className="mcontainerpadding"
        style={{ background: "#fff" }}
      >
        <Row>
          <Col md={12}>
          <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
            <h2 className="h2heading1">
              Our Campaign Management{" "}
              <span className="bold">Process Steps</span>
            </h2>
           </ScrollAnimation>
           <AnimatedLine
              className="animateline"
              width={"200px"}
              margin={"-5px 0 0 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        {width < breakpoint ? 
          <Row>
            <Col md={12} className="textcenter">
              <img src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/campaign-v2/process-mobile.png" alt="process" className="camp_process" />
            </Col>
          </Row>
          :
          <Row>
            <Col md={12} className="textcenter">
              <img src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/campaign-v2/process.png" alt="process" className="camp_process" />
            </Col>
          </Row>
       
       
        }
      </Container>
    </div>
  );
};
