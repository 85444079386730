import ScrollAnimation from "react-animate-on-scroll";
import { Col, Collapse, Container, Row } from "react-bootstrap";
import ad1 from "../../../images/ad1.png";
import ad2 from "../../../images/ad2.png";
import ad3 from "../../../images/ad3.png";
import ad4 from "../../../images/ad4.png";
import ad5 from "../../../images/ad5.png";
import ad6 from "../../../images/ad6.png";
import ad7 from "../../../images/ad7.png";
import ad8 from "../../../images/ad8.png";
import ad9 from "../../../images/ad9.png";
import ad10 from "../../../images/ad10.png";
import ad11 from "../../../images/ad11.png";
import ad12 from "../../../images/ad12.png";

import "react-multi-carousel/lib/styles.css";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CloudExpertise = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [openExp, setOpenExp] = useState(false);
  const [openWeb, setOpenWeb] = useState(false);
  return (
    <div style={{ background: "#fff" }}>
      <Container className="mcontainerpadding ">
        <div id="cloudexperties"></div>
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                Our Development{" "}
                <span className="bold">Platforms & Frameworks Expertise</span>
              </h2>
            </ScrollAnimation>
            <AnimatedLine
              className="animateline"
              width={"150px"}
              margin={"0 0 0 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        <br className="d-none d-md-block" />
        <br className="d-none d-md-block" />

        <Row>
          <Col md={6} lg={6} xl={4} xs={12}>
            <div class="numberCircle textcenter">
              <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/cexp1.png" className="platimage" />
            </div>

            <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
              <div className="card expertcard">
                <p className="devheading"><span className="bold">Deployment & Management</span></p>
                <AnimatedLine
                  className="animateline"
                  width={"100px"}
                  margin={"5px 35%"}
                >
                  <ScrollAnimation
                    animateIn="fadeInLeft"
                    duration={2}
                    animateOnce={true}
                    className="no-animation"
                  >
                    <div className="line"></div>
                  </ScrollAnimation>
                </AnimatedLine>

                <ScrollAnimation
                  animateIn="fadeIn"
                  duration={2}
                  animateOnce={true}
                >
                  <div className="card expertsubcard">
                    <Row className="textcenter">
                      <Col md={12}>
                        <p className="devicep">Application Services</p>
                      </Col>
                    </Row>

                    <div class="mflex-container">
                      <div>
                        <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/dep1.png" className="expertimg" />
                        <p className="expertparagraph">Amazon SQS</p>
                      </div>
                      <div>
                        <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/dep2.png" className="expertimg" />
                        <p className="expertparagraph">
                          Amazon Elastic Transcoder
                        </p>
                      </div>
                      <div>
                        <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/dep3.png" className="expertimg" />
                        <p className="expertparagraph">Amazon SES</p>
                      </div>
                    </div>
                    <div class="mflex-container">
                      <div>
                        <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/dep4.png" className="expertimg" />
                        <p className="expertparagraph">Amazon AppStream</p>
                      </div>
                      <div>
                        <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/dep5.png" className="expertimg" />
                        <p className="expertparagraph">Amazon CloudSearch</p>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
                <Collapse in={width < breakpoint ? openExp === "first" : true}>
                  <div id="first">
                    <ScrollAnimation
                      animateIn="fadeIn"
                      duration={2}
                      animateOnce={true}
                    >
                      <div className="card expertsubcard secondexpertcard">
                        <Row className="textcenter">
                          <Col md={12}>
                            <p className="devicep">Mobile Services</p>
                          </Col>
                        </Row>

                        <div class="mflex-container">
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/dep6.png" className="expertimg" />
                            <p className="expertparagraph">Amazon Cognito</p>
                          </div>
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/dep7.png" className="expertimg" />
                            <p className="expertparagraph">
                              Amazon Mobile Analytics
                            </p>
                          </div>
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/dep8.png" className="expertimg" />
                            <p className="expertparagraph">Amazon SNS</p>
                          </div>
                        </div>
                      </div>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="fadeIn"
                      duration={2}
                      animateOnce={true}
                    >
                      <div className="card expertsubcard secondexpertcard">
                        <Row className="textcenter">
                          <Col md={12}>
                            <p className="devicep">Enterprise Applications</p>
                          </Col>
                        </Row>

                        <div class="mflex-container">
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/dep9.png" className="expertimg" />
                            <p className="expertparagraph">Amazon WorkDocs</p>
                          </div>
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/dep10.png" className="expertimg" />
                            <p className="expertparagraph">Amazon Workspaces</p>
                          </div>
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/dep11.png" className="expertimg" />
                            <p className="expertparagraph">Amazon WorkMail</p>
                          </div>
                        </div>
                      </div>
                    </ScrollAnimation>
                  </div>
                </Collapse>
                <button className="d-xl-none d-lg-none d-md-none collapse-btn-services text-center mr-auto ml-auto pt-2">
                  {openExp === "first" ? (
                    <FontAwesomeIcon
                      icon="chevron-up"
                      onClick={() => setOpenExp(false)}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon="chevron-down"
                      onClick={() => setOpenExp("first")}
                      aria-controls="first"
                      aria-expanded="first"
                    />
                  )}
                </button>
              </div>
            </ScrollAnimation>
          </Col>

          <Col md={6} lg={6} xl={4}>
            <div class="numberCircle textcenter">
              <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/cexp2.png" className="platimage" />
            </div>
            <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
              <div className="card expertcard">
                <p className="devheading"><span className="bold">Application Services</span></p>
                <AnimatedLine
                  className="animateline"
                  width={"100px"}
                  margin={"5px 35%"}
                >
                  <ScrollAnimation
                    animateIn="fadeInLeft"
                    duration={2}
                    animateOnce={true}
                    className="no-animation"
                  >
                    <div className="line"></div>
                  </ScrollAnimation>
                </AnimatedLine>
                <ScrollAnimation
                  animateIn="fadeIn"
                  duration={2}
                  animateOnce={true}
                >
                  <div className="card expertsubcard">
                    <Row className="textcenter">
                      <Col md={12}>
                        <p className="devicep">Administration & Security</p>
                      </Col>
                    </Row>

                    <div class="mflex-container">
                      <div>
                        <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/ad1.png" className="expertimg" />
                        <p className="expertparagraph">AWS DirectoryService</p>
                      </div>
                      <div>
                        <img alt="" src={ad2} className="expertimg1" />
                        <p className="expertparagraph">AWS AWSIAM</p>
                      </div>
                      <div>
                        <img alt="" src={ad3} className="expertimg" />
                        <p className="expertparagraph">AWS Config</p>
                      </div>
                    </div>
                    <div class="mflex-container">
                      <div>
                        <img alt="" src={ad4} className="expertimg" />
                        <p className="expertparagraph">AWS Trusted Advisor</p>
                      </div>
                      <div>
                        <img alt="" src={ad5} className="expertimg" />
                        <p className="expertparagraph">AWS CloudTrail</p>
                      </div>
                      <div>
                        <img alt="" src={ad6} className="expertimg" />
                        <p className="expertparagraph">Amazon CloudWatch</p>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
                <Collapse in={width < breakpoint ? openExp === "second" : true}>
                  <div id="second">
                    <ScrollAnimation
                      animateIn="fadeIn"
                      duration={2}
                      animateOnce={true}
                    >
                      <div className="card expertsubcard secondexpertcard">
                        <Row className="textcenter">
                          <Col md={12}>
                            <p className="devicep">Deployment & Management</p>
                          </Col>
                        </Row>

                        <div class="mflex-container">
                          <div>
                            <img alt="" src={ad7} className="expertimg" />
                            <p className="expertparagraph">
                              Deployment & Management
                            </p>
                          </div>
                          <div>
                            <img alt="" src={ad8} className="expertimg" />
                            <p className="expertparagraph">AWS OpsWorks</p>
                          </div>
                          <div>
                            <img alt="" src={ad9} className="expertimg" />
                            <p className="expertparagraph">AWS CodeDeploy</p>
                          </div>
                        </div>
                      </div>
                    </ScrollAnimation>
                    <ScrollAnimation
                      animateIn="fadeIn"
                      duration={2}
                      animateOnce={true}
                    >
                      <div className="card expertsubcard secondexpertcard">
                        <Row className="textcenter">
                          <Col md={12}>
                            <p className="devicep">Analytics</p>
                          </Col>
                        </Row>

                        <div class="mflex-container">
                          <div>
                            <img alt="" src={ad10} className="expertimg" />
                            <p className="expertparagraph">Amazon Kinesis</p>
                          </div>
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/ad11.png" className="expertimg" />
                            <p className="expertparagraph">AWS Data Pipeline</p>
                          </div>
                          <div>
                            <img alt="" src={ad12} className="expertimg" />
                            <p className="expertparagraph">Amazon EMR</p>
                          </div>
                        </div>
                      </div>
                    </ScrollAnimation>
                  </div>
                </Collapse>
                <button className="d-xl-none d-lg-none d-md-none collapse-btn-services text-center mr-auto ml-auto pt-2">
                  {openExp === "second" ? (
                    <FontAwesomeIcon
                      icon="chevron-up"
                      onClick={() => setOpenExp(false)}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon="chevron-down"
                      onClick={() => setOpenExp("second")}
                      aria-controls="second"
                      aria-expanded="second"
                    />
                  )}
                </button>
              </div>
            </ScrollAnimation>
          </Col>
          <Collapse
              in={width < breakpoint ? openWeb : true}
              className="collapse-div"
            >
              <div id="collapse-services-learning">
          <Col md={6} lg={6} xl={4} className="m-auto">
            <div class="numberCircle textcenter">
              <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/cexp3.png" className="platimage" />
            </div>
            <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
              <div className="card expertcard">
                <p className="devheading"><span className="bold">Foundation Services</span></p>
                <AnimatedLine
                  className="animateline"
                  width={"100px"}
                  margin={"5px 35%"}
                >
                  <ScrollAnimation
                    animateIn="fadeInLeft"
                    duration={2}
                    animateOnce={true}
                    className="no-animation"
                  >
                    <div className="line"></div>
                  </ScrollAnimation>
                </AnimatedLine>
                <ScrollAnimation
                  animateIn="fadeIn"
                  duration={2}
                  animateOnce={true}
                >
                  <div className="card expertsubcard">
                    <Row className="textcenter">
                      <Col md={12}>
                        <p className="devicep">Compute</p>
                      </Col>
                    </Row>

                    <div class="mflex-container">
                      <div>
                        <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/co1.png" className="expertimg" />
                        <p className="expertparagraph">Amazon EC2</p>
                      </div>
                      <div>
                        <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/co2.png" className="expertimg" />
                        <p className="expertparagraph">AWS Lambda</p>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
                <Collapse in={width < breakpoint ? openExp === "third" : true}>
                  <div id="third">
                    <ScrollAnimation
                      animateIn="fadeIn"
                      duration={2}
                      animateOnce={true}
                    >
                      <div className="card expertcardthird">
                        <Row className="textcenter">
                          <Col md={12}>
                            <p className="devicep">
                              Storage & Content Delivery
                            </p>
                          </Col>
                        </Row>

                        <div class="mflex-container">
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/co3.png" className="expertimg" />
                            <p className="expertparagraph">Amazon CloudFront</p>
                          </div>
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/co4.png" className="expertimg" />
                            <p className="expertparagraph">Amazon Glacier</p>
                          </div>
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/co5.png" className="expertimg" />
                            <p className="expertparagraph">Amazon S3</p>
                          </div>
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/co6.png" className="expertimg" />
                            <p className="expertparagraph">Amazon EBS</p>
                          </div>
                        </div>
                      </div>
                    </ScrollAnimation>
                    <ScrollAnimation
                      animateIn="fadeIn"
                      duration={2}
                      animateOnce={true}
                    >
                      <div className="card expertcardthird">
                        <Row className="textcenter">
                          <Col md={12}>
                            <p className="devicep">Database</p>
                          </Col>
                        </Row>

                        <div class="mflex-container">
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/co7.png" className="expertimg" />
                            <p className="expertparagraph">Amazon DynamoDB</p>
                          </div>
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/co8.png" className="expertimg" />
                            <p className="expertparagraph">Amazon RDS</p>
                          </div>
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/co9.png" className="expertimg" />
                            <p className="expertparagraph">Amazon RedShift</p>
                          </div>
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/co10.png" className="expertimg" />
                            <p className="expertparagraph">
                              Amazon ElasticCache
                            </p>
                          </div>
                        </div>
                      </div>
                    </ScrollAnimation>
                    <ScrollAnimation
                      animateIn="fadeIn"
                      duration={2}
                      animateOnce={true}
                    >
                      <div className="card expertcardthird">
                        <Row className="textcenter">
                          <Col md={12}>
                            <p className="devicep">Networking</p>
                          </Col>
                        </Row>

                        <div class="mflex-container">
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/co11.png" className="expertimg" />
                            <p className="expertparagraph">Amazon Route S3</p>
                          </div>
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/co12.png" className="expertimg" />
                            <p className="expertparagraph">Amazon VPC</p>
                          </div>
                          <div>
                            <img alt="" src="https://6725abb7-7926-49cd-89ec-e9663b1da57c.s3.us-west-2.amazonaws.com/cloud/co13.png" className="expertimg" />
                            <p className="expertparagraph">
                              AWS Direct Connect
                            </p>
                          </div>
                        </div>
                      </div>
                    </ScrollAnimation>
                  </div>
                </Collapse>
                <button className="d-xl-none d-lg-none d-md-none collapse-btn-services text-center mr-auto ml-auto pt-2">
                  {openExp === "third" ? (
                    <FontAwesomeIcon
                      icon="chevron-up"
                      onClick={() => setOpenExp(false)}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon="chevron-down"
                      onClick={() => setOpenExp("third")}
                      aria-controls="third"
                      aria-expanded="third"
                    />
                  )}
                </button>
              </div>
            </ScrollAnimation>
          </Col>
          </div>
        </Collapse>
        <button
              onClick={() => setOpenWeb(!openWeb)}
              aria-controls="collapse-services-learning"
              aria-expanded={openWeb}
              className="d-xl-none d-lg-none d-md-none collapse-btn text-center mr-auto ml-auto pt-2"
            >
               {openWeb ? (
              ""
            ) : (
              <>
                <span className="expand readmore">
                  Expand More <FontAwesomeIcon icon="angle-double-right" />
                </span>
              </>
            )}
            </button>
        </Row>
      </Container>
    </div>
  );
};
