import { Link } from "react-router-dom";

export const Search = [
  {
    keyword: "adobe",
    link: "/adobe",
  },
  {
    keyword: "emasters",
    link: "/emasters",
  },
  {
    keyword: "xtime",
    link: "/xtime",
  },
  {
    keyword: "adobe creative cloud",
    link: "/campaign-management",
  },
  {
    keyword: "adobe experience cloud",
    link: "/campaign-management",
  },
  {
    keyword: "adobe document cloud",
    link: "/campaign-management",
  },
  {
    keyword: "adobe experience design",
    link: "/campaign-management",
  },
  {
    keyword: "adobe dreamweaver",
    link: "/campaign-management",
  },
  {
    keyword: "adobe campaign",
    link: "/campaign-management",
  },
  {
    keyword: "amazon sqs",
    link: "/cloud-services",
  },
  {
    keyword: "amazon elastic transcoder",
    link: "/cloud-services",
  },
  {
    keyword: "amazon ses",
    link: "/cloud-services",
  },
  {
    keyword: "amazon appstream",
    link: "/cloud-services",
  },
  {
    keyword: "amazon cloudsearch",
    link: "/cloud-services",
  },
  {
    keyword: "amazon cognito",
    link: "/cloud-services",
  },
  {
    keyword: "amazon mobile analytics",
    link: "/cloud-services",
  },
  {
    keyword: "amazon SNS",
    link: "/cloud-services",
  },
  {
    keyword: "amazon workdocs",
    link: "/cloud-services",
  },
  {
    keyword: "amazon workspaces",
    link: "/cloud-services",
  },
  {
    keyword: "amazon workmail",
    link: "/cloud-services",
  },
  {
    keyword: "AWS directory service",
    link: "/cloud-services",
  },
  {
    keyword: "AWS AWSIAM",
    link: "/cloud-services",
  },
  {
    keyword: "AWS config",
    link: "/cloud-services",
  },
  {
    keyword: "AWS trusted advisor",
    link: "/cloud-services",
  },
  {
    keyword: "AWS cloudtrai",
    link: "/cloud-services",
  },
  {
    keyword: "amazon cloudwatch",
    link: "/cloud-services",
  },
  {
    keyword: "deployment & management",
    link: "/cloud-services",
  },
  {
    keyword: "AWS opsworks",
    link: "/cloud-services",
  },
  {
    keyword: "AWS codedeploy",
    link: "/cloud-services",
  },
  {
    keyword: "amazon kinesis",
    link: "/cloud-services",
  },
  {
    keyword: "AWS data pipeline",
    link: "/cloud-services",
  },
  {
    keyword: "Amazon EMR",
    link: "/cloud-services",
  },
  {
    keyword: "Amazon EC2",
    link: "/cloud-services",
  },
  {
    keyword: "AWS Lambda",
    link: "/cloud-services",
  },
  {
    keyword: "Amazon CloudFront",
    link: "/cloud-services",
  },
  {
    keyword: "Amazon Glacier",
    link: "/cloud-services",
  },
  {
    keyword: "Amazon S3",
    link: "/cloud-services",
  },
  {
    keyword: "Amazon EBS",
    link: "/cloud-services",
  },
  {
    keyword: "Amazon DynamoDB",
    link: "/cloud-services",
  },
  {
    keyword: "Amazon RDS",
    link: "/cloud-services",
  },
  {
    keyword: "Amazon RedShift",
    link: "/cloud-services",
  },
  {
    keyword: "Amazon ElasticCache",
    link: "/cloud-services",
  },
  {
    keyword: "Amazon Route S3",
    link: "/cloud-services",
  },
  {
    keyword: "Amazon VPC",
    link: "/cloud-services",
  },
  {
    keyword: "AWS Direct Connect",
    link: "/cloud-services",
  },
  {
    keyword: "kafka",
    link: "/digital-transformation",
  },
  {
    keyword: "MongoDB",
    link: "/digital-transformation",
  },
  {
    keyword: "Apache Spark",
    link: "/digital-transformation",
  },
  {
    keyword: "ElasticSearch",
    link: "/digital-transformation",
  },
  {
    keyword: "Hadoop",
    link: "/digital-transformation",
  },
  {
    keyword: "Tableau",
    link: "/digital-transformation",
  },
  {
    keyword: "Amazon Web Services",
    link: "/digital-transformation",
  },
  {
    keyword: "MySQL",
    link: "/digital-transformation",
  },
  {
    keyword: "DynamoDB",
    link: "/digital-transformation",
  },
  {
    keyword: "Docker",
    link: "/digital-transformation",
  },
  {
    keyword: "Codeship",
    link: "/digital-transformation",
  },
  {
    keyword: "Python",
    link: "/digital-transformation",
  },
  {
    keyword: "Java",
    link: "/digital-transformation",
  },
  {
    keyword: "Angular",
    link: "/digital-transformation",
  },
  {
    keyword: "React Native",
    link: "/digital-transformation",
  },
  {
    keyword: "Javascript",
    link: "/digital-transformation",
  },
  {
    keyword: "Flutter",
    link: "/digital-transformation",
  },
  {
    keyword: "Native Script",
    link: "/digital-transformation",
  },
  {
    keyword: "NodeJS",
    link: "/digital-transformation",
  },
  {
    keyword: "HTML 5",
    link: "/learning-management-system",
  },
  {
    keyword: "CSS 3",
    link: "/learning-management-system",
  },
  {
    keyword: "Angular",
    link: "/learning-management-system",
  },
  {
    keyword: "Javascript",
    link: "/learning-management-system",
  },
  {
    keyword: "ReactJS",
    link: "/learning-management-system",
  },
  {
    keyword: "VueJS",
    link: "/learning-management-system",
  },
  {
    keyword: "Kotlin",
    link: "/learning-management-system",
  },
  {
    keyword: "PHP",
    link: "/learning-management-system",
  },
  {
    keyword: "Amazon Web Services",
    link: "/learning-management-system",
  },
  {
    keyword: "Swift",
    link: "/learning-management-system",
  },
  {
    keyword: "Joomla",
    link: "/learning-management-system",
  },
 
];
