import { Col, Container, Row } from "react-bootstrap"
import "react-multi-carousel/lib/styles.css";


export const CampaignNav = () => {
    return (
        <Container fluid  className="d-none d-md-block">
        <Row>
            <Col md={12} className="pl-0 pr-0">
                    <div class="mobileflex-container">
                        <div><a href="/campaign-management#campaignservice" className="blacknav">Our Campaign Services</a></div>
                        <div><a href="/campaign-management#campaignprocess" className="blacknav">Working Process</a></div>
                        <div><a href="/campaign-management#campaigncasestudy" className="blacknav">Case Studies</a></div>
                        <div><a href="/campaign-management#campaignexpertise" className="blacknav">Our Expertise</a></div>
                       
                    </div>
                </Col>
            </Row>
        </Container>

    )
}