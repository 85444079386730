import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import ipad from "../../../images/xtime/ipad-scheduling.png";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const XtimeMigration = () => {
    const { width } = useViewport();
  const breakpoint = 1024;
  const [openWeb, setOpenWeb] = useState(false);
  return (
    <div className="xtime-grey-bg mt-5" id="why">
      <Container className="xtimeAbout pt-3 pb-5 ">
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1 ">
                <span className="bold">Cloud Migration Strategy</span>
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"100px"}
              margin={"-10px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col md={6}>
            <ul className="adobe-list">
              <li>
                <p>Lift and Shift combined with robust A/B Testing.</p>
              </li>
              <li>
                <p>
                Identify & Migrate specific components including DB, as-is from on-prem to cloud.

                </p>
              </li>
              <li>
                <p>
                Break large monolith projects into smaller micro services based on strategic normalisation before migrating.

                </p>
              </li>
              <Collapse in={width < breakpoint ? openWeb : true}>
            <div id="hide">
              <li>
                <p>
                Created multiple AWS accounts & environments for develop, integration, Production to drive CI/CD Framework.

                </p>
              </li>
              <li>
                <p>
                Established access controls using IAM Roles within VPC.

                </p>
              </li>
              <li>
                <p>
                Developed Real-time Splunk alerts for health monitoring of systems.
                </p>
              </li>
              </div>
              </Collapse>
              <button
            onClick={() => setOpenWeb(!openWeb)}
            aria-controls="collapse-services-learning"
            aria-expanded={openWeb}
            className="d-xl-none collapse-btn text-center mr-auto ml-auto pt-2"
          >
            {openWeb ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Expand More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
          </button>
            </ul>
          </Col>
          <Col md={6}>
            <img src={ipad} alt="" style={{width:"100%"}} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
