import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Image, Collapse } from "react-bootstrap";
import { AnimatedLine } from "./animatedUnderline";
import { useViewport } from "./useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const ServiceCard = (props) => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [openservice, setOpenservice] = useState(false);
  return (
    <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
      <div className="card digicard">
        <div className="portal-service-img">
          <img src={props.image} className="" />
        </div>
        <p className="devheading"><span className="bold">{props.heading}</span></p>
        <Collapse in={width < breakpoint ? openservice === props.id : true}>
          <div id={props.id}>
            <p className="devparagraph">{props.content}</p>
          </div>
        </Collapse>
        <button className="d-xl-none d-lg-none d-md-none collapse-btn-services text-center mr-auto ml-auto pt-2">
          {openservice === props.id ? (
            <FontAwesomeIcon
              icon="chevron-up"
              onClick={() => setOpenservice(false)}
            />
          ) : (
            <FontAwesomeIcon
              icon="chevron-down"
              onClick={() => setOpenservice(props.id)}
              aria-controls={props.id}
              aria-expanded={props.id}
            />
          )}
        </button>
      </div>
    </ScrollAnimation>
  );
};

export default ServiceCard;
