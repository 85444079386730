import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "react-bootstrap";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import layout from "../../../images/adobe/newsletter-layout.webp";
import layoutTop from "../../../images/adobe/newsletterpart.webp";
import layoutMid from "../../../images/adobe/newsletter-p.webp";
import layoutBot from "../../../images/adobe/newsletter-pb.webp";

export const AdobeNewsletter = () => {
  return (
      <div style={{backgroundColor:"#f8f8f8"}}>
    <Container className="emastercontainer-2 pt-3 pb-3">
      <Container>
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                A peep into the Adobe <span className="bold"> Newsletter Initial Design</span>
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"200px"}
              margin={"-5px 0 15px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        <Row className="d-lg-none">
            <Col md={12}>
                <div className="newletter-layout-mobile">
                    <div className="mb-5">
                <img src={layoutTop} alt=""/>
                <p>Header with Logo and Date</p>
                </div>
                <div className="mb-5">
                  <img src={layoutMid} alt=""/>
                  <p>Primary Content with a Card Layout</p>
                  </div>
                  <div className="mb-5">
                  <img src={layoutBot} alt=""/>
                  <p>Footer with Links</p>
                  </div>
                </div>
            </Col>
        </Row>
        <Row className="d-none d-lg-flex">
          <Col md={6}>
              <div className="newsletter-layout">
                  <img src={layout} alt=""/>
                  <img src={layoutTop} alt=""/>
                  <img src={layoutMid} alt=""/>
                  <img src={layoutBot} alt=""/>
              </div>
          </Col>
          <Col md={6}>
              <div className="newsletter-info">
                  <p>Header with Logo and Date</p>
                  <p>Primary content with a card Layout</p>
                  <p>Call to Action Link</p>
                  <p>Footer with Links</p>
              </div>
          </Col>
        </Row>
      </Container>
    </Container>
    </div>
  );
};
