import React, { PureComponent } from "react";
import Layout from "../../components/layout";
import "react-multi-carousel/lib/styles.css";
import "../../pages/mobiledevelopment/mobiledevelopment.css";
import "../../pages/mobiledevelopment/mobiledevelopment.scss";
import {
 AboutusInfo, AboutusVision, AboutusClients, AboutTeam, AboutusCarousel
} from "../../components/section";
import "../emasters/emasters.css";
import "../xtime/xtime.css";
import "./about.css";

class AboutUs extends PureComponent {
  render() {
    return (
      <Layout>
        <AboutusCarousel />
        <AboutusInfo/>
        <AboutusVision/>
        <AboutusClients/>
        <AboutTeam/>
      </Layout>
    );
  }
}

export default AboutUs;
