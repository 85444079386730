import { Col, Container, Row } from "react-bootstrap"
import "react-multi-carousel/lib/styles.css";


export const CloudSectionNav = () => {
    return (
        <Container fluid  className="d-none d-md-block">
            <Row>
                <Col md={12} className="pl-0 pr-0">
                    <div class="mobileflex-container">
                        <div><a href="/cloud-services#cloudservice" className="blacknav">Our Cloud Services</a></div>
                        <div><a href="/cloud-services#cloudappsolutions" className="blacknav">Cloud App Solutions</a></div>
                        <div><a href="/cloud-services#cloudexperties" className="blacknav">Development Expertise</a></div>
                        <div><a href="/cloud-services#xtimestory" className="blacknav">Our Success Stories</a></div>
                        <div><a href="/cloud-services#cloudprocessstep" className="blacknav">Our Process</a></div>
                    </div>
                </Col>
            </Row>
        </Container>

    )
}