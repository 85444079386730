import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Image } from "react-bootstrap";
import image1 from "../../../images/mg1.png";
import image2 from "../../../images/mg2.png";
import image3 from "../../../images/mg3.png";
import image4 from "../../../images/mg4.png";
import arrowimg from "../../../images/arrow.svg";
import { useViewport } from "../../common/designParts/useViewport";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import imagemob1 from "../../../images/mobile2.png";
import imagemob2 from "../../../images/mobile1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ImaginationMobile = () => {
  const { width } = useViewport();
  const breakpoint = 800;
  const [open, setOpen] = useState(false);
  return (
    <Container className="mcontainerpaddingnew learningmanage">
      <Row>
        <Col lg={7} md={12} sm={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h3 className="subheader">
              Expand your digital footprint across the mobile platform
            </h3>
            <h2 className="h2heading1">
              <span className="bold">Build Top-Notch Mobile Experience</span>
            </h2>
          </ScrollAnimation>
          <AnimatedLine
            className="animateline"
            width={"300px"}
            margin={"-5px 0 0 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
          <p className="mparagraphheadingtop">
            Our mobile app development solutions cater to both smartphones and
            tablets, no matter the platform or device on which they are to be
            developed.
            <span
              className={`d-lg-inline d-xl-inline ${
                open ? "d-inline" : "d-none"
              }`}
            >
              &nbsp;With many years of experience delivering interoperable, scalable,
              and flexible apps for all types of industries, our mobile
              application developers are technology experts who know the
              industry well.
            </span>
          </p>
          <span
            className={`d-lg-inline d-xl-inline ${
              open ? "d-inline" : "d-none"
            }`}
          >
          
            <ul className="homelist">
              <li>
                <p>
                  Establish your presence on any device and platform using our
                  latest technology.
                </p>
              </li>
              <li>
                <p>
                  Reduce costs + time-to-market and Reach more users without
                  loss of quality.
                </p>
              </li>
              <li>
                <p>
                  Delight your users with expressive and feature-rich
                  Native/Hybrid Applications.
                </p>
              </li>
              <li>
                <p>
                  Upgrade your legacy apps to take full advantage of the new
                  mobile platforms.
                </p>
              </li>
              <li>
                <p>
                  Ensure security, scalability, and efficient release management
                  of your application.
                </p>
              </li>
              <li>
                <p>
                  Use our consulting services to leverage your App idea from
                  concept to deployment.
                </p>
              </li>
            </ul>
          </span>
          <button
            className="readmore_btn d-lg-none d-xl-none pl-0"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              ""
            ) : (
              <>
                <span className="expand readmore">
                  Read More <FontAwesomeIcon icon="angle-double-right" />
                </span>
              </>
            )}
          </button>
          <div className="d-lg-none d-xl-none d-md-none learning-mid-img mb-4">
            <img alt="" src={imagemob1} className="mgridimg1" />
          </div>
          <p className="mparagraphheading">
            We specialize in app development technologies like Android, iOS,
            React, Flutter, Xamarin, Ionic, Phonegap, Laravel, C#, C++, Visual
            Studio, Cordova, etc.
            <span className={`d-xl-inline d-none `}>
              {" "}
              In addition to designing multiple channels and device responsive
              applications, we help businesses transform customer experiences.
              Using the latest UI/UX technologies, architectures, and trends, we
              develop the next generation of mobile products to add
              functionality to existing and new applications. Using an extensive
              design thinking approach, We can ensure that your product strategy
              meshes seamlessly with users’ needs.
            </span>
          </p>
          <div className="d-lg-none d-xl-none d-md-none learning-mid-img mt-4">
            <img alt="" src={imagemob2} className="mgridimg1" />
          </div>

          <br />
        </Col>
        <Col lg={5} md={12} sm={12} className="d-none d-md-block mt-2">
          <Row>
            <Col md={6} className="columntop">
              <div>
                <img alt="" src={image1} className="mgridimg1" />
              </div>
              <div>
                <img alt="" src={image2} className="mgridimg2" />
              </div>
            </Col>
            <Col md={6} className="mt-5">
              <div>
                <img alt="" src={image3} className="mgridimg1" />
              </div>
              <br />
              <div>
                <img alt="" src={image4} className="mgridimg1" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
