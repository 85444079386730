import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import plat4 from "../../../images/icons/xd.svg";
import plat5 from "../../../images/icons/dreamviewer.png";


import plat6 from "../../../images/icons/icon3.png";
import plat7 from "../../../images/icons/html.png";

import plat8 from "../../../images/icons/css.png";


import "react-multi-carousel/lib/styles.css";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";

export const AdobeTechnology = () => {
  return (
    <>
      <div id="campaignexpertise"></div>
      <Container className="mcontainerpadding pt-3 ">
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                Our <span className="bold"> Technology Stack</span>
              </h2>
            </ScrollAnimation>
            <AnimatedLine
              className="animateline"
              width={"100px"}
              margin={"-5px 0 25px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>

        {/*  */}
        <Row>
          <Col md={8} xs={12} >
            <div className="campaignExpertise-first">
              <Row>
                <h4>Design</h4>
              </Row>

              <Row className="pt-5">
                <Col col={4}>
                  <div className="exp-img">
                    <img src={plat4} alt="icon" />
                    <p>Adobe Experience Design</p>
                  </div>
                </Col>
                <Col col={4}>
                  <div className="exp-img">
                    <img src={plat5} alt="icon" />
                    <p>Adobe Dreamweaver</p>
                  </div>
                </Col>
                <Col col={4}>
                  <div className="exp-img">
                    <img src={plat6} alt="icon" />
                    <p>Adobe Campaign</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={4} xs={12}>
            <div className="campaignExpertise">
              <Row>
                <h4>Implementation</h4>
              </Row>

              <Row className="pt-5">
                <Col col={6}>
                  <div className="exp-img">
                    <img src={plat7} alt="icon" />
                    <p>HTML</p>
                  </div>
                </Col>
                <Col col={6}>
                  <div className="exp-img">
                    <img src={plat8} alt="icon" />
                    <p>CSS</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
