import ScrollAnimation from "react-animate-on-scroll";
import {Col, Container, Row } from "react-bootstrap";
import "../emasterStory/style.scss";
import mob from "../../../images/uiux-deliver/mob.png";
import line from "../../../images/uiux-deliver/lineimg.png";
import { useViewport } from "../../common/designParts/useViewport";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";

export const UiUxDeliver = () => {
    const { width } = useViewport();
  const breakpoint = 992;
  return (
    <div style={width < breakpoint ? {display:"none"}:{display:"block"}}>
      <div id="ui-ux-service"></div>
      <Container className="mcontainerpadding">
        <Row>
        <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
              We 
                <span className="bold"> Deliver</span>
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"100px"}
              margin={"-5px 0 10px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        <Row>
          <div className="col-md-9">
            <div className="row ui_deliver_row">
              <div className="col-md-4">
                <ul className="ui_deliver">
                  <li>Key Personas Profiles.</li>
                  <li>Customer Journey Map.</li>
                  <li>Competitors Overview.</li>
                </ul>
              </div>
              <div className="col-md-1">
                <img alt="" src={line} />
              </div>
              <div className="col-md-2 ui_caption">
                <span className="ui_blue">Discovery</span>
              </div>
              <div className="col-md-5 pl-0">
                <h1 className="ui-num">01</h1>
              </div>
            </div>
            {/*  */}
            <div className="row ui-deliver-row-1">
              <div className="col-md-5">
                <ul className="ui_deliver">
                  <li>User Scenarios.</li>
                  <li>Use cases & User Stories.</li>
                  <li>Feature set and MVP.</li>
                </ul>
              </div>
              <div className="col-md-1 d-flex pl-0">
                <img alt="" src={line}  />
              </div>
              <div className="col-md-4 ui_caption">
                <span className="ui_blue">Define & Ideate</span>
              </div>
              <div className="col-md-2 pl-0">
                <h1 className="ui-num">02</h1>
              </div>
            </div>
            {/*  */}
            <div className="row ui-deliver-row-2">
              <div className="col-md-5">
                <ul className="ui_deliver">
                  <li>Wireframes.</li>
                  <li>Med/High-Fidelity Mockups.</li>
                  <li>Prototypes with Interactions.</li>
                </ul>
              </div>
              <div className="col-md-1 d-flex pl-0">
                <img alt="" src={line} />
              </div>
              <div className="col-md-4 ui_caption">
                <span className="ui_blue">Design & Prototype</span>
              </div>
              <div className="col-md-2 pl-0">
                <h1 className="ui-num">03</h1>
              </div>
            </div>
            {/*  */}
            <div className="row ui-deliver-row-3">
              <div className="col-md-5">
                <ul className="ui_deliver">
                  <li>Design Validated with user.</li>
                  <li>Test Results.</li>
                  <li>Deployment & Maintenance Document.</li>
                </ul>
              </div>
              <div className="col-md-1 d-flex pl-0">
                <img alt="" src={line} />
              </div>
              <div className="col-md-4 ui_caption">
                <span className="ui_blue">Validate & Test</span>
              </div>
              <div className="col-md-2 pl-0">
                <h1 className="ui-num">04</h1>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <img alt="" src={mob} className="ui-mob-image" />
          </div>
        </Row>
      </Container>
    </div>
  );
};
