import React from "react";
import { Container, Row, Col, Image, Collapse } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import dev1 from "../../../images/dev1.png";
import dev2 from "../../../images/dev2.png";
import dev3 from "../../../images/dev3.png";
import dev4 from "../../../images/dev4.png";
import dev5 from "../../../images/dev5.png";
import dev6 from "../../../images/dev6.png";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ServiceCard from "../../common/designParts/serviceCardComponent";


export const MobileDevelopmentService = () => {
    const { width } = useViewport();
    const breakpoint = 768;
    const [open, setOpen] = useState(false);
    const [openservice, setOpenservice] = useState(false);
    return (
      <div>
        <div id="mobileservice"></div>
        <div className="mbg-img">
          <Container
            className="mcontainerpadding "
            style={{ background: "none" }}
          >
            <Row>
              <Col md={12}>
                <ScrollAnimation
                  animateIn="animate-text"
                  duration={5}
                  animateOnce={true}
                >
                  <h2 className="h2heading1">Our <span className="bold">Mobile App Development Services</span></h2>
                </ScrollAnimation>
  
                <AnimatedLine
                  className="animateline"
                  width={"200px"}
                  margin={"-5px 0"}
                  
                >
                  <ScrollAnimation
                    animateIn="fadeInLeft"
                    duration={2}
                    animateOnce={true}
                    className="no-animation"
                  >
                    <div className="line"></div>
                  </ScrollAnimation>
                </AnimatedLine>
              </Col>
            </Row>
            <br />
  
            <Row>
              <Col md={6} lg={4}>
                <ScrollAnimation
                  animateIn="fadeIn"
                  duration={2}
                  animateOnce={true}
                >
                  <div className="card digicard">
                    <div className="portal-service-img">
                      <Image src={dev1} className="serveimg" />
                    </div>
                    <p className="devheading"><span className="bold">App Prototyping and Strategy</span></p>
                    <p className="devparagraph">
                    To transform customer experiences across multiple channels and devices, our experts help businesses design responsive and scalable applications.
                    </p>
                  </div>
                </ScrollAnimation>
              </Col>
              <Col md={6} lg={4}>
                <ServiceCard
                  id="collapse-services-dtl"
                  heading="Native Apps Development"
                  image={dev2}
                  content="Mobile app development for Android and iOS platforms that conforms to your business and security needs delivered by our mobile app developers."
                />
              </Col>
              <Col md={6} lg={4}>
                <ServiceCard
                  id="collapse-service-dtl2"
                  heading="Hybrid Apps Development"
                  image={dev3}
                  content="Native and web apps combined into cross-platform apps that work in different environments."
                />
              </Col>
  
              <Collapse
                in={width < breakpoint ? open : true}
                className="collapse-div"
              >
                <div id="collapse-services-learning">
                  <Col md={6} lg={4}>
                    <ServiceCard
                      id="collapse-service-dtl3"
                      heading="Full Stack Mobile App Development
                      "
                      image={dev4}
                      content="We offer customized and scalable solutions to enhance business and increase productivity across a wide range of industries."
                    />
                  </Col>
                  <Col md={6} lg={4}>
                    <ServiceCard
                      id="collapse-service-dtl4"
                      heading="Mobile App UX Design Services"
                      image={dev5}
                      content="Our design team uses an iterative approach to problem-solving so that companies can deal with problems in a human-centric way."
                    />
                  </Col>
                  <Col md={6} lg={4}>
                    <ServiceCard
                      id="collapse-service-dtl5"
                      heading="Progressive Web App Development"
                      image={dev6}
                      content="Our Progressive Web Apps offer native-like features and apply to any device, anywhere, with a single codebase."
                    />
                  </Col>
                </div>
              </Collapse>
              <button
                onClick={() => setOpen(!open)}
                aria-controls="collapse-services-learning"
                aria-expanded={open}
                className="d-xl-none d-lg-none d-md-none collapse-btn text-center mr-auto ml-auto pt-2"
              >
                 {open ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Expand More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
              </button>
            </Row>
          </Container>
        </div>
      </div>
  );
};
