import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "react-bootstrap";

import image3 from "../../../images/adobe/product-email-newsletter-series.png";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";

export const AdobeAbout = () => {
  const { width } = useViewport();
  const breakpoint = 769;
  const [open, setOpen] = useState(false);
  return (
     
    <Container className="mcontainerpaddingnew learningmanage">
         <div id="aboutproject"></div>
      <Row>
        <Col md={6}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
            An integrated approach to multi-channel <br/>
              <span className="bold">
                Marketing campaigns
              </span>{" "}
             
            </h2>
          </ScrollAnimation>

          <AnimatedLine
            className="animateline"
            width={"150px"}
            margin={"-5px 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>

          <p className="mparagraphheadingtop">
            It was necessary for our client Adobe to create a monthly newsletter
            that informs customers and contacts of how their solutions make
            people and organizations more efficient, effective, and creative.
          </p>
          <span
            className={`d-lg-inline d-xl-inline ${
              open ? "d-inline" : "d-none"
            }`}
          >
            <p className="mparagraphheading">
              Our team designed and developed a responsive template around brand
              guidelines for the monthly Adobe newsletter. In designing the
              template, We went through its usual discovery and design process
              and then presented multiple concepts, making revisions to the
              selected concept.
            </p>
            <p className="mparagraphheading">
              Integrating powerful Adobe Experience Cloud and Creative Cloud
              technologies increased speed, efficiency and personalisation. Our
              Team used Adobe’s Reporting Engine to determine the success of the
              Email Campaign. Using the insights, we were able to draw a clear
              picture of the number of click-throughs tracked from the
              newsletter to the web page.
            </p>
          </span>
          <button
            className="readmore_btn d-lg-none d-xl-none mt-0 mb-2 pl-0"
            onClick={() => setOpen(!open)}
          >
             {open ? (
              ""
            ) : (
              <>
                <span className="expand readmore">
                  Read More <FontAwesomeIcon icon="angle-double-right" />
                </span>
              </>
            )}
          </button>
        </Col>
        <Col md={6}>
          <Row>
            <Col>
              <img
                src={image3}
                className="mgridimg1"
                alt=""
                style={{ width: "85%", margin: "0 auto", display: "block" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
