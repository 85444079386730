import React from "react";
// import { Header, Footer } from "../common";
import { Header, Footer } from "../common/index";


const Index = ({ children }) => {
  return (
    <React.Fragment>
      <Header />
      {children}
      <Footer />
    </React.Fragment>
  );
};

export default Index;
