import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import creativecloud from "../../../images/adobe/Creative_Cloud.svg";
import experience from "../../../images/adobe/kisspng-adobe-experience-manager-adobe-marketing-cloud-ado-5b0af96150a912.2795143615274458573304.png";
import blog from "../../../images/adobe/aa.png";
import thinktank from "../../../images/adobe/think.png";
import add from "../../../images/adobe/download.png";
import sensie from "../../../images/adobe/9ff7e0cee1348f3a7f9023f1c54ddac14952acd7.png";
import creative from "../../../images/adobe/jam.png";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AdobeCampaign = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [openlearning, setOpenlearning] = useState(false);
  return (
    <div style={{ backgroundColor: "#f8f8f8" }}>
      <Container className="emastercontainer-2 pt-3 mt-4">
        <Container>
          <Row>
            <Col md={12}>
              <ScrollAnimation
                animateIn="animate-text"
                duration={5}
                animateOnce={true}
              >
                <h2 className="h2heading1">
                  Campaign Management services to the following{" "}
                  <span className="bold">Teams at Adobe</span>
                </h2>
              </ScrollAnimation>

              <AnimatedLine
                className="animateline"
                width={"200px"}
                margin={"-5px 0"}
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                  className="no-animation"
                >
                  <div className="line"></div>
                </ScrollAnimation>
              </AnimatedLine>
            </Col>
            <Col md="4">
              <div className="adobecamp">
                <img src={creativecloud} alt="" />{" "}
                <span>Adobe Creative Cloud Exchange</span>
              </div>
            </Col>
            <Col md="4">
              <div className="adobecamp">
                <img src={experience} alt="" />{" "}
                <span>Adobe Experience Manager</span>
              </div>
            </Col>
            <Col md="4">
              <div className="adobecamp">
                <img src={blog} alt="" /> <span>The Blog</span>
              </div>
            </Col>
            <Collapse in={width < breakpoint ? openlearning : true}>
              <span
                id="collapse-learning"
                style={
                  width > breakpoint ? { display: "contents" } : { display: "" }
                }
              >
                <Col md="4">
                  <div className="adobecamp">
                    <img src={thinktank} alt="" /> <span>Adobe Think Tank</span>
                  </div>
                </Col>
                <Col md="4">
                  <div className="adobecamp">
                    <img src={add} alt="" /> <span>Adobe Document Cloud</span>
                  </div>
                </Col>
                <Col md="4">
                  <div className="adobecamp">
                    <img src={sensie} alt="" /> <span>Adobe Sensei</span>
                  </div>
                </Col>
                <Col md="4" className="d-xd-none d-lg-block"></Col>
                <Col md="4">
                  <div className="adobecamp">
                    <img src={creative} alt="" />{" "}
                    <span>Adobe Creative Jam</span>
                  </div>
                </Col>
              </span>
            </Collapse>
            <Col md="4">
              <button
                onClick={() => setOpenlearning(!openlearning)}
                aria-controls="collapse-learning"
                aria-expanded={openlearning}
                className="d-xl-none d-md-none d-xs-block collapse-btn text-center ml-0 pl-0"
              >
                {openlearning ? (
                  ""
                ) : (
                  <>
                    <span className="expand readmore">
                      Read More <FontAwesomeIcon icon="angle-double-right" />
                    </span>
                  </>
                )}
              </button>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};
