import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import image1 from "../../../images/learningmanage/lm4.webp";
import image2 from "../../../images/learningmanage/lm1.png";
import arrowimg from "../../../images/arrow.svg";
import image3 from "../../../images/learningmanage/lm3.png";

import image4 from "../../../images/learningmanage/lm2.webp";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import learninghrz from "../../../images/learningmanage/learning-hrz.webp";
import youngHrz from "../../../images/learningmanage/young-horz.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const LearningInnovative = () => {
  const { width } = useViewport();
  const breakpoint = 800;
  const [open, setOpen] = useState(false);

  return (
    <Container className="mcontainerpaddingnew learningmanage">
      <Row>
        <Col lg={7} md={12} sm={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h3 className="subheader">
              Transform your company’s learning experience
            </h3>
            <h2 className="h2heading1">
              <span className="bold">Train your team for success</span>
            </h2>
          </ScrollAnimation>
          <AnimatedLine
            className="animateline"
            width={"250px"}
            margin={"-5px 0 5px 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
          <p className="mparagraphheadingtop">
            Utilizing our user-centric technology and industry-leading
            expertise, we focus on delivering impactful training to employees,
            business partners, and customers.
            <span
              className={`d-lg-inline d-xl-inline ${
                open ? "d-inline" : "d-none"
              }`}
            >
              &nbsp;Provide your remote teams with the powerful LMS tools they
              need to make continuous learning a company-wide experience.
            </span>
          </p>
          <span
            className={`d-lg-inline d-xl-inline ${
              open ? "d-inline" : "d-none"
            }`}
          >
            <ul className="homelist">
              <li>
                <p>
                  Empower different teams on different levels with our learning
                  structure.
                </p>
              </li>
              <li>
                <p>
                  Train and onboard your employees with remote tools that are
                  easily accessible.
                </p>
              </li>
              <li>
                <p>
                  Engage your audience with PowerPoint slides, videos, quizzes,
                  and more.
                </p>
              </li>
              <li>
                <p>
                  Embrace a remote learning platform that is accessible from
                  anywhere.
                </p>
              </li>
              <li>
                <p>
                  Create an interactive learning experience with our training
                  modules.
                </p>
              </li>
              <li>
                <p>
                  Ensure that learners’ engagement and performance are
                  monitored.
                </p>
              </li>
            </ul>
          </span>
          <button
            className="readmore_btn d-lg-none d-xl-none pl-0"
            onClick={() => setOpen(!open)}
          >
            {open ? (
                  ""
                ) : (
                  <>
                    <span className="expand readmore">
                      Read More <FontAwesomeIcon icon="angle-double-right" />
                    </span>
                  </>
                )}
          </button>
          <div className="d-lg-none d-xl-none d-md-none learning-mid-img mt-4">
            <img alt="" src={youngHrz} className="mgridimg1" />
          </div>
          <p className="mparagraphheading">
            The amalgamation of gamification, personalized learning pathways and
            progress indicators ensure maximum knowledge retention and
            engagement of your team.{" "}
            <span className={`d-xl-inline d-none `}>
              In the sales arena, Our Cloud-based training platform help
              increase the team’s expertise in Fast-track training, Product
              training, Practise training and more. Our team also helps you
              leverage reporting by deriving insights about learner progression,
              completion rates, learning performance and course engagement.
            </span>
          </p>
          <div className="d-lg-none d-xl-none d-md-none learning-mid-img mt-4">
            <img alt="" src={learninghrz} className="mgridimg1" />
          </div>

          <br />
        </Col>
        <Col lg={5} md={12} sm={12} className="d-none d-md-block mt-2">
          <Row>
            <Col md={6} className="columntop">
              <div>
                <img alt="" src={image1} className="mgridimg1" />
              </div>
              <div>
                <img alt="" src={image2} className="mgridimg2" />
              </div>
            </Col>
            <Col md={6} className="mt-5">
              <div>
                <img alt="" src={image3} className="mgridimg1" />
              </div>
              <br />
              <div>
                <img alt="" src={image4} className="mgridimg1" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
