import { Col, Collapse, Container, Row } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import integration from "../../../images/uiux-deliver/undraw_Design_sprint_re_tke3.svg";
import ScrollAnimation from "react-animate-on-scroll";
import bluearrow from "../../../images/bluearrow.png";
import { useViewport } from "../../common/designParts/useViewport";
import { useState } from "react";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const UiUxCapability = () => {
    const { width } = useViewport();
    const breakpoint = 992;
    const [openlearning, setOpenlearning] = useState(false);
    return (
      <div style={{background:"#fff"}} >
         <Container className="mcontainerpadding mobile-space-inc">
          <div id="cloudappsolutions"></div>
          <Row>
            <Col lg={7} className="d-none d-lg-block"></Col>
            <Col lg={5} md={12}>
              <ScrollAnimation
                animateIn="animate-text"
                duration={5}
                animateOnce={true}
              >
                <h2 className="h2heading1">UI/UX <span className="bold">Capabilities</span></h2>
              </ScrollAnimation>
  
              <AnimatedLine
                className="animateline"
                width={"150px"}
                margin={"0px 0 25px 0"}
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                  className="no-animation"
                >
                  <div className="line"></div>
                </ScrollAnimation>
              </AnimatedLine>
            </Col>
          </Row>
          <Row className="rowpadding portal-cap-p mobile-space-inc">
            <Col lg={7} md={12} className="textcenter">
              <ScrollAnimation
                animateIn="fade-image-right"
                duration={2}
                animateOnce={true}
              >
                <img src={integration} alt="" className="learning-cap-img" />
              </ScrollAnimation>
            </Col>
            <Col md={12} lg={5} style={{ marginTop: "-50px" }} id="capability-list">
              <ScrollAnimation
                animateIn="animate-text"
                duration={5}
                animateOnce={true}
                
              >
                <ul className="portal-capable-content">
                  <li>UI/UX Research</li>
                  <li>Responsive Design</li>
                  <li>Interaction Design</li>
                  <Collapse in={width < breakpoint ? openlearning : true}>
                    <div id="collapse-learning">
                      <li>User Stories and Journey</li>
                      <li>Painpoint Analysis</li>
                      <li>UI/UX Consulting</li>
                      <li>Usability Testing</li>
                      <li>Agile Front-end Development</li>
                      <li>UX Audit Report</li>
                      <li>Product Design</li>
                    </div>
                  </Collapse>
                </ul>
                <button
                  onClick={() => setOpenlearning(!openlearning)}
                  aria-controls="collapse-learning"
                  aria-expanded={openlearning}
                  className="d-xl-none d-lg-none collapse-btn text-center ml-0 pl-0"
                >
                {openlearning ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Read More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
                </button>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </div>
  );
};
