import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "react-bootstrap";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import gm from "../../../images/xtime/GM-logo.png";
import subaru from "../../../images/xtime/subaru.png";
import nissan from "../../../images/xtime/Nissan_logo.png";
import bmw from "../../../images/xtime/bmw.png";
import xtimeImage from "../../../images/xtime/section1-img.png";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const XtimeAbout = () => {
  const { width } = useViewport();
  const breakpoint = 769;
  const [open, setOpen] = useState(false);
  return (
    <Container className="mcontainerpaddingnew learningmanage" id="about">
      <Row className="mt-3">
        <Col lg={7} md={12} sm={12} className="mb-5">
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1 ">
              <span className="bold">Seamless Mobile Transformation</span> With
              <br /> Cloud Migration
            </h2>
          </ScrollAnimation>

          <AnimatedLine
            className="animateline"
            width={"200px"}
            margin={"-5px 0"}
          >
            <ScrollAnimation
              animateIn="fadeInLeft"
              duration={2}
              animateOnce={true}
              className="no-animation"
            >
              <div className="line"></div>
            </ScrollAnimation>
          </AnimatedLine>
          <p className="mparagraphheadingtop">
            Adobe needed to promote Adobe Sensei, an innovative product that
            uses artificial intelligence (AI) and machine learning, while
            reinforcing Adobe’s role as a thought leader in AI.
          </p>

          <div className="xtime-about-images">
            <img src={gm} alt="" />
            <img src={subaru} alt="" />
            <img src={nissan} alt="" />
            <img src={bmw} alt="" />
            <span className="d-none d-md-inline">and More....</span>
          </div>
          <span
            className={`d-lg-inline d-xl-inline ${
              open ? "d-inline" : "d-none"
            }`}
          >
            <p className="mparagraphheading">
              The client is also a leading cloud-based Customer Relationship
              Management (CRM) and scheduling platform for automotive
              manufacturers and automotive service departments. As the
              industry’s only product platform designed specifically to help
              dealers transform service operations, Xtime has consistently
              demonstrated its capability to boost customer satisfaction and
              revenue.
            </p>
          </span>
          <button
            className="readmore_btn d-lg-none d-xl-none mt-3 pl-0"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              ""
            ) : (
              <>
                <span className="expand readmore">
                  Read More <FontAwesomeIcon icon="angle-double-right" />
                </span>
              </>
            )}
          </button>
        </Col>
        <Col lg={5} md={12} sm={12} className="mt-2">
          <div className="adobe-about-right">
            <img src={xtimeImage} alt="" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
