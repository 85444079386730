import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import xtimeImage from "../../../images/xtime/xtime-spectrum-300px.png";
import border from "../../../images/xtime/border.png";
import invite from "../../../images/xtime/invite-2.png";
import schedule from "../../../images/xtime/schedule-1.png";
import engage from "../../../images/xtime/engage-1.png";
import inspect from "../../../images/xtime/inspect-2.png";
import xt_mobile from "../../../images/xtime/xt-mobile.png";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const XtimeProducts = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [openWeb, setOpenWeb] = useState(false);
  return (
    <div className="xtime-grey-bg mt-4 pb-3">
      <Container className="xtimeAbout pt-3 pb-5 ">
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1 ">
                Our Services to the following{" "}
                <span className="bold">Products at Xtime</span>
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"200px"}
              margin={"-5px 0 25px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
          <Col className="d-block d-md-none">
            <div className="xtime-mobile-products">
              {/* <img src={xt_mobile} alt=""></img> */}
              <img src={xtimeImage} alt="" />
              <img src={border} alt="" />
            </div>
            <Row className="xtime-product-dtl">
              <Col xs={3} className="pl-0">
                <div>
                  <img src={invite} alt="" style={{ width: "100%" }} />
                </div>
                <h5>INVITE</h5>
              </Col>
              <Col xs={3} className="pl-0">
                <div>
                  <img src={schedule} alt="" style={{ width: "100%" }} />
                </div>
                <h5>SCHEDULE</h5>
              </Col>
              <Col xs={3} className="pl-0">
                <div>
                  <img src={engage} alt="" style={{ width: "100%" }} />
                </div>
                <h5>ENGAGE</h5>
              </Col>
              <Col xs={3} className="pl-0">
                <div>
                  <img src={inspect} alt="" style={{ width: "100%" }} />
                </div>
                <h5>INSPECT</h5>
              </Col>
              <Col xs={12} className="xtime-productlist-mobile">
                <h4>Invite</h4>
                <p>Cardboard </p>
                <p>Notifications</p>
                <p>Service Reminders</p>
              </Col>
              <Col xs={12} className="xtime-productlist-mobile">
                <h4>Schedule</h4>
                <p>Online Scheduling </p>
                <p>Dealer Scheduling</p>
                <p>Call Center Scheduling</p>
                <p>Menu pricing</p>
                <p>Ride Sharing</p>
              </Col>
              <Collapse in={width < breakpoint ? openWeb === true : false}>
                <div id="hide">
                  <Col xs={12} className="xtime-productlist-mobile">
                    <h4>Engage</h4>
                    <p>Tablet Reception</p>
                    <p>Texting</p>
                    <p>Walk-around</p>
                    <p>Vehicle Check-in</p>
                    <p>Add-ons Detailing</p>
                    <p>Diagonistics</p>
                  </Col>
                  <Col xs={12} className="xtime-productlist-mobile">
                    <h4>Inspect</h4>
                    <p>Inspection Dashboard</p>
                    <p>Dealership Chat</p>
                    <p>Multi-point Inspection</p>
                    <p>Enhanced Multimedia</p>
                    <p>Online Approvals</p>
                    <p>Service Tracker</p>
                  </Col>
                </div>
              </Collapse>
           
            </Row>
            <button
                onClick={() => setOpenWeb(!openWeb)}
                aria-controls="collapse-services-learning"
                aria-expanded={openWeb}
                className="d-xl-none collapse-btn text-center mr-auto ml-auto pt-2"
              >
               {openWeb ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Expand More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
              </button>
          </Col>
          <Col className="d-none d-md-block">
            <div className="xtime-products">
              <img src={xtimeImage} alt="" />
              <img src={border} alt="" />
            </div>
            <Row className="xtime-product-dtl">
              <Col md={3}>
                <div>
                  <img src={invite} alt="" />
                </div>
                <h5>INVITE</h5>

                <ul>
                  <li>Cardboard</li>
                  <li>Notifications</li>
                  <li>Service Reminders</li>
                </ul>
              </Col>
              <Col md={3}>
                <div>
                  <img src={schedule} alt="" />
                </div>
                <h5>SCHEDULE</h5>

                <ul>
                  <li>Online Scheduling</li>
                  <li>Mobile Scheduling</li>
                  <li>Dealer Scheduling</li>
                  <li>Call Center Scheduling</li>
                  <li>Menu pricing</li>
                  <li>Ride Sharing</li>
                </ul>
              </Col>
              <Col md={3}>
                <div>
                  <img src={engage} alt="" />
                </div>
                <h5>ENGAGE</h5>

                <ul>
                  <li>Tablet Reception</li>
                  <li>Texting</li>
                  <li>Walk-around</li>
                  <li>Vehicle Check-in</li>
                  <li>Add-ons Detailing</li>
                  <li>Diagonistics</li>
                </ul>
              </Col>
              <Col md={3}>
                <div>
                  <img src={inspect} alt="" />
                </div>
                <h5>INSPECT</h5>

                <ul>
                  <li>Inspection Dashboard</li>
                  <li>Dealership Chat</li>
                  <li>Multi-point Inspection</li>
                  <li>Enhanced Multimedia</li>
                  <li>Online Approvals</li>
                  <li>Service Tracker</li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
