import React, { PureComponent } from "react";
import Layout from "../../components/layout";
import "react-multi-carousel/lib/styles.css";
import "../../pages/mobiledevelopment/mobiledevelopment.css";
import "../../pages/mobiledevelopment/mobiledevelopment.scss";
import {
  ProjectEmasterCarousel,
  EmastersNav,
  EmastersAbout,
  EmasterRelation,
  EmasterApp,
  EmasterFlow,
  EmasterWireframe,
  EmasterScreens, EmasterBranding, EmasterTechnology
} from "../../components/section";
import "./emasters.css";

class Emasters extends PureComponent {
  render() {
    return (
      <Layout>
        <ProjectEmasterCarousel />
        <EmastersNav />
        <EmastersAbout />
        <EmasterRelation />
        <EmasterApp />
        <EmasterFlow />
        <EmasterWireframe />
        <EmasterScreens />
        <EmasterBranding/>
       <EmasterTechnology/>
      </Layout>
    );
  }
}

export default Emasters;
