import React, { useState } from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Col, Row, Container, Form } from "react-bootstrap";

export const ContactSection = () => {
  const form = useRef();

  const [isActive, setIsActive] = useState(false);
  const [value,setValue] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname,setLastName] = useState("");
  const [companyname,setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [message,setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_8da4e4s",
        "template_3daf139",
        form.current,
        "25RRyuuE973lwkLC-"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("message sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  
  function handleTextChange(text) {
    setValue(text);

    if (text !== "") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <p className="contact-intro">
              We appreciate your interest in JMT Worldwide. To help us better
              understand your business needs, please get in touch with us.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={5} className="d-none d-md-block">
            <div class="timeline-contact block mt-5 mb-5">
              <div class="tl-item">
                <div class="tl-dot b-warning"></div>
                <div class="tl-content">
                  <div class="tl-heading">We’ll prepare a proposal</div>
                  <div class="tl-content mt-1">
                    Required scope, timeline and price will be included if you
                    provide us with detail information about a project
                  </div>
                </div>
              </div>
              <div class="tl-item">
                <div class="tl-dot tl-dot-2 b-primary"></div>
                <div class="tl-content">
                  <div class="tl-heading">Together we discuss it</div>
                  <div class="tl-content mt-1">
                    Let’s get acquainted and discuss all the possible variants
                    and options in person or virtually
                  </div>
                </div>
              </div>
              <div class="tl-item">
                <div class="tl-dot tl-dot-3 b-danger"></div>
                <div class="tl-content">
                  <div class="tl-heading">Let’s start building</div>
                  <div class="tl-content mt-1">
                    When the contract is signed, and all goals are set, we can
                    start the first sprint
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={7} xs={12} className="contact-padding">
            <div className="contact-form">
              <h2>Let’s Start a Conversation</h2>
              <Form ref={form} onSubmit={sendEmail}>
                <Row>
                  <Col  xs={6} sm={6}>
                    <div id="float-label">
                      <input
                        type="text"
                        
                        //onChange={(e) => handleTextChange(e.target.value)}
                        name="first_name"
                      />

                      <label
                        className={isActive === "firstname" ? "Active" : ""}
                        htmlFor="firstname"
                      >
                        First Name
                      </label>
                    </div>
                  </Col>
                  <Col xs={6} sm={6}>
                  <div id="float-label">
                      <input
                        type="text"
                       name="last_name"
                      />

                      <label
                        className={isActive === "lastname" ? "Active" : ""}
                        htmlFor="lastname"
                      >
                        Last Name
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                  <div id="float-label">
                      <input
                        type="text"
                        name="company_name"
                        // onChange={(e) => handleTextChange(e.target.value)}
                      />

                      <label
                        className={isActive === "companyname" ? "Active" : ""}
                        htmlFor="companyname"
                      >
                        Company Name
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                  <div id="float-label">
                      <input
                        type="email"
                        name="lead_email"
                        // onChange={(e) => handleTextChange(e.target.value)}
                      />

                      <label
                        className={isActive === "email" ? "Active" : ""}
                        htmlFor="email"
                      >
                        Email
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                  <div id="float-label">
                      <textarea
                        rows={3}
                        name="message"
                        // onChange={(e) => handleTextChange(e.target.value)}
                      />

                      <label
                        className={isActive === "message" ? "Active" : ""}
                        htmlFor="message"
                      >
                        Message
                      </label>
                    </div>
                    
                  </Col>
                </Row>
                <Row>
                  
                  <input type="submit" className="button" value="SEND" />
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
