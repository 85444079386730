import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import rightcircle from "../../../images/rightcircle.png";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import airplane from "../../../images/airplane.png";
import mobdev from "../../../images/mob_development.png"

export const MobileProcess = () => {
  const [click, setClick] = useState("designproto");
  const { width } = useViewport();
  const breakpoint = 992;
  return (
    <div>
      {" "}
      <div id="process"></div>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                Our  <span className="bold">Mobile Development Process Steps</span>
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"200px"}
              margin={"0px 0"}
              
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
      
        {width < breakpoint ? (
          <Row>
          <Col className="mb-3">
          <img src={mobdev} alt="" style={{width:"100%"}}/>
          </Col>
          </Row>
        ) : (
          <Row>
            <Col md={8} sm={12} xs={12} style={{marginBottom:"19em"}}>
              <section className="ps-timeline-sec" style={{margin:"40% 0"}}>
                <div className="container">
                  <ol
                    className="ps-timeline"
                   style={{margin:"300px 0"}}
                  >
                    <li>
                      <div class="img-handler-bot">
                        <div
                          className="bluediv"
                          style={
                            click === "strategy"
                              ? { display: "none" }
                              : { display: "block" }
                          }
                          onClick={() => setClick("strategy")}
                        >
                          <p className="bluep">
                            Strategy & <br /> Consultation
                          </p>
                        </div>
                        {/* content detail */}
                        <div
                          className="imagediv"
                          style={
                            click === "strategy"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <div class="steptext-over-img">
                            <p className="steph">
                               <b>Strategy & Consultation</b>
                            </p>
                            <p className="stepp">
                              The initiation phase encompasses all the steps you
                              must take before a project is approved and any
                              planning begins.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="ps-top">
                        <h3 className="blueh">01</h3>
                      </div>
                      <span class="ps-sp-bot"></span>
                    </li>
                    <li>
                      <div class="img-handler-top">
                        <div
                          className="bluediv"
                          style={
                            click === "designproto"
                              ? { display: "none" }
                              : { display: "block" }
                          }
                          onClick={() => setClick("designproto")}
                        >
                          <p className="bluep">
                            Design & <br /> Prototype
                          </p>
                        </div>

                        <div
                          className="imagediv"
                          style={
                            click === "designproto"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <div class="steptext-over-img">
                            <p className="steph">
                               <b>Design & Prototype</b>
                            </p>
                            <p className="stepp">
                              The initiation phase encompasses all the steps you
                              must take before a project is approved and any
                              planning begins.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="ps-bot">
                        <h3 className="blueh">02</h3>
                      </div>
                      <span class="ps-sp-top"></span>
                    </li>

                    <li>
                      <div class="img-handler-bot">
                        <div
                          className="bluediv"
                          style={
                            click === "development"
                              ? { display: "none" }
                              : { display: "block" }
                          }
                          onClick={() => setClick("development")}
                        >
                          <p className="bluep">Development & Upgrade</p>
                        </div>
                        <div
                          className="imagediv"
                          style={
                            click === "development"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <div class="steptext-over-img">
                            <p className="steph">
                               <b>Development & Upgrade</b>
                            </p>
                            <p className="stepp">
                              The initiation phase encompasses all the steps you
                              must take before a project is approved and any
                              planning begins.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="ps-top">
                        <h3 className="blueh">03</h3>
                      </div>
                      <span class="ps-sp-bot"></span>
                    </li>

                    <li>
                      <div class="img-handler-top">
                        <div
                          className="bluedivnew"
                          style={
                            click === "deployment"
                              ? { display: "none" }
                              : { display: "block" }
                          }
                          onClick={() => setClick("deployment")}
                        >
                          <p className="bluep">Deployment & Publishing</p>
                        </div>
                        <div
                          className="imagediv"
                          style={
                            click === "deployment"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <div class="steptext-over-img">
                            <p className="steph">
                               <b>Deployment & Publishing</b>
                            </p>
                            <p className="stepp">
                              The initiation phase encompasses all the steps you
                              must take before a project is approved and any
                              planning begins.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="ps-bot">
                        <h3 className="blueh">04</h3>
                      </div>
                      <span class="ps-sp-top"></span>
                    </li>
                    <li style={{ borderTop: "none" }}>
                      <div class="img-handler-bot">
                        <div
                          className="bluediv"
                          style={
                            click === "support"
                              ? { display: "none" }
                              : { display: "block" }
                          }
                          onClick={() => setClick("support")}
                        >
                          <p className="bluep">
                            Support & <br /> Maintenance
                          </p>
                        </div>
                        <div
                          className="imagediv"
                          style={
                            click === "support"
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <div class="steptext-over-img">
                            <p className="steph">Support & Maintenance</p>
                            <p className="stepp">
                              The initiation phase encompasses all the steps you
                              must take before a project is approved and any
                              planning begins.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="ps-top">
                        <h3 className="blueh">05</h3>
                      </div>
                      <span class="ps-sp-bot"></span>
                    </li>
                  </ol>
                </div>
              </section>
            </Col>
            <Col md={1} className=" pl-0 d-none d-md-block">
                {/* <img src={airplane} alt="maintainance" /> */}
            </Col>
            <Col md={3} className="initiancolumn mobile-pro-card d-sm-none d-md-block d-xs-none">
              <div
                className="card initiancard"
                style={
                  click === "strategy"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                         <b>01</b>
                      </span>
                      <span className="inip">Strategy & Consultation</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Kickoff the Proceedings of the Feasibility Study.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Adapt the process to the new Project.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Establish the new project Charter.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Final Review of the Requirement Document.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* 2 */}
              <div
                className="card initiancard"
                style={
                  click === "designproto"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                         <b>02</b>
                      </span>
                      <span className="inip">Design & Prototype</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Kickoff the Proceedings of the Feasibility Study.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Adapt the process to the new Project.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Establish the new project Charter.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Final Review of the Requirement Document.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* 3 */}
              <div
                className="card initiancard"
                style={
                  click === "development"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                         <b>03</b>
                      </span>
                      <span className="inip">Development & Upgrade</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Kickoff the Proceedings of the Feasibility Study.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Adapt the process to the new Project.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Establish the new project Charter.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Final Review of the Requirement Document.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* 4 */}
              <div
                className="card initiancard"
                style={
                  click === "deployment"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                         <b>04</b>
                      </span>
                      <span className="inip">Deployment & Publishing</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Kickoff the Proceedings of the Feasibility Study.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Adapt the process to the new Project.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Establish the new project Charter.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Final Review of the Requirement Document.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* 5 */}
              <div
                className="card initiancard"
                style={
                  click === "support"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                         <b>05</b>
                      </span>
                      <span className="inip">Support & Maintenance</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Kickoff the Proceedings of the Feasibility Study.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Adapt the process to the new Project.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Establish the new project Charter.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10}>
                    <p className="inicontent">
                      Final Review of the Requirement Document.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};
