import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export const DigiInfo = () => {
  return (
    <div>
      <div className="learningbgInfo digibg">
        <ScrollAnimation
          animateIn="fadeInRight"
          duration={2}
          animateOnce={true}
        >
          <p className="">
            By evaluating your current capabilities, we help you develop
            optimized digital solutions that will yield tangible outcomes for
            your business and establish business agility.
          </p>
        </ScrollAnimation>
      </div>
    </div>
  );
};
