import React, { PureComponent } from "react";
import Layout from "../../components/layout";
import "react-multi-carousel/lib/styles.css";
import "../../components/section/cloudProcess/cloudprocess.css";
import "../../pages/cloudservices/cloudServices.css";
import "../digitaltranformation/digitaltransformation.css";
import "./campaign.css";
import {
 
  CampaignCarousel,
  CampaignNav,
  CampaignInnovative,
  CampaignServices,
  CampaignInfo,
  CampaignCapability,
  CampaignExpertise,
  CampaignStory, CampaignProcess
} from "../../components/section";

class CampaignManagement extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
  render() {
    return (
      <Layout>
        <CampaignCarousel />
        <CampaignNav />
        <CampaignInnovative />
        <CampaignServices />
        <CampaignInfo />
        <CampaignCapability />
        <CampaignExpertise />
        <CampaignStory />
        <CampaignProcess />
      </Layout>
    );
  }
}

export default CampaignManagement;
