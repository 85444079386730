import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export const WebInfo = () => {
  return (
    <div>
      <div className=" webbg learningbgInfo">
        <ScrollAnimation
          animateIn="fadeInRight"
          duration={2}
          animateOnce={true}
        >
          <p className="">
            Our business transformation services help achieve tangible outcomes
            for your business and establish business agility through optimized
            solutions based on evaluation of your current capabilities.
          </p>
        </ScrollAnimation>
      </div>
    </div>
  );
};
