import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";

import plat1 from "../../../images/icons/kotlin.png";
import plat2 from "../../../images/icons/php.png";

import plat3 from "../../../images/icons/java.png";

import plat4 from "../../../images/icons/aws.png";

import plat5 from "../../../images/icons/swift.png";

import plat6 from "../../../images/icons/joomla.png";
import plat7 from "../../../images/icons/html.png";
import plat8 from "../../../images/icons/css.png";
import plat9 from "../../../images/icons/4-2.png";
import plat10 from "../../../images/icons/js.png";
import plat11 from "../../../images/icons/react.png";
import plat12 from "../../../images/icons/vue.png"
import icon1 from "../../../images/digitaltrans/devices.png";
import icon2 from "../../../images/digitaltrans/icons8-code-80.png";

import "react-multi-carousel/lib/styles.css";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";

export const LearningExpertise = () => {
  return (
    <Container className="mcontainerpadding " id="learningexpertise">
      <Row>
        <Col md={12}>
          <ScrollAnimation
            animateIn="animate-text"
            duration={5}
            animateOnce={true}
          >
            <h2 className="h2heading1">
              Our Development <span className="bold">Platforms & Frameworks Expertise</span>
            </h2>
          </ScrollAnimation>

          <AnimatedLine
              className="animateline"
              width={"250px"}
              margin={"0 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          <br />
          <br/>
          <br/>
        </Col>
      </Row>
    
      {/*  */}
      <Row>
     
     <Col md={12} style={{ padding: "0",margin:"0 0 2% 0" }}>
     <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
      

       <div className="campaignExpertise learningExpertise">
       <div className="portal-exp-icon learning-exp">
         <img src={icon1} alt="#" />
       </div>
         <Row >
             <Col>
           <h4>Front-End Development</h4>
           <AnimatedLine
           className="animateline"
           width={"150px"}
           margin={"15px auto"}
         >
           <ScrollAnimation
             animateIn="fadeInLeft"
             duration={2}
             animateOnce={true}
             className="no-animation"
           >
             <div className="line"></div>
           </ScrollAnimation>
         </AnimatedLine>
         </Col>
         </Row>

         <Row className="pt-3">
           <Col lg={2} md={2} sm={4} xs={4}>
             <div className="exp-img">
               <div>
                 <img src={plat7} alt="icon" />
               </div>
               <p>HTML 5</p>
             </div>
           </Col>
           <Col lg={2} md={2} sm={4} xs={4}>
             <div className="exp-img">
               <div>
                 <img src={plat8} alt="icon" />
               </div>
               <p>CSS 3</p>
             </div>
           </Col>
           <Col lg={2} md={2} sm={4} xs={4}>
             <div className="exp-img">
               <div>
                 <img src={plat9} alt="icon" />
               </div>
               <p>Angular</p>
             </div>
           </Col>
         
         
           <Col lg={2} md={2} sm={4} xs={4}>
             <div className="exp-img">
               <div>
                 <img src={plat10} alt="icon" />
               </div>
               <p >Javascript</p>
             </div>
           </Col>
           <Col lg={2} md={2} sm={4} xs={4}>
             <div className="exp-img">
               <div>
                 <img src={plat11} alt="icon" />
               </div>
               <p>ReactJS</p>
             </div>
           </Col>

           <Col lg={2} md={2} sm={4} xs={4}>
             <div className="exp-img">
               <div>
                 <img src={plat12} alt="icon" />
               </div>
               <p>VueJS</p>
             </div>
           </Col>
         </Row>
       </div>
       </ScrollAnimation>
     </Col>
   
    
   </Row>
      {/*  */}
      <Row style={{marginTop:"5rem"}}>
     
        <Col md={12} style={{ padding: "0",margin:"0 0 2% 0" }}>
        <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
         

          <div className="campaignExpertise learningExpertise">
          <div className="portal-exp-icon learning-exp">
            <img src={icon2} alt="#" />
          </div>
            <Row>
                <Col>
              <h4>Back-End Development</h4>
              <AnimatedLine
              className="animateline"
              width={"150px"}
              margin={"15px auto"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
            </Col>
            </Row>

            <Row className="pt-3">
              <Col lg={2} md={2} sm={4} xs={4}>
                <div className="exp-img">
                  <div>
                    <img src={plat1} alt="icon" />
                  </div>
                  <p>Kotlin</p>
                </div>
              </Col>
              <Col lg={2} md={2} sm={4} xs={4}>
                <div className="exp-img">
                  <div>
                    <img src={plat2} alt="icon" />
                  </div>
                  <p>PHP</p>
                </div>
              </Col>
              <Col lg={2} md={2} sm={4} xs={4}>
                <div className="exp-img">
                  <div>
                    <img src={plat3} alt="icon" />
                  </div>
                  <p>Java</p>
                </div>
              </Col>
            
            
              <Col lg={2} md={2} sm={4} xs={4}>
                <div className="exp-img">
                  <div>
                    <img src={plat4} alt="icon" />
                  </div>
                  <p >Amazon Web Services</p>
                </div>
              </Col>
              <Col lg={2} md={2} sm={4} xs={4}>
                <div className="exp-img">
                  <div>
                    <img src={plat5} alt="icon" />
                  </div>
                  <p>Swift</p>
                </div>
              </Col>

              <Col lg={2} md={2} sm={4} xs={4}>
                <div className="exp-img">
                  <div>
                    <img src={plat6} alt="icon" />
                  </div>
                  <p>Joomla</p>
                </div>
              </Col>
            </Row>
          </div>
          </ScrollAnimation>
        </Col>
      
       
      </Row>
    </Container>
  );
};
