import React, { PureComponent } from "react";
import Layout from "../../components/layout";
import "react-multi-carousel/lib/styles.css";
import '../../pages/mobiledevelopment/mobiledevelopment.css'
import '../../pages/mobiledevelopment/mobiledevelopment.scss'
import {
    ContactCarousel, ContactSection
   
  } from "../../components/section";
import "./contact.css";  



class ContactUs extends PureComponent {
    render() {
        return (
            <Layout>
                <ContactCarousel />
                <ContactSection/>
               
            </Layout>
        );
    }
}

export default ContactUs;
