import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Image,
  Container,
  Collapse,
  Button,
} from "react-bootstrap";
import logo from "../../../images/logo-jmt.png";
import * as IosIcons from "react-icons/io";
// import { HashLink as Link } from 'react-router-hash-link';
import "./style.scss";
import { Link } from "react-router-dom";
import menuIcon from "../../../images/menu-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Search } from "../../common/designParts/searchData";

export const Header = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [show]);

  const closeSearch = () =>{
      
      setSearchText("")
  }

  

  const handleSearch = (event) => {
      setSearchText(event.target.value)
      if(event.target.value !== "" || event.target.value >= 1){
    let value = event.target.value.toLowerCase();
    let result = [];
    console.log(value);
    result = Search.filter((data) => {
    return data.keyword.toLowerCase().search(value) != -1;
    });
    setFilteredData(result);
}
else{
    setFilteredData([])
}
    }
  return (
    <Navbar expand="lg" className="pt-0 pb-0">
      <Container fluid>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand href="/">
          <Image src={logo} />
        </Navbar.Brand>
        <Nav.Link
              eventKey={2}
              href="#search"
              className="d-block d-lg-none "
              style={{ position: "relative" }}
              onClick={() => setSearch(!search)}
            >
              <IosIcons.IoIosSearch className="searchicon" />
            </Nav.Link>
       

        <Navbar.Collapse>
          <Nav className="mr-auto">
            <Link to={"/home"} className="nav-link">
              Home
            </Link>
            <Link
              onClick={() => setOpen(!open)}
              aria-controls="dropdown-navigation"
              aria-expanded={open}
              id="collasible-nav-dropdown"
              className="nav-link d-block d-lg-none"
            >
              Services <FontAwesomeIcon icon="chevron-down"></FontAwesomeIcon>
            </Link>
            <Collapse in={open}>
              <div id="dropdown-navigation" className="dropdown-navi">
                <Link to={"/campaign-management"} className="nav-link">
                  Campaign Management
                </Link>

                <Link to={"/cloud-services"} className="nav-link">
                  Cloud Services
                </Link>

                <Link to={"/digital-transformation"} className="nav-link">
                  Digital Transformation
                </Link>

                <Link to={"/learning-management-system"} className="nav-link">
                  Learning Management System
                </Link>

                <Link to={"/mobile-development"} className="nav-link">
                  Mobile Development
                </Link>

                <Link to={"/portal-collaboration"} className="nav-link">
                  Portal & Collaboration
                </Link>

                <Link to={"/qatesting"} className="nav-link">
                  QA Testing
                </Link>

                <Link to={"/ui-ux-services"} className="nav-link">
                  UI/UX Services
                </Link>

                <Link to={"/web-development"} className="nav-link">
                  Web Development
                </Link>

                <Link to={"/workflow-automation"} className="nav-link">
                  Workflow Automation
                </Link>
              </div>
            </Collapse>

            <NavDropdown
              title="Services"
              id="collasible-nav-dropdown"
              className="d-none d-lg-block"
            >
              <NavDropdown.Item className="main-nav">
                <Link to={"/campaign-management"} className="nav-link">
                  Campaign Management
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item className="main-nav">
                <Link to={"/cloud-services"} className="nav-link">
                  Cloud Services
                </Link>
              </NavDropdown.Item>

              <NavDropdown.Item className="main-nav">
                <Link to={"/digital-transformation"} className="nav-link">
                  Digital Transformation
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item className="main-nav">
                <Link to={"/learning-management-system"} className="nav-link">
                  Learning Management System
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item className="main-nav">
                <Link to={"/mobile-development"} className="nav-link">
                  Mobile Development
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item className="main-nav">
                <Link to={"/portal-collaboration"} className="nav-link">
                  Portal & Collaboration
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item className="main-nav">
                <Link to={"/qatesting"} className="nav-link">
                  QA Testing
                </Link>
              </NavDropdown.Item>

              <NavDropdown.Item className="main-nav">
                <Link to={"/ui-ux-services"} className="nav-link">
                  UI/UX Services
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item className="main-nav">
                <Link to={"/web-development"} className="nav-link">
                  Web Development
                </Link>
              </NavDropdown.Item>

              <NavDropdown.Item className="main-nav">
                <Link to={"/workflow-automation"} className="nav-link">
                  Workflow Automation
                </Link>
              </NavDropdown.Item>
            </NavDropdown>

            <Link to={"/portfolio"} className="nav-link">
              Portfolio
            </Link>
            <Link to={"/about-us"} className="nav-link">
              About Us
            </Link>
          </Nav>

          <Nav className="ml-auto">
            <Link to={"/contact-us"} className="nav-link">
              Contact Us
            </Link>
            <Nav.Link
              eventKey={2}
              href="#search"
              className="d-none d-lg-block "
              style={{ position: "relative" }}
              onClick={() => setSearch(!search)}
            >
              <IosIcons.IoIosSearch className="searchicon" />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        {search && (
          <>
            <div class="togglesearch">
              <input
                type="text"
                placeholder=""
                value={searchText}
                onChange={(e) => handleSearch(e)}
              />
              <button><FontAwesomeIcon icon="times" onClick={(e)=>closeSearch(e)}/></button>
            </div>

            <div className="searchresult">
              <ul>
              {filteredData.map((data,index)=>{
                  return(
                    <li>
                    <Link to={data.link}>{data.keyword}</Link>
                  </li>
                  )
              })}
              
              </ul>
            </div>
          </>
        )}
      </Container>
    </Navbar>
  );
};
