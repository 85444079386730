import { Col, Collapse, Container, Row } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import integration from "../../../images/learningmanage/distance-learning-e-learning-graduates-student-online-college-man-with-laptop-and-books-person-studying-in-digital-166157944.png";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export const LearningCapability = () => {
  const { width } = useViewport();
  const breakpoint = 992;
  const [openlearning, setOpenlearning] = useState(false);
  return (
    <div>
      <Container className="mcontainerpadding mobile-space-inc">
        <div id="cloudappsolutions"></div>
        <Row>
          <Col md={7} className="d-none d-lg-block"></Col>
          <Col md={5} sm={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">Our LMS <span className="bold">Capabilities</span></h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"150px"}
              margin={"-10px 0 10px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        <Row className="rowpadding portal-cap-p mobile-space-inc">
          <Col lg={7} md={12} className="textcenter">
            <ScrollAnimation
              animateIn="fade-image-right"
              duration={2}
              animateOnce={true}
            >
              <img src={integration} alt="" className="learning-cap-img" />
            </ScrollAnimation>
          </Col>
          <Col md={12} lg={5} style={{ marginTop: "-50px" }} id="capability-list">
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <ul className="portal-capable-content">
                <li>Creating a custom Learning Management System Product</li>
                <li>Reliable and secure product</li>
                <li>Faster User On-boarding</li>
                <Collapse in={width < breakpoint ? openlearning : true}>
                  <div id="collapse-learning">
                    <li>Innovative instructional design</li>
                    <li>Long-term saving</li>
                    <li>
                      Learning experiences that integrate social interaction
                    </li>
                    <li>Upgrade your product information easily</li>
                    <li>Centralized learning</li>
                    <li>Cost-effective learning and development</li>
                    <li>Evaluation of capabilities in real time</li>
                  </div>
                </Collapse>
              </ul>
              <button
                onClick={() => setOpenlearning(!openlearning)}
                aria-controls="collapse-learning"
                aria-expanded={openlearning}
                className="d-xl-none d-lg-none collapse-btn text-center ml-0 pl-0"
              >
                 {openlearning ? (
              ""
            ) : (
                <>
              <span className="expand readmore">
                Read More  <FontAwesomeIcon icon="angle-double-right" />
              </span>
             
              </>
            )}
              </button>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
