import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "react-bootstrap";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";

export const AboutusVision = () => {
  return (
    <div className="about-vision">
      <Container>
        <Row>
          <Col md={4}>
            <div className="vision-box">
              <h3>Our Core Values</h3>
              <AnimatedLine
                className="animateline"
                width={"100px"}
                margin={"0 0"}
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                  className="no-animation"
                >
                  <div className="line"></div>
                </ScrollAnimation>
              </AnimatedLine>
              <p>
                Be an implementation partner for critical business
                transformations.
              </p>
            </div>
          </Col>
          <Col md={5}>
          <div className="vision-box vision-box-2">
              <h3>Our Vision</h3>
              <AnimatedLine
                className="animateline"
                width={"100px"}
                margin={"0 0"}
              >
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={2}
                  animateOnce={true}
                  className="no-animation"
                >
                  <div className="line"></div>
                </ScrollAnimation>
              </AnimatedLine>
              <p>
              Strive to identify and deliver on opportunities to organize, innovate, automate and most importantly “Connect” !!
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
