import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import image1 from "../../../images/uiux-deliver/pexels-christina-morillo-1181346.png";
import image2 from "../../../images/uiux-deliver/web-designer-drawing-website-development-wireframe-68755251.png";
import image3 from "../../../images/uiux-deliver/pp.png";
import image4 from "../../../images/uiux-deliver/young-female-entrepreneur-working-in-a-home-office-58215150.png";
import arrowimg from "../../../images/arrow.svg";
import { useViewport } from "../../common/designParts/useViewport";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import imagemob1 from "../../../images/uiux-deliver/ui1.png";
import imagemob2 from "../../../images/uiux-deliver/ui2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const UiUxInnovative = () => {
  const { width } = useViewport();
  const breakpoint = 1025;
  const [open, setOpen] = useState(false);
  return (
    <div style={{ background: "#ffffff" }}>
      <Container className="mcontainerpaddingnew learningmanage">
        <Row>
          <Col lg={7} md={12} sm={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h3 className="subheader">
                User-centered design for interactive experiences
              </h3>
              <h2 className="h2heading1">
                <span className="bold">
                  Take your idea from concept to reality
                </span>
              </h2>
            </ScrollAnimation>
            <AnimatedLine
              className="animateline"
              width={"150px"}
              margin={"-5px 0 10px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
            <p className="mparagraphheadingtop">
              We offer a comprehensive approach to Web design, UI/UX, brand
              identity, and maintenance, all focusing on user-friendliness and
              added value.
              <span
                className={`d-lg-inline d-xl-inline ${
                  open ? "d-inline" : "d-none"
                }`}
              >
                &nbsp;With affordable, interactive, and eye-catching contemporary
                designs, our UI/UX design company helps businesses prosper from
                start-ups to established ones. We create mock-up designs using
                established UI/UX Design guidelines to achieve excellence.
              </span>
            </p>
            <span
              className={`d-lg-inline d-xl-inline ${
                open ? "d-inline" : "d-none"
              }`}
            >
              <ul className="homelist">
                <li>
                  <p>
                    Ensure your brand presence on all platforms and multiple
                    devices.
                  </p>
                </li>
                <li>
                  <p>
                    Design Responsive and Adaptive Designs that fit your
                    business needs.
                  </p>
                </li>
                <li>
                  <p>
                    Optimize your User interface to improve customer experience.
                  </p>
                </li>
                <li>
                  <p>
                    Enhance productivity and increase the operational efficiency
                    of your enterprise.
                  </p>
                </li>
                <li>
                  <p>
                    Improve customer loyalty and retention with our effective
                    design strategy.
                  </p>
                </li>
              </ul>
            </span>
            <button
              className="readmore_btn d-lg-none d-xl-none pl-0"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                ""
              ) : (
                <>
                  <span className="expand readmore">
                    Read More <FontAwesomeIcon icon="angle-double-right" />
                  </span>
                </>
              )}
            </button>
            <div className="d-lg-none d-xl-none d-md-none learning-mid-img mt-4">
              <img alt="" src={imagemob1} className="mgridimg1" />
            </div>
            <p className="mparagraphheading">
              We have a dedicated creative design team that utilizes best
              practices and concepts for creating great user experiences and
              interactions for our clients.
              <span className={` d-xl-inline d-none `}>
                {" "}
                Our products offer a set of experiences that work seamlessly
                together, from onboarding to feedback on all platforms. Our
                designs provide a unique digital experience, whether it’s a
                website, mobile application, or email campaign. Along with
                evaluating how effective an application’s design is, we conduct
                user testing and optimize our code to boost the application’s
                performance. Based on strategic and informational frameworks, we
                build products that meet the users’ needs and offer a
                competitive advantage over other solutions.
              </span>
            </p>
            <div className="d-lg-none d-xl-none d-md-none learning-mid-img mt-4">
              <img alt="" src={imagemob2} className="mgridimg1" />
            </div>

            <br />
          </Col>
          <Col lg={5} md={12} sm={12} className="d-none d-md-block mt-2">
            <Row>
              <Col md={6} className="columntop">
                <div>
                  <img alt="" src={image1} className="mgridimg1" />
                </div>
                <div>
                  <img alt="" src={image2} className="mgridimg2" />
                </div>
              </Col>
              <Col md={6} className="mt-5">
                <div>
                  <img alt="" src={image3} className="mgridimg1" />
                </div>
                <br />
                <div>
                  <img alt="" src={image4} className="mgridimg1" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
