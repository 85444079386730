import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export const CampaignInfo = () => {
  return (
    <div className="learningbgInfo campaignbg">
      <ScrollAnimation animateIn="fadeInRight" duration={2} animateOnce={true}>
        <p className="">
          We offer Targeted Email Marketing Solutions & Transparent Packages For
          Businesses Worldwide - From Strategy To Development To Execution.
        </p>
      </ScrollAnimation>
    </div>
  );
};
