import React from "react";
import cisco from "../../../images/about/cisco.png";
import cox from "../../../images/about/cox.png";
import infosys from "../../../images/about/infosys.png";
import google from "../../../images/about/google.png";
import mile from "../../../images/about/milestone.png";

import adobe from "../../../images/about/adobe.png";
import applause from "../../../images/about/applause.png";
import ocz from "../../../images/about/ocz.png";
import apple from "../../../images/about/apple.png";
import xtime from "../../../images/about/xtime.png";

import applauseMob from "../../../images/about/applause-mob.png";
import oczMob from "../../../images/about/oczMob.png";
import xtimeMob from "../../../images/about/xtimeMob.png";
import adobeMob from "../../../images/about/adobeMob.png";

import { Col, Container, Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useViewport } from "../../common/designParts/useViewport";

export const ClientCarousel = () => {
    const { width } = useViewport();
    const breakpoint = 768;
    let settings_3 = {
        dots: false,
        autoplay: false,
        autoplaySpeed: 3000,
        slidesToShow: 6,
        slidesToScroll: 2,
      };

      let settings = {
        dots: false,
        autoplay: false,
        autoplaySpeed: 3000,
        slidesToShow: 4,
        slidesToScroll: 2,
      };

  return (
    <>
    <div className="pb-5">
     {width > breakpoint ?
     <Slider {...settings_3} className="clients-carousel" id="clients" >
     <div>
       <img src={adobe} alt=""/>
     </div>
    
     <div>
       <img src={ocz} alt=""/>
     </div>
     <div>
       <img src={apple} alt=""/>
     </div>
     <div>
       <img src={cox} alt=""/>
     </div>
   
     <div>
       <img src={infosys} alt=""/>
     </div>
     <div>
       <img src={google} alt=""/>
     </div>
     <div>
       <img src={cisco} alt=""/>
     </div>
     <div>
       <img src={mile} alt=""/>
     </div>
     <div>
     <img src={applause} alt="" />
     </div>
    
     <div>
       <img src={xtime} alt=""/>
     </div>
   </Slider> : 
   <Slider {...settings} className="clients-carousel" id="clients" >
   <div>
     <img src={adobeMob} alt=""/>
   </div>
  
   <div>
     <img src={oczMob} alt=""/>
   </div>
   <div>
     <img src={apple} alt=""/>
   </div>
   <div>
     <img src={cox} alt=""/>
   </div>
 
   <div>
     <img src={infosys} alt=""/>
   </div>
   <div>
     <img src={google} alt=""/>
   </div>
   <div>
     <img src={cisco} alt=""/>
   </div>
   <div>
     <img src={mile} alt=""/>
   </div>
   <div>
   <img src={applauseMob} alt=""/>
   </div>
  
   <div>
     <img src={xtimeMob} alt=""/>
   </div>
 </Slider>
     }
      
      </div>
    </>
  );
};
