import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import rightcircle from "../../../images/rightcircle.png";
import ScrollAnimation from "react-animate-on-scroll";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import styled from "styled-components";
import { useViewport } from "../../common/designParts/useViewport";
import digicircle from "../../../images/digitaltrans/digicircle.png";
import digicomp from "../../../images/digitaltrans/comp.png";

export const DigitalProcess = () => {
  const { width } = useViewport();
  const breakpoint = 992;
  const [click, setClick] = useState("assess_needs");
  const ProcessButton = styled.div`
  background:#7B7D7E;
  color:#fff;
  height:100px;
  width:100px
  text-align:center;
  padding-right:15px;
  padding-left:15px;
  padding-bottom:10px;
  position: absolute;
  border-radius:50%;
  font-size:12px;
  cursor:pointer;
 
  top:${(props) => props.top};
  left:${(props) => props.left};
`;

  return (
    <div>
      {" "}
      <div id="process"></div>
      <Container className="mcontainerpadding mb-5">
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                Our Digital Transformation <span className="bold"> Process Steps</span>
              </h2>
            </ScrollAnimation>
            <AnimatedLine
              className="animateline"
              width={"200px"}
              margin={"0 0 40px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
       
        {width < breakpoint ? (
          <Row>
              <Col md={12}>
                  <img src={digicircle} alt="" className="learning-process-img mt-4"/>
              </Col>
          </Row>
        ) : (
          <Row className="mb-3" style={{marginTop:"80px"}}>
            <Col md={9} sm={12} xs={12}>
              <div className="process_circle">
              <img src={digicomp} alt="" className="digicircle"/>
                <ProcessButton
                  top={"0"}
                  left={"0"}
                  className={`learning-pro-btn ${click === "assess_needs" ? "activeLearning" : ""}`}
                  onClick={() => setClick("assess_needs")}
                  style={{paddingTop:"22px"}}
                >
                 Enhanced High-
                  <br /> level Control
                </ProcessButton>

                <ProcessButton
                  top={"-4em"}
                  left={"13em"}
                  style={{paddingTop:"30px"}}
                  className={`learning-pro-btn-2 ${click === "design_spec" ? "activeLearning" : ""}`}
                  onClick={() => setClick("design_spec")}
                >
                   Customer 
                  <br /> Experience
                </ProcessButton>
                <ProcessButton
                  top={"8em"}
                  left={"25em"}
                  className={`learning-pro-btn-3 ${click === "test_software" ? "activeLearning" : ""}`}
                  onClick={() => setClick("test_software")}
                  style={{paddingTop:"25px"}}
                >
                  Product &  
                  <br />
                  Service
                  <br />
                  Innovation
                </ProcessButton>
                <ProcessButton
                  top={"20em"}
                  left={"20em"}
                  className={`learning-pro-btn-4 ${click === "implement_system" ? "activeLearning" : ""}`}
                  onClick={() => setClick("implement_system")}
                  style={{paddingTop:"22px"}}
                >
                    Distribution, <br /> Marketing & <br /> Sales
                 
                </ProcessButton>
                <ProcessButton
                  top={"23em"}
                  left={"5em"}
                  className={`learning-pro-btn-5 ${click === "support_oper" ? "activeLearning" : ""}`}
                  onClick={() => setClick("support_oper")}
                  style={{paddingTop:"30px"}}
                >
                  Digital <br />
                  Fulfillment
                </ProcessButton>
                <ProcessButton
                  top={"14em"}
                  left={"-4em"}
                  className={`learning-pro-btn-6 ${click === "evaluate" ? "activeLearning" : ""}`}
                  onClick={() => setClick("evaluate")}
                  style={{paddingTop:"30px"}}
                >
                  Risk <br />
                  Optimization
                </ProcessButton>
              </div>
            </Col>

            <Col
              md={3}
              className="initiancolumn mobile-pro-card d-sm-none d-md-block d-xs-none"
            >
              <div
                className="card initiancard"
                style={
                  click === "assess_needs"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                        <span className="bold">01</span>
                      </span>
                      <span className="inip">Enhanced High-Level Control</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                    Improved Real-time Information management systems and informed decision-making.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                    Seamless third party integrations to achieve the business target.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                    Execute, measure against outcomes with a key focus on the customers.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* 2 */}
              <div
                className="card initiancard"
                style={
                  click === "design_spec"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                        <span className="bold">02</span>
                      </span>
                      <span className="inip">Customer Experience</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                    Seamless multichannel experience.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">Determine the goal of the transformation. </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">Draw service propositions.</p>
                  </Col>
                </Row>

               
              </div>
              {/* 3 */}
              <div
                className="card initiancard"
                style={
                  click === "test_software"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                        <span className="bold">03</span>
                      </span>
                      <span className="inip">Product & Service Innovation</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                    Value Added products and Services.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                    AI Powered  processes.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                    Cloud-Based Strategies.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                    Legacy System migrations.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                    New Digital Products and Service Workflows.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* 4 */}
              <div
                className="card initiancard"
                style={
                  click === "implement_system"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                        <span className="bold">04</span>
                      </span>
                      <span className="inip">Distribution, Marketing & Sales</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                    Digital Marketing boosted with higher Returns.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                    Calculation and prioritisation of the transformation.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                    Identification of issues concerning the existing system.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* 5 */}
              <div
                className="card initiancard"
                style={
                  click === "support_oper"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                        <span className="bold">05</span>
                      </span>
                      <span className="inip">Digital Fulfillment</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                    Build the case, gain trust from the employees for the migration.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                    Collaborate with the core team to settle for a solution.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                    Virtual servicing and provision of the solution.
                    </p>
                  </Col>
                </Row>
              </div>
              {/* 6 */}
              <div
                className="card initiancard"
                style={
                  click === "evaluate"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Row>
                  <Col md={12}>
                    <p className="d-flex">
                      <span className="inih">
                        <span className="bold">06</span>
                      </span>
                      <span className="inip">Risk Optimization</span>
                    </p>
                  </Col>
                </Row>

                <br />
                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">Improved targeting with the trends collected from the customers.</p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <img alt="" src={rightcircle} className="rightcircleimg" />
                  </Col>
                  <Col md={10} className="pl-0">
                    <p className="inicontent">
                    Embedding automated workflows, risk profiles to simplify processes.
                    </p>
                  </Col>
                </Row>

               
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};
