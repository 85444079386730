import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import { AnimatedLine } from "../../common/designParts/animatedUnderline";
import creativecloud from "../../../images/adobe/Creative_Cloud.svg";
import add from "../../../images/adobe/download.png";
import exp from "../../../images/adobe/Screenshot 2021-05-10 at 1.39.45 AM.png"
import { useViewport } from "../../common/designParts/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import camp from "../../../images/adobe/Screenshot 2021-05-10 at 1.22.18 AM.png";
import dw from "../../../images/icons/dreamviewer.png";

import xd from "../../../images/adobe/xd.svg";
import analytics from "../../../images/adobe/Screenshot 2021-05-10 at 1.45.52 AM.png";

export const AdobeProcess = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  const [openlearning, setOpenlearning] = useState(false);
  return (
    <Container className="emastercontainer pt-3">
        <div id="process"></div>
      <Container>
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate-text"
              duration={5}
              animateOnce={true}
            >
              <h2 className="h2heading1">
                Our
                <span className="bold"> Process Flow</span>
              </h2>
            </ScrollAnimation>

            <AnimatedLine
              className="animateline"
              width={"130px"}
              margin={"-10px 0"}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                duration={2}
                animateOnce={true}
                className="no-animation"
              >
                <div className="line"></div>
              </ScrollAnimation>
            </AnimatedLine>
          </Col>
        </Row>
        {width < breakpoint ? (
          <Row className="adobeprocessSec mt-5">
            <Col xs={2}>
              <h1>01</h1>
            </Col>
            <Col xs={10}>
              <div className="adobeProcessCont">
                <p>Designing Early Content Calendar along with all teams.</p>
                <Collapse in={width < breakpoint ? openlearning === "one" : true}>
                  <div id="collapse-learning">
                    <ul className="mt-4">
                      <li>
                        <img src={creativecloud} alt="" />{" "}
                        <span>Adobe Creative Cloud</span>
                      </li>
                      <li>
                        <img src={exp} alt="" />{" "}
                        <span>Adobe Experience Cloud</span>
                      </li>
                      <li>
                        <img src={add} alt="" />{" "}
                        <span>Adobe Document Cloud</span>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>
              <button className="d-xl-none d-lg-none d-md-none collapse-btn-services text-center mr-auto ml-auto pt-2 adobe-close-btn">
                {openlearning === "one" ? (
                  <FontAwesomeIcon
                    icon="chevron-up"
                    onClick={() => setOpenlearning(false)}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon="chevron-down"
                    onClick={() => setOpenlearning("one")}
                    aria-controls="one"
                    aria-expanded="one"
                  />
                )}
              </button>
            </Col>
            <Col xs={{ span: 10, offset: 2 }} style={{ overflow: "hidden" }}>
              <div className="processBorder"></div>
            </Col>
            <Col xs={2}>
              <h1>02</h1>
            </Col>
            <Col xs={10}>
              <div className="adobeProcessCont">
                <p>Designing the content for the Newsletter.</p>
                <Collapse in={width < breakpoint ? openlearning === "two" : true}>
                  <div id="collapse-learning">
                    <ul className="mt-4">
                      <li>
                        <img src={xd} alt="" />{" "}
                        <span>Adobe Experience Design</span>
                      </li>
                      <li>
                        <img src={dw} alt="" />{" "}
                        <span>Adobe Dreamweaver</span>
                      </li>
                     
                    </ul>
                  </div>
                </Collapse>
              </div>
              <button className="d-xl-none d-lg-none d-md-none collapse-btn-services text-center mr-auto ml-auto pt-2 adobe-close-btn">
                {openlearning === "two" ? (
                  <FontAwesomeIcon
                    icon="chevron-up"
                    onClick={() => setOpenlearning(false)}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon="chevron-down"
                    onClick={() => setOpenlearning("two")}
                    aria-controls="two"
                    aria-expanded="two"
                  />
                )}
              </button>
            </Col>
            <Col xs={{ span: 10, offset: 2 }} style={{ overflow: "hidden" }}>
              <div className="processBorder"></div>
            </Col>
            <Col xs={2}>
              <h1>03</h1>
            </Col>
          
            <Col xs={10}>
              <div className="adobeProcessCont">
                <p>Pushing the News Structure out as Campaign.</p>
                <Collapse in={width < breakpoint ? openlearning === "third" : true}>
                  <div id="collapse-learning">
                    <ul className="mt-4">
                      <li>
                        <img src={camp} alt="" />{" "}
                        <span>Adobe Campaign</span>
                      </li>
                     
                    </ul>
                  </div>
                </Collapse>
              </div>
              <button className="d-xl-none d-lg-none d-md-none collapse-btn-services text-center mr-auto ml-auto pt-2 adobe-close-btn">
                {openlearning === "third" ? (
                  <FontAwesomeIcon
                    icon="chevron-up"
                    onClick={() => setOpenlearning(false)}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon="chevron-down"
                    onClick={() => setOpenlearning("third")}
                    aria-controls="third"
                    aria-expanded="third"
                  />
                )}
              </button>
            </Col>
            
            <Col xs={{ span: 10, offset: 2 }} style={{ overflow: "hidden" }}>
              <div className="processBorder"></div>
            </Col>
            <Col xs={2}>
              <h1>04</h1>
            </Col>
          
            <Col xs={10}>
              <div className="adobeProcessCont">
                <p>Designing Early Content Calendar along with all teams.</p>
                <Collapse in={width < breakpoint ? openlearning === "forth" : true}>
                  <div id="collapse-learning">
                    <ul className="mt-4">
                      <li>
                        <img src={analytics} alt="" />{" "}
                        <span>Adobe Analytics</span>
                      </li>
                     
                    </ul>
                  </div>
                </Collapse>
              </div>
              <button className="d-xl-none d-lg-none d-md-none collapse-btn-services text-center mr-auto ml-auto pt-2 adobe-close-btn">
                {openlearning === "forth" ? (
                  <FontAwesomeIcon
                    icon="chevron-up"
                    onClick={() => setOpenlearning(false)}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon="chevron-down"
                    onClick={() => setOpenlearning("forth")}
                    aria-controls="forth"
                    aria-expanded="forth"
                  />
                )}
              </button>
            </Col>
           
         
           
           
          </Row>
        ) : (
          <Row className="adobeprocessSec mt-5">
            <Col md={2} lg={3}>
              <h1>01</h1>
            </Col>
            <Col md={5} lg={4}>
              <div className="adobeProcessCont">
                <p>Designing Early Content Calendar along with all teams.</p>
              </div>
            </Col>
            <Col md={5} lg={5}>
              <ul>
                <li>
                  <img src={creativecloud} alt="" />{" "}
                  <span>Adobe Creative Cloud</span>
                </li>
                <li>
                  <img src={exp} alt="" /> <span>Adobe Experience Cloud</span>
                </li>
                <li>
                  <img src={add} alt="" />{" "}
                  <span>Adobe Document Cloud</span>
                </li>
              </ul>
            </Col>
            {/* border */}
            <Col md={{ span: 4, offset: 3 }} style={{ overflow: "hidden" }}>
              <div className="processBorder"></div>
            </Col>
            <Col md={5}></Col>
            {/* border */}
            <Col md={2} lg={3}>
              <h1>02</h1>
            </Col>
            <Col lg={4} md={5}>
              <div className="adobeProcessCont">
                <p>
                  Designing the content for the Newsletter.<br />
                </p>
              </div>
            </Col>
            <Col md={5}>
              <ul className="mt-2 mb-0">
                <li>
                  <img src={xd} alt="" />{" "}
                  <span>Adobe Experience Design</span>
                </li>
                <li>
                  <img src={dw} alt="" /> <span>Adobe Dreamweaver</span>
                </li>
              </ul>
            </Col>
            {/* border */}
            <Col md={{ span: 4, offset: 3 }} style={{ overflow: "hidden" }}>
              <div className="processBorder"></div>
            </Col>
            <Col md={5}></Col>
            {/* border */}
            <Col md={2} lg={3}>
              <h1>03</h1>
            </Col>
            <Col lg={4} md={5}>
              <div className="adobeProcessCont">
                <p>Pushing the News Structure out as Campaign.</p>
              </div>
            </Col>
            <Col md={5}>
              <ul className="mt-5 mb-0">
                <li>
                  <img src={camp} alt="" /> <span>Adobe Campaign</span>
                </li>
              </ul>
            </Col>
            {/* border */}
            <Col md={{ span: 4, offset: 3 }} style={{ overflow: "hidden" }}>
              <div className="processBorder"></div>
            </Col>
            <Col md={5}></Col>
            {/* border */}
            <Col md={2} lg={3}>
              <h1>04</h1>
            </Col>
            <Col lg={4} md={5}>
              <div className="adobeProcessCont">
                <p>Generate the reports for the released Campaign.</p>
              </div>
            </Col>
            <Col md={5}>
              <ul className="mt-5 mb-0">
                <li>
                  <img src={analytics} alt="" /> <span>Adobe Analytics</span>
                </li>
              </ul>
            </Col>
          </Row>
        )}
      </Container>
    </Container>
  );
};
